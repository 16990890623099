import React from "react";
import {
  NavLink,
  Link,
  useRouteMatch,
} from "react-router-dom";
import styled from "styled-components";

const PostApplicationProcess = ({ logoutHandler }) => {
  const { path, url } = useRouteMatch();
  return (
    <div className="row">
      <div className="col-md-12">
        <ul className="nav">
          <ListItem className="nav-item">
            <span className="content-item" >
              <img
                src="/assets/icons/icon-youtube.svg"
                className="img-fluid post"
                alt="Status"
              />
              <p className="content-text"> <a href="https://www.youtube.com/@InformativeVideosinfo" target="_blank">Youtube Videos</a></p>
            </span>
          </ListItem>
          <ListItem className="nav-item">
            <span className="content-item" >
              <img
                src="/assets/icons/icon-faq-grey.svg"
                className="img-fluid post"
                alt="Help Center"
              />
              <p className="content-text"><a href="https://support.pmay.org/faqs.html" target="_blank">FAQs</a></p>
            </span>
          </ListItem>
          <ListItem className="nav-item">
            <span className="content-item" >
              <img
                src="/assets/icons/help-line.svg"
                className="img-fluid post"
                alt="Help Center"
              />
              <p className="content-text">Helpline: <a href="tel:+918956983351">+91 89569 83351  </a><span className="helplinespan">(10:30 AM to 6:00 PM on gov. working days)</span></p>
            </span>
          </ListItem>
        </ul>
      </div>
      <div className="col-md-12">
        <LogoutBtn>
          <span className="content-item" onClick={() => logoutHandler()}>
            <img
              src="/assets/icons/Logout.svg"
              className="img-fluid"
              alt="Logout"
            />
            <p className="content-text">Logout</p>
          </span>
        </LogoutBtn>
      </div>
    </div>
  );
};

export default PostApplicationProcess;

const ListItem = styled.li`
    width: 100%;
    .content-item .post {
      height: 23px;
    }
`;

const LogoutBtn = styled.button`
  padding: 10px;
  border-radius: 8px;
  box-shadow: -8px 8px 40px 0 rgba(0, 0, 0, 0.08);
  border: solid 1px #e2e7f5;
  background-color: #fff;
  width: 100%;
  color: #ff6550;
  margin-top: 30px; 
  p{
    color: #ff6550;
  }
  .content-item img {
    padding: 5px;
  }
`;
