import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import styled from "styled-components";
import { fetchUserRequest } from "../../redux/slices/userRequestSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  FilterPayload1Items,
  FilterPayload2Items,
} from "../../services/PayloadAPIService.js";
import {
  selectUserPayload1,
  selectUserPayload2,
} from "../../redux/slices/userRequestSlice";
import Spinner from "../Spinner";
import { postPayload2Data } from "../../services/PayloadAPIService.js";
const AcceptTerms = ({ userId, onNextTabHandler }) => {
  const dispatch = useDispatch();

  const [acceptTermsData, setAcceptTermsData] = useState(false);
  const selectUserPayload2Data = useSelector(selectUserPayload2);
  const selectUpdateUserReqStatus = useSelector(
    (state) => state.userRequest?.updateStatusRequest
  );
  const [agree, setAgree] = useState(false);
  const [validated, setValidated] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [languageChnage, setLanguageChnage] = useState(false);

  useEffect(() => {
    if (selectUserPayload2Data?.identification_terms_accepted) {
      setAcceptTermsData(true);
      // setAgree(selectUserPayload2Data?.identification?.term?.isAgreement)
    }
  }, [selectUserPayload2Data?.identification_terms_accepted]);

  //   useEffect(() => {
  //     if(acceptTerms){
  //       setAgree()
  //     }
  //   }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    } else {
      manageUserHandler();
      event.preventDefault();
      event.stopPropagation();
    }
  };

  // useEffect(() => {
  //     if (selectUpdateUserReqStatus?.state === 'succeeded') {
  //         if (userId)
  //             dispatch(fetchUserRequest({ userId }));
  //     }
  // }, [selectUpdateUserReqStatus?.state]);

  const manageUserHandler = async () => {
    setUploading(true);

    let input = {
      identification_terms_accepted: true,
    };

    let payload = {
      user_id: userId,
      //    payload1: JSON.stringify(FilterPayload1Items('identificationFilled', 'tab3')),
      payload2:  FilterPayload2Items(input, selectUserPayload2Data )
    };

    let response = await postPayload2Data(payload);
    if (response.status === 200) {
      dispatch(fetchUserRequest({ userId }));
      onNextTabHandler("informaton");
    }
    setUploading(false);
  };

  return (
    <div className="container-fluid">
      {selectUpdateUserReqStatus?.state === "loading" ? (
        <Spinner />
      ) : (
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <div className="row">
            <General>
              <div className="col-sm-10">
                <h3 className="text-left heading-font">
                  {!languageChnage ? (
                    <>
                      सर्वसाधारण संमती
                      <a
                        style={{
                          color: "#4640de",
                          fontSize: 16,
                          fontWeight: "normal",
                          fontStretch: "normal",
                          fontStyle: "normal",
                          lineHeight: 1.63,
                          letterSpacing: "normal",
                          marginLeft: 15,
                          cursor: "Pointer",
                        }}
                        onClick={() => setLanguageChnage(true)}
                      >
                        {" "}
                        (For English click here)
                      </a>
                    </>
                  ) : (
                    <>
                      General Consent
                      <a
                        style={{
                          color: "#4640de",
                          fontSize: 16,
                          fontWeight: "normal",
                          fontStretch: "normal",
                          fontStyle: "normal",
                          lineHeight: 1.63,
                          letterSpacing: "normal",
                          marginLeft: 15,
                          cursor: "Pointer",
                        }}
                        onClick={() => setLanguageChnage(false)}
                      >
                        {" "}
                        (मराठीत साठी येथे क्लिक करा)
                      </a>
                    </>
                  )}
                </h3>
                {!languageChnage ? (
                  <>
                    <a className="alignatag">आधार कार्ड</a>
                    <p className="contentdata text-left">
                      मी याद्वारे माझा आधार कार्ड क्रमांक शेअर करणेस व पडताळणी
                      करणेकामी व्यक्तिगतरित्या / लेखी संमती देतो. तसेच माझा आधार
                      कार्ड क्रमांक प्रधानमंत्री आवास योजनेच्या संकेतस्थळावर व
                      इतर पडताळणी करणाऱ्या संकेतस्थळावर संग्रहीत राहील हे मला
                      ज्ञात आहे.
                    </p>
                    <a className="alignatag">
                      पॅन क्रमांक/ अधिवास प्रमाणपत्र/ जातीचे प्रमाणपत्र/
                      तहसीलदारांचे उत्पन्न प्रमाणपत्र
                    </a>
                    <p className="contentdata text-left">
                      मी वरील दस्तऐवज MahaIT API सेवांद्वारे प्रमाणित करण्यासाठी
                      व सदर कागदपत्रांचे तपशील संग्रहित करण्यासाठी
                      व्यक्तिगतरित्या / लेखी संमती देतो.
                    </p>
                    <a className="alignatag">सामान्य घोषणा</a>
                    <p className="contentdata text-left">
                      मी घोषित करते / करतो की माझ्या आणि माझ्या कुटुंबातील
                      सदस्यांच्या नावावर भारतात कुठेही कायमस्वरूपी घर नाही आणि
                      आजपर्यंत आम्हाला कोणत्याही सरकारी गृहनिर्माण योजनांचा लाभ
                      मिळालेला नाही. तसेच, माझे कौटुंबिक उत्पन्न रु.3,00,000
                      (रुपये तीन लाख) पेक्षा जास्त नाही. मी याद्वारे घोषित करते
                      / करतो की या अर्जात मी दिलेली माहिती माझ्या सर्वोत्तम
                      माहिती आणि विश्वासानुसार सत्य आणि बरोबर आहे. सदर माहिती
                      खोटी असल्याचे आढळून आल्यास, पिंपरी चिंचवड महानगरपालिकेने
                      घेतलेल्या निर्णयानुसार शिक्षा किंवा इतर कोणत्याही कायदेशीर
                      कारवाईस मी जबाबदार असेल.
                    </p>
                    <p className="contentdata text-left">
                      वरीलप्रमाणे मी माझी माहिती शेअर करणेस व पडताळणीकामी आवश्यक
                      कार्यवाही करणेस सहमत आहे.
                    </p>
                  </>
                ) : (
                  <>
                    <a className="alignatag"> Aadhar Card</a>
                    <p className="contentdata text-left">
                      I hereby provide my expressed & written consent to share
                      my Aadhar number details & also, provide consent to get my
                      Aadhar details validated. | understand that my Aadhar
                      number will be stored on PMAY server or on the servers of
                      participants involved in verification of my Aadhar number.
                    </p>
                    <a className="alignatag">
                      PAN number/Domicile certificates/Caste Certificate/ Income
                      certificate from Tahsildar
                    </a>
                    <p className="contentdata text-left">
                      I also provide my expressed & written consent to validate
                      above documents through MahalT API services & provide
                      consent to store the details.
                    </p>
                    <a className="alignatag">General Declaration</a>
                    <p className="contentdata text-left">
                      I declare that there is no permanent house anywhere in
                      India in the name of me and my family members and we have
                      not benefited from any government housing schemes till
                      date. Also, my family income is not more than Rs.3,00,000
                      (Rupees Three Lakhs only). I hereby declare that the
                      information provided by me in this application is true and
                      correct to the best of my knowledge and belief. If the
                      information is found to be false, I shall be liable for
                      punishment or any other legal action as per the decision
                      taken by Pimpri Chinchwad Municipal Corporation.
                    </p>
                  </>
                )}

                <Form.Group className="mb-3" style={{ textAlign: "left" }}>
                  <Form.Check
                    required
                    label={`${
                      languageChnage
                        ? "I agree to my information being shared and gathered between services"
                        : "वरीलप्रमाणे मी माझी माहिती शेअर करणेस व पडताळणीकामी आवश्यक कार्यवाही करणेस सहमत आहे."
                    } `}
                    feedback="You must agree before submitting."
                    feedbackType="invalid"
                    value={agree}
                    disabled={acceptTermsData}
                    checked={agree || acceptTermsData}
                    onChange={(e) => setAgree(e.target.value)}
                  />
                </Form.Group>
              </div>
            </General>
            {acceptTermsData ? (
              <button
                className="btn btn-primary mt-5"
                type="button"
                onClick={() => onNextTabHandler("informaton")}
              >
                {" "}
                Save & Proceed{" "}
              </button>
            ) : (
              <button className="btn btn-primary mt-5" type="submit">
                {" "}
                Save & Proceed{" "}
              </button>
            )}
          </div>
        </Form>
      )}
    </div>
  );
};

export default AcceptTerms;

const General = styled.div`
  .alignatag {
    display: block;
    text-align: left;
    color: #848493;
    text-decoration: underline;
    margin-bottom: 10px;
    cursor: pointer;
  }
`;
