import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ApiDataService from "../../services/api-service"
export const fetchUserProfile = createAsyncThunk(
  "user/getUserProfile",
  async ({mobileNo, otp }) => {
    const result = await ApiDataService.get(`session_auth?mobile_number=${mobileNo}&otp=${otp}`);
    return result.data;
  }
);

const userProfileSlice = createSlice({
  name: "userProfile",
  initialState: {
    profile: null,
    status: "idle",
    error: "",
  },
  reducers: {},
  extraReducers: {
    [fetchUserProfile.pending]: (state, action) => {
      state.status = "loading";
      state.error = "";
    },
    [fetchUserProfile.fulfilled]: (state, action) => {
      state.profile = action.payload;
      localStorage.setItem("userId",state.profile?.user_id);
      localStorage.setItem("token",state.profile?.token)
      state.status = "succeeded";
    },
    [fetchUserProfile.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
  },
});

export const selectUserProfile = (state) => state.userProfile;
export const userProfileLoading  = (state) => state.userProfile.status;
export const selectUserId = (state) =>
  state.userProfile.profile ? state.userProfile.profile.id : null;


export default userProfileSlice.reducer;
