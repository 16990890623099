import React, { useState, useRef } from "react";
import { fileUpload } from "../../services/PayloadAPIService";
import moment from "moment";
import { UPLOAD_DOCUMENT_STATUS } from "../../constants.js";
import Spinner from "../Spinner";
import { Utility } from "../../utils/utility.js";
const Document = ({ index, item, handleFileUploadChange, errorMsgHandler }) => {
  const inputRef = useRef(null);
  const otherInputRef = useRef(null);
  const [otherDocument, setOtherDocument] = useState(item.misc_doc_description);
  const [otherDocumentValidation, setOtherDocumentValidation] = useState(false);
  const [uploading, setUploading] = useState(false);
  const handleClick = () => {
    if (!otherDocument) {
      otherInputRef.current.focus();
      // errorMsgHandler("Please provide other document description");

      return;
    }

    if (item.misc_doc_name === 'Upload Additional Documents Here' || item.misc_doc_name === 'Upload Additional') {

      if (!Utility.validateAlphaNumeric(otherDocument)) {
        setOtherDocumentValidation(true)
        return
      }
      setOtherDocumentValidation(false)
    }

    // 👇️ open file input box on click of another element
    inputRef.current.click();
  };

  const handleFileChange = async (event) => {
    const MAX_FILE_SIZE = 3072 // 3MB
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }

    const allowedTypes = ["image/jpeg", "image/png", "image/jpeg", "application/pdf"];
    if (!allowedTypes.includes(fileObj?.type)) {
      errorMsgHandler("File does not support. You must use .png, .jpg or .pdf ");
      return;
    }

    const fileSizeKiloBytes = fileObj?.size / 1024;

    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      errorMsgHandler("File size is greater than maximum limit");
      return
    }


    setUploading(true);
    const formData = new FormData();
    // Update the formData object
    formData.append(
      "file",
      fileObj,
      fileObj.name
    );
    const response = await fileUpload(formData);
    if (response.status === 200) {
      if (item.misc_doc_name === 'Upload Additional Documents Here' || item.misc_doc_name === 'Upload Additional') {
        handleFileUploadChange(response.data, index, otherDocument);
      } else {
        handleFileUploadChange(response.data, index, '');
      }

      setUploading(false);
    } else {
      errorMsgHandler("Something went wrong!")
      setUploading(false);
    }
  };

  return (
    <li className="list-group-item">
      <div className="row">
        <div className="col-md-5">
          <div>{item.misc_doc_name}</div>
          {(item.misc_doc_name === 'Upload Additional Documents Here' || item.misc_doc_name === 'Upload Additional') ? <>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Document Name"
              value={otherDocument}
              maxLength={200}
              ref={otherInputRef}
              onChange={(e) => setOtherDocument(e.target.value)}
            />
            {otherDocumentValidation ? <p style={{ color: '#ff6550' }}>Please Enter Valid Document Name</p> : null}
          </>
            :
            <small>{item.misc_doc_description}</small>}

        </div>
        <div className="col-md-3">
          {item.misc_doc_status === UPLOAD_DOCUMENT_STATUS.notUploaded ? (
            <div>Not Uploaded</div>
          ) : (
            <>
              <div>Document Uploaded</div>
              <small>{item.misc_doc_upload_date ? moment(JSON.parse(item.misc_doc_upload_date)).format('DD MMM YYYY') : null}</small>
            </>
          )}
        </div>
        <div className="col-md-3">
          {item.misc_doc_status === UPLOAD_DOCUMENT_STATUS.notUploaded ? null : (
            <span
              className={`badge ${item.misc_doc_status === UPLOAD_DOCUMENT_STATUS.approved
                ? "badge-success"
                : item.misc_doc_status === UPLOAD_DOCUMENT_STATUS.rejected
                  ? "badge-danger"
                  : "badge-warning"
                } `}
            >
              {item.misc_doc_status}
            </span>
          )}
          {item.misc_doc_status === UPLOAD_DOCUMENT_STATUS.pending ||
            item.misc_doc_status === UPLOAD_DOCUMENT_STATUS.rejected ||
            item.misc_doc_status === UPLOAD_DOCUMENT_STATUS.approved
            ? (
              <span style={{ paddingLeft: 25 }}>
                <input
                  style={{ display: "none" }}
                  ref={inputRef}
                  type="file"
                  onChange={handleFileChange}
                />
                <i className="fa  fa fa-repeat" aria-hidden="true"
                  style={{ cursor: 'pointer' }} title="Re-Upload Document"
                  onClick={handleClick}></i>
              </span>
            )
            :
            null}

          {item.misc_doc_status === UPLOAD_DOCUMENT_STATUS.rejected ? (
            uploading ? <Spinner /> : <img
              src="/assets/icons/icon-info-red.svg"
              className="img-fluid"
              alt="upload"
              title={item.misc_doc_note}
            />
          ) : null}

        </div>
        <div className="col-md-1">
          {item.misc_doc_status === UPLOAD_DOCUMENT_STATUS.notUploaded || item.misc_doc_status === UPLOAD_DOCUMENT_STATUS.rejected ? (
            <div>
              <input
                style={{ display: "none" }}
                ref={inputRef}
                type="file"
                onChange={handleFileChange}
              />
              <img
                style={{ cursor: 'pointer' }}
                src="/assets/icons/upload.svg"
                className="img-fluid"
                alt="upload"
                title="Upload Document"
                onClick={handleClick}
              />
            </div>
          ) : null} 
        </div>
      </div>
    </li>
  );
};

export default Document;
