import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
// import ApiDataService from "../../services/api-service";
import { useDispatch, useSelector } from "react-redux";
import { FilterPayload1Items, FilterPayload2Items } from "../../services/PayloadAPIService";
import { useHistory } from "react-router-dom";
import { selectUserPayload1, selectUserPayload2, fetchUserRequest, selectUserRequestData } from "../../redux/slices/userRequestSlice";
import Spinner from "../Spinner";
import { Utility } from "../../utils/utility";
// import Alert from 'react-bootstrap/Alert';
import { postPayload2Data, getIFSCCodeVerificationData } from "../../services/PayloadAPIService";

const BankDetails = ({ getApplicationIdHandler }) => {
    const [accountNumber, setAccountNumber] = useState('');
    const [confirmAccountNumber, setConfirmAccountNumber] = useState('');
    const [ifscCode, setIfscCode] = useState('');
    const [ifscCodeFlag, setIfscCodeFlag] = useState(false);
    const [bankName, setBankName] = useState('');
    const [bankBranch, setBankBranch] = useState('');
    const [validated, setValidated] = useState(false);
    const [userId, setUserId] = useState("");

    const history = useHistory();
    const [uploading, setUploading] = useState(false);
    const selectUserPayload2Data = useSelector(selectUserPayload2);
    const selectUserPayload1Data = useSelector(selectUserPayload1);
    const dispatch = useDispatch();
    const selectUpdateUserReqStatus = useSelector((state) => state.userRequest?.updateStatusRequest);
    const selectUserRequest = useSelector(selectUserRequestData);
    useEffect(() => {
        getApplicationIdHandler(selectUserRequest?.data?.application_id)
    }, [selectUserRequest?.data?.application_id]);

    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
        } else {
            manageBankDetailHandler();
            event.preventDefault();
            event.stopPropagation();
        }
    };

    useEffect(() => {
        if (localStorage.getItem("userId")) {
            setUserId(localStorage.getItem("userId"))
        }
    }, []);

    useEffect(() => {
        if (userId)
            dispatch(fetchUserRequest({ userId }));
    }, [userId]);

    useEffect(() => {
        if (selectUserPayload2Data) {
            setAccountNumber(selectUserPayload2Data?.bank_account_no);
            setConfirmAccountNumber(selectUserPayload2Data?.bank_account_no);
            setIfscCode(selectUserPayload2Data?.bank_ifsc_code)
            setBankName(selectUserPayload2Data?.bank_name)
            setBankBranch(selectUserPayload2Data?.bank_branch)
        }
    }, [selectUserPayload2Data]);

    const manageBankDetailHandler = async () => {
        setUploading(false);
        let input = {
            bank_account_no: accountNumber,
            bank_confirm_account_no: confirmAccountNumber,
            bank_ifsc_code: ifscCode,
            bank_name: bankName,
            bank_branch: bankBranch,
        };
        let payload = {
            user_id: userId,
            payload1: FilterPayload1Items('BankDetailFilled', 'lastTab'),
            payload2: FilterPayload2Items(input, selectUserPayload2Data)
        };

        let response = await postPayload2Data(payload);
        if (response.status == 200) {
            dispatch(fetchUserRequest({ userId }));
            history.push(`/dashboard/family-dependent`);
        }
        setUploading(false);
    };

    const handleIfscKeyPress = (event) => {
        getIFSCCodeData(event);
    }

    function getIFSCCodeData(ifscVal) {
        getIFSCCodeVerificationData(ifscVal)
            .then(response => {
                if (response.status === 200) {
                    let resData = response.data;
                    setBankName(resData.BANK);
                    setBankBranch(resData.BRANCH);
                    setIfscCodeFlag(true);
                }else{
                    setIfscCodeFlag(false);
                }
            })
            .catch(err => {
                console.log('An error occurred:', err.res);
                setBankName("");
                setBankBranch("");
            });
    }

    return (
        <>
            <div className="container-fluid">
                {selectUpdateUserReqStatus?.state === 'loading' ? <Spinner /> :
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col ">
                                <h5 className="mainTitle" style={{ marginTop: 20, marginBottom: 20 }}>Bank Details</h5>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-3 ">
                                <h5 className="headingTitle">Bank Account Information</h5>
                                <p className="contentdata">Please enter your primary Bank Account Details. Please ensure that this is an active account and you have access to the account.
                                </p>
                            </div>
                            <div className="col-sm-6 offset-sm-1">
                                <Form.Group className="form-group" controlId="accountNumber" >
                                    <Form.Label>Bank Account Number</Form.Label>
                                    <Form.Control
                                        className="form-control"
                                        type="text"
                                        // pattern='[0-9]{0,50}'
                                        placeholder="Enter Account Number"
                                        value={accountNumber}
                                        maxLength={50}
                                        onChange={(e) => setAccountNumber(e.target.value)}
                                        isInvalid={accountNumber && !Utility.validateBankAccount(accountNumber)}
                                        required
                                    ></Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        {(accountNumber && !Utility.validateBankAccount(accountNumber)) ? 'Bank Account Number not more than 50' : 'Please provide Bank Account Number.'}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="form-group" controlId="confirmAccountNumber" >
                                    <Form.Label>Confirm Bank Account Number</Form.Label>
                                    <Form.Control
                                        className="form-control"
                                        type="password"
                                        pattern='[0-9]{0,50}'
                                        maxLength={50}
                                        placeholder="Enter Account Number"
                                        value={confirmAccountNumber}
                                        isInvalid={
                                            accountNumber && confirmAccountNumber && !Utility.validateConfirmPassword(accountNumber, confirmAccountNumber)
                                        }
                                        onChange={(e) => setConfirmAccountNumber(e.target.value)}
                                        required
                                    ></Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        Confirm Account Number is not Same as Bank Account Number.-
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="form-group" controlId="ifscCode" >
                                    <Form.Label>IFSC Code</Form.Label>
                                    <Form.Control
                                        className="form-control"
                                        type="text"
                                        placeholder="Enter IFSC Code"
                                        value={ifscCode}
                                        maxLength={11}
                                        onChange={(e) => setIfscCode(Utility.toUpperCase(e.target.value))}
                                        isInvalid={
                                            ifscCode && !Utility.ToIFSCCode(ifscCode)
                                        }
                                        onBlur={(e) => handleIfscKeyPress(e.target.value)}
                                        required
                                    ></Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        {(ifscCode && !Utility.ToIFSCCode(ifscCode)) ? 'Please provide Correct IFSC Code.' : 'Please provide IFSC Code.'}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="form-group" controlId="bankName" >
                                    <Form.Label>Bank Name</Form.Label>
                                    <Form.Control
                                        className="form-control"
                                        type="text"
                                        placeholder="Enter Bank Name"
                                        maxLength={50}
                                        disabled
                                        isInvalid={
                                            bankName && !Utility.validateAlphaNumeric(bankName)
                                        }
                                        value={bankName}
                                        onChange={(e) => setBankName(e.target.value)}
                                        required
                                    ></Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        Please provide Bank Name.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="form-group" controlId="bankBranch" >
                                    <Form.Label>Bank Branch</Form.Label>
                                    <Form.Control
                                        className="form-control"
                                        type="text"
                                        placeholder="Enter Bank Branch"
                                        disabled 
                                        value={bankBranch}
                                        maxLength={30}
                                        isInvalid={
                                            bankBranch && !Utility.validateName(bankBranch)
                                        }
                                        onChange={(e) => setBankBranch(e.target.value)}
                                        required
                                    ></Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        Please provide Bank Branch.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </div>
                        </div>
                        <hr className="mt-5" />
                        <div className="row">
                            <div className="col-sm-4 offset-sm-4">
                                <button className="btn btn-primary" type="submit"
                                    disabled={ifscCodeFlag && !Utility.validateConfirmPassword(accountNumber, confirmAccountNumber)
                                        || !Utility.validateBankAccount(accountNumber) ||
                                        //  !Utility.ToIFSCCode(ifscCode) ||
                                        !Utility.validateName(bankBranch) || !Utility.validateAlphaNumeric(bankName)}>Save & Proceed</button>
                            </div>
                        </div>
                    </Form>}
            </div>
        </>
    )
}

export default BankDetails