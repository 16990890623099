// import moment from "moment";

const isDate = (date) => {
  return new Date(date) !== "Invalid Date" && !isNaN(new Date(date));
};
export const Utility = {

  toCapitalize: function (name) {
    return name.charAt(0).toUpperCase() + name.slice(1);
  },
  toUpperCase: function (name) {
    return name.toUpperCase();
  },

  ToIFSCCode: function (name) {
    let val = name.includes(0, 4)
    if (val) {
      return true
    } else {
      return false
    }
  },
  validateMobileNumber: function (mobileTxt) {
    if (!mobileTxt) {
      return null;
    }
    var regEx = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
    return regEx.test(mobileTxt);
  },
  validateEmail: function (emailTxt) {
    if (!emailTxt) {
      return null;
    }
    var regEx = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    // var regEx = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/;
    return regEx.test(emailTxt);
  },

  validateConfirmPassword: function (password, confirmPass) {
    if (password === confirmPass) {
      return true;
    } else {
      return false;
    }
  },
  // disabledStepper: function (paymentDetails, acceptTerm) {
  //   if (acceptTerm && paymentDetails?.AKURDI && paymentDetails?.AKURDI?.txn_status === 'SUCCESS') {
  //     return false;
  //   } else {
  //     return true;
  //   }

  // },
  excelDateToJSDate: (excelDate) => {
    if (!excelDate) {
      return null;
    }
    if (isDate(excelDate)) {
      var date = new Date(Math.round((excelDate - 25569) * 86400 * 1000));
      if (date.getTime()) {
        var converted_date = date.toISOString().split("T")[0];
        return converted_date;
      } else {
        return null;
      }
    }
  },
  validateGstNumber: (value) => {
    if (!value) {
      return null;
    }
    var regex = /^[a-zA-Z0-9\-_]{15}$/;
    return regex.test(value);
  },
  validatePanNumber: (value) => {
    if (!value) {
      return null;
    }
    var regex = /^[a-zA-Z0-9\-_]{10}$/;
    return regex.test(value);
  },
  validateName: (value) => {
    if (!value) {
      return null;
    }
    var regex = /^[^-\s][a-zA-Z_\s-]+$/;
    return regex.test(value);
  },
  validateAlphaNumeric: (value) => {
    if (!value) {
      return null;
    }
    var regex = /^[^-\s][a-zA-Z0-9_\s-]+$/;
    return regex.test(value);
  },
  validateNumber: (value) => {
    if (!value) {
      return null;
    }
    var regex = /^[0-9]+$/;
    return regex.test(value);
  },
  validateYearsUpto100: (value) => {
    if (value <= 100) {
      return true;
    } else {
      return false
    }
  },

  validateSizeOfDwelling: (value) => {
    if (value <= 5000) {
      return true;
    } else {
      return false
    }
  },

  validateBankAccount: (value) => {
    if (!value) {
      return null;
    }
    var regex = /^[0-9]{0,50}$/;
    return regex.test(value);
  },

  validateNoOfRoom: (value) => {
    if (value <= 20) {
      return true;
    } else {
      return false
    }
  },

  validateMonthlyIncome: (value) => {
    if (value <= 500000) {
      return true;
    } else {
      return false
    }
  },

  convertNumberToWordsForMember: (value) => {
    switch (value) {
      case 1:
        return 'First Member';
      case 2:
        return 'Second Member';
      case 3:
        return 'Third Member';
      case 4:
        return 'Fourth Member';
      case 5:
        return 'Fifth Member';
      case 6:
        return 'Sixth Member';
      case 7:
        return 'Seventh Member';
      case 8:
        return 'Eighth Member';
      case 9:
        return 'Ninth Member';
      case 10:
        return 'Tenth Member';
      default:
        return `${value} Member`;
    }
  },
  validateDecimalNumber: (value) => {
    if (!value) {
      return null;
    }
    var regex = /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/;
    return regex.test(value);
  },
  disablePastDate: (value) => {
    const today = new Date();
    const dd = String(today.getDate() + 1).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const pastYear = today.getFullYear() - 2;
    //const yyyy = today.getFullYear();
    return pastYear + "-" + mm + "-" + dd;
  },
  dateCompareValidation: (startDate, endDate) => {
    let validationRes = false;
    if (!startDate) {
      return null;
    }
    if (isDate(startDate)) {
      var sDate = new Date(startDate);
      var eDate = new Date(endDate);
      if (sDate.getTime() > eDate.getTime()) {
        validationRes = true;
      }
    }
    return validationRes;
  },
  convertNumberToWords: (num) => {
    num = Math.round(num);
    let ones = ["", "One ", "Two ", "Three ", "Four ", "Five ", "Six ", "Seven ", "Eight ", "Nine ", "Ten ", "Eleven ", "Twelve ", "Thirteen ", "Fourteen ", "Fifteen ", "Sixteen ", "Seventeen ", "Eighteen ", "Nineteen "];
    let tens = ["", "", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"];
    if ((num = num.toString()).length > 9) return "Overflow: Maximum 9 digits supported";
    let n = ("000000000" + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    if (!n) return;
    let str = "";
    str += n[1] != 0 ? (ones[Number(n[1])] || tens[n[1][0]] + " " + ones[n[1][1]]) + "Crore " : "";
    str += n[2] != 0 ? (ones[Number(n[2])] || tens[n[2][0]] + " " + ones[n[2][1]]) + "Lakh " : "";
    str += n[3] != 0 ? (ones[Number(n[3])] || tens[n[3][0]] + " " + ones[n[3][1]]) + "Thousand " : "";
    str += n[4] != 0 ? (ones[Number(n[4])] || tens[n[4][0]] + " " + ones[n[4][1]]) + "Hundred " : "";
    str += n[5] != 0 ? (str != "" ? "and " : "") + (ones[Number(n[5])] || tens[n[5][0]] + " " + ones[n[5][1]]) : "";
    return str;
  },
  noSpaceAlphnumaric: (value) => {
    if (!value.trim()) {
      return null;
    }
    var regex = /^([a-zA-Z0-9 _-]+)$/;
    return new RegExp(regex).test(value)
  },
  
  convertToFormat: (data, dateFormat = "YYYY-MM-DD") => {
    // if (!data) {
    //   return null;
    // }
    // let date = "";

    // let dateData = data?.seconds || data?._seconds;
    // if (dateData) {
    //   date = moment.unix(dateData).format(dateFormat);
    // } else {
    //   date = moment(data).format(dateFormat);
    // }
    // return date;
  },
};