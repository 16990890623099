import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ApiDataService from "../../services/api-service"
export const fetchMasterDropdownData = createAsyncThunk(
  "user/getMasterDropdownData",
  async () => {
    try {
      const url = `config`;
      const result = await ApiDataService.get(url);
      return {
        data: result.data,
      };
    } catch (error) {
      console.log("update error:", error);
    }
  }
);

const MasterDropdownDataSlice = createSlice({
  name: "masterDropdownData",
  initialState: {
    data: null,
    status: "idle",
    error: "",
  },
  reducers: {},
  extraReducers: {
    [fetchMasterDropdownData.pending]: (state, action) => {
      state.status = "loading";
      state.error = "";
    },
    [fetchMasterDropdownData.fulfilled]: (state, action) => {
      state.data = action.payload?.data;
      state.status = "succeeded";
    },
    [fetchMasterDropdownData.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
  },
});

export const selectUserDropdownData = (state) =>
  state.masterDropdownData?.data
    ? state.masterDropdownData?.data
    : null;

export default MasterDropdownDataSlice.reducer;
