import React, { useState, useEffect ,useRef} from "react";
import { Form, InputGroup } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import ApiDataService from "../../services/api-service";
import { selectUserDropdownData } from "../../redux/slices/MasterDropdownSlice";
import { useDispatch, useSelector } from "react-redux";
import { Utility } from "../../utils/utility";
import { fileUpload } from "../../services/PayloadAPIService";

const FamilyMeber = ({ counter, item, handleFormChange, 
    removeMemberHandler, aadharVerificationHandler, aadhaarVerification, aadhaarloader, handleFileUploadChange }) => {
    const inputRef = useRef(null);
    const otherInputRef = useRef(null);
    const [userId, setUserId] = useState("");
    const [relation, setRelation] = useState("");
    const [gender, setGender] = useState("");
    const [otherType, setotherType] = useState("");
    const masterDropdownList = useSelector(selectUserDropdownData);
    const [errorMsg, setErrorMsg] = useState('');
    const [uploading, setUploading] = useState(false);
    useEffect(() => {
        if (localStorage.getItem("userId")) {
            setUserId(localStorage.getItem("userId"))
        }
    }, []);

    useEffect(() => {
        if (masterDropdownList?.config_id) {
            setRelation(masterDropdownList?.config_id?.relation);
            setGender(masterDropdownList?.config_id?.gender);
            setotherType(masterDropdownList?.config_id?.other_id_type);
        }
    }, [masterDropdownList?.config_id]);

    const handleClick = () => {
        if (!item.member_other_id_type && !item.member_other_id_no) {
            otherInputRef.current.focus();
           setErrorMsg("Please provide other document");
         
          return;
        }
        // 👇️ open file input box on click of another element
        inputRef.current.click();
      };
    
      const handleFileChange = async(event) => {
        const MAX_FILE_SIZE = 3072 // 3MB
        const fileObj = event.target.files && event.target.files[0];
        if (!fileObj) {
          return;
        }
    
        const allowedTypes = ["image/jpeg", "image/png", "image/jpeg", "application/pdf"];
      if (!allowedTypes.includes(fileObj?.type)) {
        setErrorMsg("File does not support. You must use .png, .jpg or .pdf ");
        return;
      }
    
       const fileSizeKiloBytes = fileObj?.size / 1024;
    
       if(fileSizeKiloBytes > MAX_FILE_SIZE){
        setErrorMsg("File size is greater than maximum limit");
        return
      }
    
    
        setUploading(true);
        const formData = new FormData();
        // Update the formData object
        formData.append(
            "file",
            fileObj,
            fileObj.name
        );
        const response = await fileUpload(formData);
        if(response.status === 200){
            item.member_document_file_name = response?.data?.filename;
            setErrorMsg('')
            setUploading(false);
        } else {
          setErrorMsg("Something went wrong!")
          setUploading(false);
        }
      };

    return (
        <div className="container-fluid sectionborder">
            <div className="row">
                <div className="col-sm-3 ">
                    <h5 className="headingTitle">{Utility.convertNumberToWordsForMember(counter + 1)}</h5>
                    <p className="contentdata">Please add detailed information for the first member of the family
                    </p>
                </div>
                <div className="col-sm-6 offset-sm-1">
           
                    <Form.Group className="form-group" controlId="fullName" >
                        <Form.Label for="fullName">Full Name </Form.Label>
                        <Form.Control
                            className="form-control"
                            type="text"
                            name="member_name"
                            placeholder="Enter Full Name"
                            value={item.member_name}
                            onChange={(e) => handleFormChange(counter, e, 'member_name')}
                            required
                        ></Form.Control>
                        <Form.Control.Feedback type="invalid">
                            Please provide Full Name.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="form-group" controlId="selectedRelation">
                        <Form.Label for="selectedRelation">Relation to Applicant?</Form.Label>
                        <Form.Control as='select' className="form-control" name="member_relation" value={item.member_relation}
                            onChange={(e) => handleFormChange(counter, e, 'member_relation')} required>
                            <option value=''>Select a Relation:</option>
                            {
                                relation && relation.map((data, key) => (
                                    <option key={key} value={data}>{data}  </option>
                                ))}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            Please Select Relation.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="form-group" controlId="dateOfBirth" >
                        <Form.Label for="dateOfBirth">Date of Birth</Form.Label>
                        <Form.Control
                            className="form-control"
                            type="date"
                            name="member_dob"
                            placeholder="Enter Date of Birth"
                            value={item.member_dob}
                            max={new Date().toISOString().slice(0, 10)}
                            onChange={(e) => handleFormChange(counter, e, 'member_dob')}
                            required
                        ></Form.Control>
                        <Form.Control.Feedback type="invalid">
                            Please provide Date of Birth.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="form-group" controlId="selectedGender">
                        <Form.Label for="selectedGender">Gender</Form.Label>
                        <Form.Control as='select'  name="member_gender" className="form-control" value={item.member_gender}
                            onChange={(e) => handleFormChange(counter, e, 'member_gender')} required>
                            <option value=''>Select a Gender:</option>
                            {
                                gender && gender.map((data, key) => (
                                    <option key={key} value={data}>{data}  </option>
                                ))}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            Please Select Gender.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="form-group" controlId="aadharNo" >
                        <Form.Label for="aadharNo">Aadhaar Number</Form.Label>
                        <InputGroup className="mb-3">
                            <Form.Control
                                className="form-control"
                                type="text"
                                name="member_aadhaar"
                                placeholder="Enter Aadhaar Number"
                                pattern='[0-9]{0,12}'
                                value={item.member_aadhaar}
                                disabled={item.aadhaarVerified}
                                onChange={(e) => handleFormChange(counter, e, 'member_aadhaar')}
                                required
                            ></Form.Control>
                            <Button disabled={item.aadhaarVerified} className={`btn-sm ${item.aadhaarVerified ? 'btn-success' : 'btn-primary'}`} id="button-addon2" onClick={(e) => aadharVerificationHandler(counter, e, item, 'aadhaarVerified')}>
                                {aadhaarloader ? <div className="spinner-border text-light" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div> : item.aadhaarVerified ? 'Verified' : 'Verify'}
                            </Button>
                        </InputGroup>
                        <Form.Control.Feedback type="invalid">
                            Please provide Aadhaar Number.
                        </Form.Control.Feedback>
                        {item.aadhaarVerified == false ? <p style={{color:'#f40039'}}>Please provide Valid Aadhaar Number</p>:""}
                    </Form.Group>
                    <Form.Group className="form-group" controlId="selectedOtherType">
                        <Form.Label for="selectedOtherType">Other ID Type</Form.Label>
                        <Form.Control as='select' className="form-control" name="member_other_id_type" ref={otherInputRef} value={item.member_other_id_type}
                            onChange={(e) => handleFormChange(counter, e, 'member_other_id_type')} required>
                            <option value=''>Select a Other ID Type:</option>
                            {
                                otherType && otherType.map((data, key) => (
                                    <option key={key} value={data}>{data}  </option>
                                ))}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            Please Select Other ID Type.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <div className="row">
                        <div className="col-md-10">
                            <Form.Group className="form-group" controlId="idNo" >
                                <Form.Label for="idNo">Other ID Number</Form.Label>
                                <Form.Control
                                    className="form-control"
                                    type="text"
                                    name="member_other_id_no"
                                    placeholder="Enter Other ID Number"
                                    value={item.member_other_id_no}
                                    onChange={(e) => handleFormChange(counter, e, 'member_other_id_no')}
                                    required
                                ></Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    Please provide Other ID Number.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </div>
                        <div className="col-md-2 text-right">
                        <Button variant="outline-primary" style={{marginTop: 35}} size="sm" title="Upload document" onClick={handleClick}>
                        <input
                            style={{ display: "none" }}
                            ref={inputRef}
                            type="file"
                            onChange={handleFileChange}
                        />
                            <img
                            style={{cursor:'pointer'}}
                                src="/assets/icons/upload.svg"
                                className="img-fluid"
                                alt="upload"
                                
                            />
                        </Button>
                        
                        </div>
                        <div className="col-md-12">
                        {errorMsg ? <p style={{color:'#f40039'}}>{errorMsg}</p>:""}
                        </div>
                    </div>
                    {/* <div className="row">
                        <div className="col">
                            <Form.Group className="form-group" controlId="idNo" >
                                <Form.Label for="idNo">Upload Document</Form.Label>
                                <Form.Control
                                    className="form-control"
                                    type="file"
                                    onChange={handleFileChange}
                                ></Form.Control>
                            </Form.Group>
                        </div>
                    </div> */}
                </div>
                <div className="col-sm-2">
                    <Button variant="outline-primary" size="sm"  onClick={() => removeMemberHandler(counter)}>X Delete Section </Button>
                </div>
            </div>

        </div>
    )
}

export default FamilyMeber