import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { logoutFromAPI } from "../services/PayloadAPIService";

import { useHistory } from "react-router-dom";

const SubmitIssuesModal = ({
  message,
  showSubmitModal,
}) => {
  const [show, setShow] = useState(showSubmitModal);
  const history = useHistory();

  const logouthandler = async () => {
    logoutFromAPI().then((response) => {
      if (response?.status === 200) {
        setShow(false);
        localStorage.removeItem("token");
        localStorage.removeItem("userId");
        localStorage.removeItem("mobileNo");
        history.push("/login");
      }
    });
  };

  return (
    <>
      <Modal
        show={show}
        size="md"
        onHide={logouthandler}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>
              <p className="tdfontalign">
                {/* Resubmission */}
                पुन्हा सादर करा
                </p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p>
              {/* {message} */}
              {/* Your changes have been updated successfully. Your application is being sent for review again. Please make sure you have made all the requested changes. Failure to do so may result in disqualification. */}
              आपल्या बदलांना यशस्वीरित्या अद्ययावत केल्या गेलेल्या आहेत. आपला अर्ज पुन्हा समीक्षा करण्यासाठी पाठवला जातो आहे. कृपया आपण सर्व अपेक्षित बदल केलेल्या आहेत, हे खात्री करा. हे करणार नसल्यास, हे निष्क्रियतेसाठी परिणामी होऊ शकते.
            </p>
        </Modal.Body>
        <Modal.Footer>

              <Button variant="primary" onClick={logouthandler}>
                Logout
              </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SubmitIssuesModal;
