import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
// import ApiDataService from "../../services/api-service";
import { useDispatch, useSelector } from "react-redux";
import { FilterPayload1Items, FilterPayload2Items } from "../../services/PayloadAPIService";
import { useHistory } from "react-router-dom";
import { clearUpdateReqState, selectUserPayload1, selectUserPayload2, fetchUserRequest, selectUserRequestData } from "../../redux/slices/userRequestSlice";
import Spinner from "../Spinner";
import { selectUserDropdownData } from "../../redux/slices/MasterDropdownSlice";
// import Alert from 'react-bootstrap/Alert';
import { Utility } from "../../utils/utility";
import { postPayload2Data } from "../../services/PayloadAPIService";

const EmployementAndFinance = ({ getApplicationIdHandler }) => {
    const [employeeStatus, setEmployeeStatus] = useState('');
    const [employement, setEmployement] = useState('');
    const [occupation, setOccupation] = useState('');
    const [occupationHandle, setOccupationHandle] = useState('');
    const [montlyIncome, setMontlyIncome] = useState('');
    const [bplCard, setBPLCard] = useState(false);
    const [bplCardNo, setBplCardNo] = useState("");
    const [userId, setUserId] = useState("");
    const [validated, setValidated] = useState(false);

    const history = useHistory();
    const [uploading, setUploading] = useState(false);
    const selectUserPayload2Data = useSelector(selectUserPayload2);
    const selectUserPayload1Data = useSelector(selectUserPayload1);
    const dispatch = useDispatch();
    const selectUpdateUserReqStatus = useSelector((state) => state.userRequest?.updateStatusRequest);
    const masterDropdownList = useSelector(selectUserDropdownData);
    const selectUserRequest = useSelector(selectUserRequestData);
    useEffect(() => {
        if (selectUpdateUserReqStatus?.state === 'succeeded') {
            dispatch(clearUpdateReqState());
        }
    }, [selectUpdateUserReqStatus?.state]);

    useEffect(() => {
        getApplicationIdHandler(selectUserRequest?.data?.application_id)
    }, [selectUserRequest?.data?.application_id]);

    useEffect(() => {
        if (userId)
            dispatch(fetchUserRequest({ userId }));
    }, [userId]);

    useEffect(() => {
        if (masterDropdownList?.config_id) {
            setEmployement(masterDropdownList?.config_id?.employment_status);
            setOccupationHandle(masterDropdownList?.config_id?.occupation)
        }
    }, [masterDropdownList?.config_id]);

    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
        } else {
            manageEmploymentHandler();
            event.preventDefault();
            event.stopPropagation();
        }
    };

    useEffect(() => {
        if (localStorage.getItem("userId")) {
            setUserId(localStorage.getItem("userId"))
        }
    }, []);

    useEffect(() => {
        if (selectUserPayload2Data) {
            setEmployeeStatus(selectUserPayload2Data?.employment_status);
            setOccupation(selectUserPayload2Data?.employment_occupation)
            setMontlyIncome(selectUserPayload2Data?.employment_avg_monthly_income)
            setBPLCard(selectUserPayload2Data?.employment_is_bpl)
            setBplCardNo(selectUserPayload2Data?.employment_bpl_card_no)
        }
    }, [selectUserPayload2Data]);

    const manageEmploymentHandler = async () => {
        setUploading(false);
        let input = {
            // flag: true,
            // isSaved: true,
            // employeeStatus: employeeStatus,
            // occupation: occupation,
            // montlyIncome: montlyIncome,
            // bplCard: bplCard,
            // bplCardNo: bplCardNo

            employment_status: employeeStatus,
            employment_occupation: occupation,
            employment_avg_monthly_income: montlyIncome,
            employment_is_bpl: bplCard,
            employment_bpl_card_no: bplCardNo,
        };

        let payload = {
            user_id: userId,
            payload1: FilterPayload1Items('employmentAndFinancialFilled', 'lastTab'),
            // selectUserPayload1Data
            payload2: FilterPayload2Items(input, selectUserPayload2Data)
        };

        let response = await postPayload2Data(payload);
        if (response.status == 200) {
            dispatch(fetchUserRequest({ userId }));
            history.push(`/dashboard/bank-details`);
        }
        setUploading(false);
    };

    // const handleVisible = () => {
    //     const timeId = setTimeout(() => {
    //         setUploading(false);
    //         history.push(`/dashboard/bank-details`);
    //     }, 3000)
    //     return () => {
    //         clearTimeout(timeId)
    //     }
    // }

    const validateData = () => {
        return (
            uploading ||
            !employeeStatus ||
            !occupation ||
            (montlyIncome && !Utility.validateMonthlyIncome(montlyIncome))
        );
    };

    return (
        <>
            <div className="container-fluid">
                {selectUpdateUserReqStatus?.state === 'loading' ? <Spinner /> :
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col ">
                                <h5 className="mainTitle" style={{ marginTop: 20, marginBottom: 20 }}>Employment and Financials</h5>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-3 ">
                                <h5 className="headingTitle">Employment Information</h5>
                                <p className="contentdata">Please enter the employment information of the head of the family
                                </p>
                                <p className="contentdata">For the monthly income, please enter the total average monthly income of all working household members combined.
                                </p>

                            </div>
                            <div className="col-sm-6 offset-sm-1">
                                <Form.Group className="form-group" controlId="employeeStatus">
                                    <Form.Label>Employment Status</Form.Label>
                                    <Form.Control as='select' className="form-control" value={employeeStatus}
                                        onChange={(e) => setEmployeeStatus(e.target.value)} required>
                                        <option value="">Select Employment Status</option>
                                        {
                                            employement && employement.map((data, key) => (
                                                <option key={key} value={data}>{data}  </option>
                                            ))}
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        Please Select Employment Status
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="form-group" controlId="occupation">
                                    <Form.Label>Occupation</Form.Label>
                                    <Form.Control as='select' className="form-control" value={occupation}
                                        onChange={(e) => setOccupation(e.target.value)} required>
                                        <option value=''>Select a Occupation:</option>
                                        {
                                            occupationHandle && occupationHandle.map((data, key) => (
                                                <option key={key} value={data}>{data}  </option>
                                            ))}
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        Please Select Occupation
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="form-group" controlId="montlyIncome">
                                    <Form.Label>Average monthly income of household (in rupees)</Form.Label>
                                    <Form.Control
                                        className="form-control"
                                        type="text"
                                        placeholder="Enter Average monthly income"
                                        value={montlyIncome}
                                        pattern='[0-9]{0,6}'
                                        maxLength={6}
                                        onChange={(e) => setMontlyIncome(e.target.value)}
                                        isInvalid={montlyIncome && !Utility.validateMonthlyIncome(montlyIncome)}
                                        required
                                    ></Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        {montlyIncome && !Utility.validateMonthlyIncome(montlyIncome) ? 'Average monthly income of household should not more than 500000' : '  Please provide Average monthly income of household (in rupees).'}
                                    </Form.Control.Feedback>
                                    {montlyIncome ? <Form.Label>Your annual income is Rs. {Number(montlyIncome) * 12}</Form.Label> : null}
                                </Form.Group>

                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="form-group" style={{ marginBottom: 0 }}>
                                            <label for="lbl">Does the family have a Below Poverty Line (BPL) card?</label>
                                        </div>
                                    </div>
                                </div>

                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" class="custom-control-input" name="bplCard" onChange={(e) => setBPLCard(e.target.value)} id="Yes" value="true" checked={bplCard === "true"} />
                                    <label class="custom-control-label" for="Yes">Yes</label>
                                </div>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input type="radio" class="custom-control-input" name="bplCard" onChange={(e) => setBPLCard(e.target.value)} id="no" value="false" checked={bplCard === "false"} />
                                    <label class="custom-control-label" for="no">No</label>
                                </div>

                                {bplCard == "true" ?
                                    <Form.Group className="form-group" style={{ marginTop: 20 }} controlId="bplCardNo" >
                                        <Form.Label>BPL Card No</Form.Label>
                                        <Form.Control
                                            className="form-control"
                                            type="text"
                                            placeholder="Enter BPL Card No"
                                            value={bplCardNo}
                                            maxLength={50}
                                            onChange={(e) => setBplCardNo(e.target.value)}
                                            isInvalid={bplCardNo && !Utility.noSpaceAlphnumaric(bplCardNo)}
                                            required
                                        ></Form.Control>
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter BPL Card No
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    : ""}
                                {bplCard === "true" ?
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="form-group">
                                                <label for="lbl">Please upload BPL card on Upload Documents Page</label>
                                            </div>
                                        </div>
                                    </div>
                                    : ""}
                            </div>
                        </div>
                        <hr className="mt-5" />
                        <div className="row">
                            <div className="col-sm-4 offset-sm-4">
                                <button className="btn btn-primary" type="submit"
                                // disabled={validateData()} 
                                >Save & Proceed</button>
                            </div>
                        </div>
                    </Form>}
            </div>
        </>
    )
}

export default EmployementAndFinance