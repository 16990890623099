import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Login from './pages/Login';
import './App.scss';
import Dashboard from './pages/Dashboard';
function App() {
  return (
       <Router>
      <div>

        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/dashboard">
            <Dashboard />
          </Route>
         
          <Route path="/">
            <Login />
          </Route>
        </Switch>
      </div>
    </Router>
   
  );
}

export default App;
