import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from "react-redux";
import { FilterPayload1Items, FilterPayload2Items } from "../../services/PayloadAPIService";
import { useHistory } from "react-router-dom";
import { clearUpdateReqState, updateRequestStatus, selectUpdateUserReqStatus, selectUserPayload2, fetchUserRequest, selectUserRequestData } from "../../redux/slices/userRequestSlice";
import Spinner from "../Spinner";
import { fileUpload } from '../../services/PayloadAPIService';
import '../../common/FileUploadStyle.scss';
import { postPayload2Data } from '../../services/PayloadAPIService';
import Modal from "react-bootstrap/Modal";

const UploadPhoto = ({ userId, onNextTabHandler }) => {
    const dispatch = useDispatch();
    const selectUserPayload2Data = useSelector(selectUserPayload2);
    const [fileUpload1, setFileUpload1] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [fileSize, setfileSize] = useState(false);

    const history = useHistory();
    const selectUpdateUserReqStatus = useSelector((state) => state.userRequest?.updateStatusRequest);
    const [headOfFamilyPhoto, setHeadOfFamilyPhoto] = useState(false);
    const [responseAlert, setResponseAlert] = useState(false);
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        if(selectUserPayload2Data?.applicant_photo_uploaded){
            setHeadOfFamilyPhoto(selectUserPayload2Data?.applicant_photo_uploaded);
        }
      }, [selectUserPayload2Data?.applicant_photo_uploaded]);

    const manageImageUploadHandler = async (fileName) => {
        let input = {
            applicant_photo_uploaded: true,
            applicant_photo_file_name:fileName
        };
        let payload = {
            user_id: userId,
            payload1: FilterPayload1Items('FamilyAndDependencyFilled', 'tab2'),
            payload2: FilterPayload2Items(input, selectUserPayload2Data)
        };

        let response = await postPayload2Data(payload);
        if(response.status==200){
            dispatch(fetchUserRequest({ userId }));
            if(selectUserPayload2Data?.applicant_photo_uploaded){
                setResponseAlert(true);
                handleShow(true);
            } else {
                onNextTabHandler("familyMemberMain");
            }
        }
        setUploading(false);
    };

    const uploadFileOnS3Handler = async() => {
        setResponseAlert(true)
        if (fileUpload1) {
            setUploading(true)
            const formData = new FormData();
            formData.append("file", fileUpload1);
      
            const response = await fileUpload(formData);
            if(response.status === 200){
              manageImageUploadHandler(response.data.filename)
            }
        }
    }

    useEffect(() => {
        function Init() {
            var fileSelect = document.getElementById('file-upload'),
                fileDrag = document.getElementById('file-drag'),
                submitButton = document.getElementById('submit-button');
            fileSelect.addEventListener('change', fileSelectHandler, false);
            var xhr = new XMLHttpRequest();
            if (xhr.upload) {
                // File Drop
                fileDrag.addEventListener('dragover', fileDragHover, false);
                fileDrag.addEventListener('dragleave', fileDragHover, false);
                fileDrag.addEventListener('drop', fileSelectHandler, false);
            }
        }

        function fileDragHover(e) {
            var fileDrag = document.getElementById('file-drag');
            e.stopPropagation();
            e.preventDefault();
            fileDrag.className = (e.type === 'dragover' ? 'hover' : 'modal-body file-upload');
        }
        function fileSelectHandler(e) {
            // Fetch FileList object
            var files = e.target.files || e.dataTransfer.files;

            // Cancel event and hover styling
            fileDragHover(e);
            // Process all File objects
            for (var i = 0, f; f = files[i]; i++) {
                parseFile(f);
            }
        }

        function output(msg) {
            var m = document.getElementById('messages');
            m.innerHTML = msg;
        }

        function parseFile(file) {
            setFileUpload1(file)
            output(
                '<strong>' + encodeURI(file.name) + '</strong>'
            );
            let fileSizeUpload = (file.size / (1024*1024)).toFixed(2);
            if(fileSizeUpload>=2){
              setfileSize(true);
            }else{
              setfileSize(false)
            }
            var imageName = file.name;
            var pdfFlag = (/\.(?=pdf)/gi).test(imageName);
            if (pdfFlag) {
                document.getElementById('start').classList.add("hidden");
                document.getElementById('response').classList.remove("hidden");
                document.getElementById('notimage').classList.add("hidden");
            } else {
                var isGood = (/\.(?=gif|jpg|png|jpeg|pdf)/gi).test(imageName);

                if (isGood) {
                    document.getElementById('start').classList.add("hidden");
                    document.getElementById('response').classList.remove("hidden");
                    document.getElementById('notimage').classList.add("hidden");
                    // Thumbnail Preview
                    document.getElementById('file-image').classList.remove("hidden");
                    document.getElementById('file-image').src = URL.createObjectURL(file);
                }
                else {
                    document.getElementById('file-image').classList.add("hidden");
                    document.getElementById('notimage').classList.remove("hidden");
                    document.getElementById('start').classList.remove("hidden");
                    document.getElementById('response').classList.add("hidden");
                    document.getElementById("file-upload-form").reset();
                }
            }
        }
        // Check for the various File API support.
        if (window.File && window.FileList && window.FileReader) {
            Init();
        } else {
            document.getElementById('file-drag').style.display = 'none';
        }
    })

    const removeImage=()=>{
        setHeadOfFamilyPhoto(false);
        document.getElementById('file-image').classList.add("hidden");
        document.getElementById('notimage').classList.remove("hidden");
        document.getElementById('start').classList.remove("hidden");
        document.getElementById('response').classList.add("hidden");
        document.getElementById("file-upload-form").reset();
        setFileUpload1(null);
        setfileSize(false);
      }



    return (
        <div className="container-fluid">
            {responseAlert ?
        <Modal  show={show} size="md" onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title >
              Alert
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            File uploaded successfully.
          </Modal.Body>
        </Modal>
        :
        null
      }
            <div className="row">
                <div className="col-sm-4 ">
                    <h5 className="headingTitle">Applicant Photo</h5>
                    <p className="contentdata">Upload the photo of the applicant. Please make sure that the photo is clear on a plain background (preferably white).
                    </p>
                    <p className="contentdata">It should have full face, front view, eyes open. Photo should present full head from top of hair to bottom of chin. The photograph should have a minimum resolution on 300 x 300 px
                    </p>
                    {fileSize ?<p className="imgwarning">size of upload document should not be more than 2 MB</p>:""}
                </div>
                <div className="col-sm-5 offset-sm-1">
                {headOfFamilyPhoto ? <div className="text-center">
                    Applicant document <span style={{color:'#56cdad'}}>successfully uploaded</span>. If you wish to reupload, <a onClick={() => setHeadOfFamilyPhoto(false)} style={{cursor:"pointer", textDecoration:"underline"}}>click here</a>
                </div>: null}
    
                    <form id="file-upload-form" style={{display : headOfFamilyPhoto ? 'none' : 'block'}} className="fileuploader">
                        <input id="file-upload" type="file" name="fileUpload" accept="image/*" />
                        <label for="file-upload" id="file-drag" className="fileUploadLabel">
                            
                            <div className="file-pv-container">
                            <img id="file-image" src="#" alt="Preview" className="hidden" />
                            {fileUpload1 ? <button type="button" class="btn btn-outline-primary btn-sm" onClick={removeImage}>X</button> : null}
                            </div>
                            <div id="start">
                                <i className="fa fa-file-image-o" aria-hidden="true"></i>
                                <div className="imagelabel">Click to add or drag and drop Applicant Photo</div>
                                <p className="contentdata ">PNG,JPG or PDF file</p>
                                <div id="notimage" className="hidden">Please select an image or PDF</div>
                            </div>
                            <div id="response" className="hidden">
                                <div id="messages"></div>
                            </div>
                        </label>
                    </form>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-4 offset-sm-5">

                    {selectUpdateUserReqStatus?.state === 'loading' ? <Spinner /> :
                        headOfFamilyPhoto ? <button className="btn btn-primary mt-5" type="button" onClick={() => onNextTabHandler('familyMemberMain')}>
                        Save & Proceed
                    </button> : <button className="btn btn-primary mt-5" type="button" disabled={!(fileUpload || headOfFamilyPhoto) || fileSize } onClick={() => uploadFileOnS3Handler()}>
                            Save & Proceed
                        </button>}
                </div>
            </div>
        </div>
    )
}

export default UploadPhoto