import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { FilterPayload1Items, FilterPayload2Items } from "../../services/PayloadAPIService";
import { useHistory } from "react-router-dom";
import {  selectUserPayload1, selectUserPayload2, fetchUserRequest, selectUserRequestData } from "../../redux/slices/userRequestSlice";
import Spinner from "../Spinner";
// import ErrorPage from "../../pages/ErrorPage.js";
import { postPayload2Data } from "../../services/PayloadAPIService";
import { Utility } from "../../utils/utility.js";

const SpecialCase = ({ getApplicationIdHandler }) => {
    const [disability, setDisability] = useState('false');
    const [disabilityCardNo, setDisabilityCardNo] = useState("");
    const [userId, setUserId] = useState("");
    const history = useHistory();
    const [uploading, setUploading] = useState(false);
    const [disabilityCardNoValidation, setDisabilityCardNoValidation] = useState(false);
    const selectUserPayload2Data = useSelector(selectUserPayload2);
    const selectUserPayload1Data = useSelector(selectUserPayload1);
    const dispatch = useDispatch();
    const selectUpdateUserReqStatus = useSelector((state) => state.userRequest?.updateStatusRequest);
    const selectUserRequest = useSelector(selectUserRequestData);

    useEffect(() => {
        if (localStorage.getItem("userId")) {
            setUserId(localStorage.getItem("userId"))
        }
    }, []);

    useEffect(() => {
        if (userId)
            dispatch(fetchUserRequest({ userId }));
    }, [userId]);

    useEffect(() => {
        getApplicationIdHandler(selectUserRequest?.data?.application_id)
    }, [selectUserRequest?.data?.application_id]);

    useEffect(() => {
        if (selectUserPayload2Data) {
            setDisability(selectUserPayload2Data?.special_case_is_disability);
            setDisabilityCardNo(selectUserPayload2Data?.special_case_disability_card_no);
        }
    }, [selectUserPayload2Data]);

    const manageSpecialDetailHandler = async () => {
        if (disability == 'true' && !Utility.validateAlphaNumeric(disabilityCardNo)) {
            setDisabilityCardNoValidation(true)
            return
        }
        setDisabilityCardNoValidation(false)

        setUploading(true);
        let input = {
            // flag: true,
            // isSaved: true,
            // disability: disability,
            // disabilityCardNo: disabilityCardNo

            special_case_is_disability: disability,
            special_case_disability_card_no: disabilityCardNo,
        };

        let payload = {
            user_id: userId,
            payload1: FilterPayload1Items('specialCasesFilled', 'lastTab'),
            payload2: FilterPayload2Items(input, selectUserPayload2Data)
        };

        let response = await postPayload2Data(payload);
        if (response.status == 200) {
            dispatch(fetchUserRequest({ userId }));
            history.push(`/dashboard/upload-documents`);
        }
        setUploading(false);
    };

    return (
        <>
            {selectUpdateUserReqStatus?.state === 'loading' ? <Spinner /> :
                <div className="container-fluid">
                    <div className="row">
                        <div className="col ">
                            <h5 className="mainTitle" style={{ marginTop: 20, marginBottom: 20 }}>Special Cases</h5>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-3 ">
                            <h5 className="headingTitle">Special Cases</h5>
                            <p className="contentdata">This section contains options for special cases   </p>
                        </div>
                        <div className="col-sm-6 offset-sm-1">

                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <label for="lbl">Is the applicant a person with disability</label>
                                    </div>
                                </div>
                            </div>

                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" name="disability" class="custom-control-input" onChange={(e) => setDisability(e.target.value)} id="Yes" value="true" checked={disability === "true"} />
                                <label class="custom-control-label" for="Yes">Yes</label>
                            </div>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" name="disability" class="custom-control-input" onChange={(e) => setDisability(e.target.value)} id="no" value="false" checked={disability === "false"} />
                                <label class="custom-control-label" for="no">No</label>
                            </div>

                            {disability === "true" ? <>
                                <Form.Group className="form-group" style={{ marginTop: 20 }} controlId="disabilityCardNo" >
                                    <Form.Label>Disability Card No</Form.Label>
                                    <Form.Control
                                        className="form-control"
                                        type="text"
                                        placeholder="Enter Disability Card No"
                                        value={disabilityCardNo}
                                        // pattern='[0-9]{0,15}'
                                        maxLength={30}
                                        onChange={(e) => setDisabilityCardNo(e.target.value)}
                                        required
                                    ></Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        Please Enter Disability Card No
                                    </Form.Control.Feedback>
                                    {disabilityCardNoValidation ? <p style={{ color: '#ff6550' }}>Please Enter Valid Disability Card No</p> : null}
                                </Form.Group>

                            </>
                            : ""}

                            {disability === "true" ?
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="form-group">
                                            <label for="lbl">Please upload Disability card on Upload Documents Page</label>
                                        </div>
                                    </div>
                                </div>
                                : ""}
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-sm-4 offset-sm-4">
                            <button className="btn btn-primary mt-5" type="button" onClick={() => manageSpecialDetailHandler()}>
                                Proceed
                            </button>
                        </div>
                    </div>
                </div>}
        </>
    )
}

export default SpecialCase