import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserRequest, selectUserRequestData } from "../../redux/slices/userRequestSlice";
import { onlinePayment } from "../../services/PayloadAPIService";


const PaymentStatusFailure = ({ selectUserPaymentDetails }) => {

  const [userMobile, setUserMobile] = useState("");
  const [applicationId, setApplicationId] = useState("");
  const selectUserRequest = useSelector(selectUserRequestData);

  useEffect(() => {
    if (localStorage.getItem("mobileNo")) {
      setUserMobile(localStorage.getItem("mobileNo"));
    }
  }, []);

  useEffect(() => {
    if (selectUserRequest?.data?.application_id) {
      setApplicationId(selectUserRequest?.data?.application_id);
    }
  }, [selectUserRequest?.data?.application_id]);

  const managePaymentHandler = async (projName, userId) => {
    onlinePayment(projName, userId, userMobile, applicationId)
      .then(response => {
        if (response.status === 200) {
          let resData = response.data;
          postToUrl(resData.billdesk_url, { 'msg': resData.msg });
        }
      })
      .catch(err => {
        console.log('An error occurred:', err.res);
      });
  }

  function postToUrl(path, params, method = 'post') {
    const form = document.createElement('form');
    form.method = method;
    form.action = path;
    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        const hiddenField = document.createElement('input');
        hiddenField.type = 'hidden';
        hiddenField.name = key;
        hiddenField.value = params[key];
        form.appendChild(hiddenField);
      }
    }
    document.body.appendChild(form);
    form.submit();
  }

  return (
    <>
      <div className="col-sm-5 offset-md-3 text-center payment-pending-content">
        <img
          src="/assets/icons/payment-failure.svg"
          style={{ marginBottom: 30, marginTop: 30 }}
          alt="payment status"
        />
        <p className="contentdata">
          Payment failed. If you fund were debited they will be refunded in 3-5 working days.
        </p>
        <div style={{ marginTop: 20 }}>
          <h4 className="payment-amount">₹ {selectUserPaymentDetails?.txn_amount ? selectUserPaymentDetails?.txn_amount : 0} </h4>
        </div>
        <hr />
        <div style={{ marginTop: 30 }}>
          <table className="table" cellspacing="0" cellpadding="0">
            <tr>
              <td className="first">Payment Method</td>
              <td className="second">{selectUserPaymentDetails?.payment_method}</td>
            </tr>
            <tr>
              <td className="first">Status</td>
              <td className="second">
                <span className="failure-color">Payment Failed</span>
              </td>
            </tr>
            <tr>
              <td className="first">Transaction ID</td>
              <td className="second">{selectUserPaymentDetails?.transaction_id}</td>
            </tr>

            <tr>
              <td className="first">Transaction Ref</td>
              <td className="second">{selectUserPaymentDetails?.txn_reference_no}</td>
            </tr>
          </table>
        </div>
        <div>
          <button
            className="btn btn-primary pl-5 pr-5 mt-4 form-control"
            type="button"
            onClick={() => managePaymentHandler(selectUserPaymentDetails?.project_name, selectUserPaymentDetails?.user_id)}
          >
            Retry Payment
          </button>
        </div>
      </div>
    </>
  );
};

export default PaymentStatusFailure;
