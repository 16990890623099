import React, { useState, useEffect } from "react";
// import { useRouteMatch, useLocation } from "react-router-dom";
import styled from "styled-components";
import "bootstrap/dist/css/bootstrap.min.css";
import "bs-stepper/dist/css/bs-stepper.min.css";

import Stepper from "bs-stepper";
import HousingStatus from "./HousingStatus";
import CurrentAddress from "./CurrentAddress";
import PermanentAddress from "./PermanentAddress";
import HouseDetail from "./HouseDetail";
import { fetchUserRequest, selectUserRequestData } from "../../redux/slices/userRequestSlice";
import { useDispatch, useSelector } from "react-redux";

const AddressHousing = ({getApplicationIdHandler}) => {
  // const { path, url } = useRouteMatch();

  const [stepper, setStepper] = useState();
  const [userId, setUserId] = useState("");
  const [pageInitialLoad, setPageInitialLoad] = useState("housingStatus");
  const selectUserRequest = useSelector(selectUserRequestData);
  const dispatch = useDispatch();

  useEffect(() => {
    setStepper(
      new Stepper(document.querySelector("#mainTabStepper"), {
        linear: false,
        animation: true,
      })
    );
    setPageInitialLoad('housingStatus')
  }, []);

  useEffect(() => {
    getApplicationIdHandler(selectUserRequest?.data?.application_id)
  }, [selectUserRequest?.data?.application_id]);

  useEffect(() => {
    if (localStorage.getItem("userId")) {
      setUserId(localStorage.getItem("userId"))
    }
  }, []);

  useEffect(() => {
    if (userId)
      dispatch(fetchUserRequest({ userId }));
  }, [userId]);


  const onNextTabHandler = (page) => {
    if (stepper && page) {
      stepper.next();
      setPageInitialLoad(page)
    }
  };

  return (
    <MainTabStepper>
      <Title className="pagetitle">Address & Housing</Title>
      <div id="mainTabStepper" className="bs-stepper">
        <div className="bs-stepper-header">
          <div className="step active" data-target="#test-l-1">
            <button className="step-trigger step-trigger1" onClick={() => { setPageInitialLoad('housingStatus') }}>
              <span className="bs-stepper-circle">
                <img
                  src="/assets/icons/building-grey.svg"
                  className="img-fluid"
                  alt="Status"
                />
                <img
                  src="/assets/icons/white/company.svg"
                  className="img-fluid active-icon"
                  alt="Status"
                />
              </span>
              <span className="bs-stepper-label">
                <div style={{ color: "#4640de", fontSize: 14, fontWeight: 500 }}>
                  Step 1/4
                </div>
                <div>Housing Status</div>
              </span>
            </button>
          </div>
          <div className="line"></div>
          <div className="step" data-target="#test-l-2">
            <button className="step-trigger" onClick={() => { setPageInitialLoad('currentAddress') }}>
              <span className="bs-stepper-circle">
                <img
                  src="/assets/icons/icon-location.svg"
                  className="img-fluid"
                  alt="Status"
                />
                <img
                  src="/assets/icons/white/location.svg"
                  className="img-fluid  active-icon"
                  alt="Status"
                />
              </span>
              <span className="bs-stepper-label">
                <div
                  style={{ color: "#4640de", fontSize: 14, fontWeight: 500 }}
                >
                  Step 2/4
                </div>
                <div>Current Address</div>
              </span>
            </button>
          </div>
          <div className="line"></div>
          <div className="step" data-target="#test-l-3" onClick={() => { setPageInitialLoad('permanentAddress') }}>
            <button className="step-trigger">
              <span className="bs-stepper-circle">
                <img
                  src="/assets/icons/icon-location.svg"
                  className="img-fluid"
                  alt="Status"
                />
                <img
                  src="/assets/icons/white/location.svg"
                  className="img-fluid  active-icon"
                  alt="Status"
                />
              </span>
              <span className="bs-stepper-label">
                <div
                  style={{ color: "#4640de", fontSize: 14, fontWeight: 500 }}
                >
                  Step 3/4
                </div>
                <div>Permanent Address</div>
              </span>
            </button>
          </div>
          <div className="line"></div>
          <div className="step" data-target="#test-l-4" onClick={() => { setPageInitialLoad('houseDetails') }}>
            <button className="step-trigger">
              <span className="bs-stepper-circle">
                <img
                  src="/assets/icons/icon-home-grey.svg"
                  className="img-fluid"
                  alt="Status"
                />
                <img
                  src="/assets/icons/white/house.svg"
                  className="img-fluid active-icon"
                  alt="Status"
                />
              </span>
              <span className="bs-stepper-label">
                <div
                  style={{ color: "#4640de", fontSize: 14, fontWeight: 500 }}
                >
                  Step 4/4
                </div>
                <div>House Details</div>
              </span>
            </button>
          </div>
        </div>
        <div className="bs-stepper-content">
          <div id="test-l-1" className="content fade active dstepper-block" style={{ display: pageInitialLoad === 'housingStatus' ? 'block' : 'none' }}>
            {pageInitialLoad === 'housingStatus' ? <HousingStatus userId={userId} onNextTabHandler={onNextTabHandler} /> : null}
          </div>
          <div id="test-l-2" className="content" style={{ display: pageInitialLoad === 'currentAddress' ? 'block' : 'none' }}>
            {pageInitialLoad === 'currentAddress' ? <CurrentAddress userId={userId} onNextTabHandler={onNextTabHandler} /> : null}
          </div>
          <div id="test-l-3" className="content" style={{ display: pageInitialLoad === 'permanentAddress' ? 'block' : 'none' }}>
            {pageInitialLoad === 'permanentAddress' ? <PermanentAddress userId={userId} onNextTabHandler={onNextTabHandler} /> : null}
          </div>
          <div id="test-l-4" className="content" style={{ display: pageInitialLoad === 'houseDetails' ? 'block' : 'none' }}>
            {pageInitialLoad === 'houseDetails' ? <HouseDetail userId={userId} onNextTabHandler={onNextTabHandler} /> : null}
          </div>
        </div>
      </div>
    </MainTabStepper>
  );
};

export default AddressHousing;

const Title = styled.h3`
  font-family: ClashDisplay;
  font-size: 32px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  color: #25324b;
  margin-bottom: 15px;

  @media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait){
    font-family: ClashDisplay;
    font-size: 20px;
  }
 }`;


const MainTabStepper = styled.div`
   padding:15px;
  .bs-stepper-header {
    justify-content: space-between;
    border-radius: 4px;
    border: solid 1px #d6ddeb;
    background-color: #fff;
    margin-bottom: 25px;
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .bs-stepper-header {
      overflow-x: auto;
    }
  }

  .bs-stepper .line, .bs-stepper-line {
      display: none;
  }
  .bs-stepper .step{
    flex: 1;
    padding: 5px;
  }
  .bs-stepper .step-trigger{
    border-left: 1px solid #d6ddeb;
    padding: 0px;
    border-radius: 0;
    width: 100%;
    text-align: left;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .bs-stepper .step-trigger1{
    border-left: 0;
  }
  .bs-stepper-circle {
    width: 3em;
    height: 3em;
    padding: 12px;
    border-radius: 50%;
    background-color: #e9ebfd;
  }
  .bs-stepper .step-trigger:hover {
    background: none;
    border-radius: 0px;
  }
  .bs-stepper-circle .active-icon{
    display:none;
    padding: 2px;
  }
  .active .bs-stepper-circle {
    fill: white;
  }
  .active .bs-stepper-circle {
    background-color: #4640de;
  }
  .active .bs-stepper-circle img {
    display:none;
  }
  .active .bs-stepper-circle .active-icon{
    display:block;
  }
  .active .bs-stepper-circle:hover  {
    // background-color: #007bff;
  }
  .bs-stepper-label{
    font-size: 14px;
  }


`;
