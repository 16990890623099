import React, { useState, useEffect } from "react";
import styled from "styled-components";
import "bootstrap/dist/css/bootstrap.min.css";
import "bs-stepper/dist/css/bs-stepper.min.css";
import Stepper from "bs-stepper";
import { fetchUserRequest, selectUserRequestData, selectUserPayload2 } from "../../redux/slices/userRequestSlice";
import { useDispatch, useSelector } from "react-redux";
import OnlinePayment from "./OnlinePayment";
import PaymentTerm from "./PaymentTerm";
import PaymentStatus from "./PaymentStatus"
import { fetchUserWisePayment, selectUserWisePayment } from "../../redux/slices/userWisePaymentSlice";
import { Link, useLocation, BrowserRouter as Router } from "react-router-dom";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}
const PaymentDetails = ({ getApplicationIdHandler }) => {
    let query = useQuery();
    
    const [stepper, setStepper] = useState();
    const [userId, setUserId] = useState("");

    const [getTab, setTab] = useState(query.get("tab"));
    const [getArea, setArea] = useState(query.get("area"));

    const [pageInitialLoad, setPageInitialLoad] = useState("paymentTerm");
    const dispatch = useDispatch();
    const selectUserRequest = useSelector(selectUserRequestData);
    const selectUserPayload2Data = useSelector(selectUserPayload2);
    const selectUserPaymentDetails = useSelector(selectUserWisePayment);
    const [acceptTerms, setAcceptTerms] = useState(false);
    useEffect(() => {
        if (getTab && getArea && stepper && acceptTerms) {
            if(Number(getTab)===3){
                stepper.to(3);
                setPageInitialLoad('paymentStatus')
            }
        }
    }, [getTab,getArea, stepper, acceptTerms]);


    useEffect(() => {
        if (localStorage.getItem("userId")) {
          setUserId(localStorage.getItem("userId"))
        }
      }, []);

    useEffect(() => {
        if (selectUserPayload2Data?.payment_terms_accepted) {
          setAcceptTerms(selectUserPayload2Data?.payment_terms_accepted);
        }
      }, [selectUserPayload2Data?.payment_terms_accepted]);

    useEffect(() => {
        getApplicationIdHandler(selectUserRequest?.data?.application_id)
    }, [selectUserRequest?.data?.application_id]);

    useEffect(() => {
        if (userId)
            dispatch(fetchUserRequest({ userId }));
    }, [userId]);

    useEffect(() => {
        if (userId)
            dispatch(fetchUserWisePayment({ userId }));
    }, [userId]);

    useEffect(() => {
        setStepper(
            new Stepper(document.querySelector("#mainTabStepper"), {
                linear: false,
                animation: true,
            })
        );
        // setPageInitialLoad('paymentTerm')
    }, []);


    const onNextTabHandler = (page) => {
        if (stepper && page) {
            stepper.next();
            setPageInitialLoad(page)
        } else if(page==='projectWisePayment'){
            dispatch(fetchUserRequest({ userId }));
        }
    };

    return (
        <MainTabStepper>
            <Title>Payment </Title>
            <div id="mainTabStepper" className="bs-stepper">
                <div className="bs-stepper-header">
                    <div className="step active" data-target="#test-l-1" >
                        <button className="step-trigger step-trigger1" onClick={() => { setPageInitialLoad("paymentTerm") }}>
                            <span className="bs-stepper-circle">
                                <img
                                    src="/assets/icons/payment-term-white.svg"
                                    className="img-fluid"
                                    alt="Status"
                                />
                                <img
                                    src="/assets/icons/payment-term-white.svg"
                                    className="img-fluid active-icon"
                                    alt="Status"
                                />
                            </span>
                            <span className="bs-stepper-label">
                                <div style={{ color: "#4640de", fontSize: 14, fontWeight: 500 }}>
                                    Step 1/3
                                </div>
                                <div>Payment Terms</div>
                            </span>
                        </button>
                    </div>
                    <div className="line"></div>
                    <div className="step" data-target="#test-l-2" >
                        <button className="step-trigger" 
                        // disabled={!acceptTerms} 
                        onClick={() => { setPageInitialLoad("projectWisePayment") }}>
                            <span className="bs-stepper-circle">
                                <img
                                    src="/assets/icons/icon-dollar-grey.svg"
                                    className="img-fluid"
                                    alt="Status"
                                />
                                <img
                                    src="/assets/icons/icon-dollar-white.svg"
                                    className="img-fluid active-icon"
                                    alt="Status"
                                />
                            </span>
                            <span className="bs-stepper-label">
                                <div
                                    style={{ color: "#4640de", fontSize: 14, fontWeight: 500 }}
                                >
                                    Step 2/3
                                </div>
                                <div>Project Wise Payment</div>
                            </span>
                        </button>
                    </div>
                    <div className="line"></div>
                    <div className="step" data-target="#test-l-3" >
                        <button className="step-trigger" 
                        // disabled={!acceptTerms} 
                        onClick={() => { setPageInitialLoad("paymentStatus") }}>
                            <span className="bs-stepper-circle">
                                <img
                                    src="/assets/icons/icon-check-grey.svg"
                                    className="img-fluid"
                                    alt="Status"
                                />
                                <img
                                    src="/assets/icons/white/check.svg"
                                    className="img-fluid active-icon"
                                    alt="Status"
                                />
                            </span>
                            <span className="bs-stepper-label">
                                <div
                                    style={{ color: "#4640de", fontSize: 14, fontWeight: 500 }}
                                >
                                    Step 3/3
                                </div>
                                <div>Payment Status</div>
                            </span>
                        </button>
                    </div>

                </div>
                <div className="bs-stepper-content" >
                    <div id="test-l-1" aria-labelledby="basic-information-btn-1" className="content fade active dstepper-block"
                        style={{ display: pageInitialLoad == 'paymentTerm' ? 'block' : 'none' }}>
                        {pageInitialLoad === 'paymentTerm' ?   <PaymentTerm userId={userId} onNextTabHandler={onNextTabHandler} /> : null}
                    </div>
                    <div id="test-l-2" className="content" style={{ display: pageInitialLoad === 'projectWisePayment' ? 'block' : 'none' }}>
                        {pageInitialLoad === 'projectWisePayment' ? <OnlinePayment userId={userId} onNextTabHandler={onNextTabHandler} selectUserPaymentDetails={selectUserPaymentDetails?.projectwise_transactions} /> : null}
                    </div>
                    <div id="test-l-3" className="content" style={{ display: pageInitialLoad === 'paymentStatus' ? 'block' : 'none' }}>
                        {pageInitialLoad === 'paymentStatus' ? <PaymentStatus userId={userId} onNextTabHandler={onNextTabHandler} selectUserPaymentDetails={selectUserPaymentDetails?.projectwise_transactions} /> : null} 
                    </div>
                </div>
            </div>
        </MainTabStepper>
    );
};
export default PaymentDetails
const Title = styled.h3`
  font-family: ClashDisplay;
  font-size: 32px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  color: #25324b;
  margin-bottom: 15px;
  
  @media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait){
    font-family: ClashDisplay;
    font-size: 20px;
  }
`;

const MainTabStepper = styled.div`
   padding:15px;
  .bs-stepper-header {
    justify-content: space-between;
    border-radius: 4px;
    border: solid 1px #d6ddeb;
    background-color: #fff;
    margin-bottom: 25px;
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    .bs-stepper-header {
        overflow-x: auto;
      }
}

  .bs-stepper .line, .bs-stepper-line {
      display: none;
  }
  .bs-stepper .step{
    flex: 1;
    padding: 5px;
  }
  .bs-stepper .step-trigger{
    border-left: 1px solid #d6ddeb;
    padding: 0px;
    border-radius: 0;
    width: 100%;
    text-align: left;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .bs-stepper .step-trigger1{
    border-left: 0;
  }
  .bs-stepper-circle {
    width: 3em;
    height: 3em;
    padding: 12px;
    border-radius: 50%;
    background-color: #e9ebfd;
  }
  .bs-stepper .step-trigger:hover {
    background: none;
    border-radius: 0px;
  }
  .bs-stepper-circle .active-icon{
    display:none;
    padding: 2px;
  }
  .active .bs-stepper-circle {
    fill: white;
  }
  .active .bs-stepper-circle {
    background-color: #4640de;
  }
  .active .bs-stepper-circle img {
    display:none;
  }
  .active .bs-stepper-circle .active-icon{
    display:block;
  }
  .active .bs-stepper-circle:hover  {
    // background-color: #007bff;
  }
  .bs-stepper-label{
    font-size: 14px;
  }
`;
