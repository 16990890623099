import React, { useState } from "react";
import LoginForm from "../components/LoginForm";
import "./Login.scss";

const Login = () => {
  const [languageChnage, setLanguageChnage] = useState(false);
  return (
    <>
      <div className="leadpage-container">
        <div className="leadpage sidebar">
          <div className="sidebar-content text-center">
              <img src="/assets/login-banner.png" alt="Login Banner" />
          </div>
        </div>
        <div className="leadpage right-box-content">
        {/* {languageChnage ? <p className="contentdata" style={{fontSize:17, marginBottom:15, padding:"0 15px"}}>
          Last date for Online Application Registration has been extended till 12th August 2023<a
                    style={{
                      color: "#4640de",
                      fontSize: 16,
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: 1.63,
                      letterSpacing: "normal",
                      marginLeft: 10,
                      cursor: "Pointer"
                    }}
                    onClick={() => setLanguageChnage(false)}
                  >
                    (मराठीत साठी येथे क्लिक करा)
                  </a>
            </p> : <p className="contentdata" style={{fontSize:17, marginBottom:15}}>
          ऑनलाईन अर्ज नोंदणीची अंतिम तारीख 12 ऑगस्ट 2023 पर्यंत वाढवण्यात आली आहे <a
                    style={{
                      color: "#4640de",
                      fontSize: 16,
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: 1.63,
                      letterSpacing: "normal",
                      marginLeft: 10,
                      cursor: "Pointer"
                    }}
                    onClick={() => setLanguageChnage(true)}
                  >
                    (For English click here)
                  </a>
            </p> } */}
          <div className="leadpage-form">
         
           <div className="text-center">
            
           <h4 className="form-title">Pradhan Mantri Awas Yojana</h4>
            <small className="online-lottery-lbl">Online Application Process</small>
            <p className="intro-subtitle">
              log in with your aadhaar linked mobile number
            </p>
           </div>
            <LoginForm/>
          </div>
        </div>
      </div>
   
    </>
  );
};

export default Login;
