import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
// import ApiDataService from "../../services/api-service";
import { useDispatch, useSelector } from "react-redux";
import { FilterPayload1Items, FilterPayload2Items } from "../../services/PayloadAPIService";
// import { useHistory } from "react-router-dom";
import { selectUserPayload2, fetchUserRequest } from "../../redux/slices/userRequestSlice";
import Spinner from "../Spinner";
import { postPayload2Data, getPincodeVerificationData } from "../../services/PayloadAPIService";

const PermanentAddress = ({ userId, onNextTabHandler }) => {
    const selectDataUserReqStatus = useSelector((state) => state.userRequest?.userRequestListState);
    const [pAddPinCode, setPAddPinCode] = useState('');
    const [pAddState, setPAddState] = useState([]);
    const [selectedpAddState, setSelectedPAddState] = useState([]);
    const [pAddDistrict, setPAddDistrict] = useState([]);
    const [selectedpAddDistrict, setSelectedPAddDistrict] = useState([]);
    const [pAddCity, setPAddCity] = useState([]);
    const [selectPCity, setPCitySelected] = useState('');
    const [pAddAreaName, setPAddAreaName] = useState('');
    const [pAddDoorNo, setPAddDoorNo] = useState('');

    const [sameAddress, setSameAddress] = useState(false);
    const [validateFlag, setValidateFlag] = useState(false);
    const selectUpdateUserReqStatus = useSelector((state) => state.userRequest?.updateStatusRequest);

    const [validated, setValidated] = useState(false);
    const dispatch = useDispatch();
    // const history = useHistory();
    const [uploading, setUploading] = useState(false);
    const selectUserPayload2Data = useSelector(selectUserPayload2);
    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
        } else {
            managePermanentAddressHandler();
            event.preventDefault();
            event.stopPropagation();
        }
    };

    const validateSameAddress = async (data) => {
        
        if (!sameAddress) {
            if (selectUserPayload2Data?.current_address_pin) {
                getPincodeData(selectUserPayload2Data?.current_address_pin);
            }
            setSameAddress(true);
            setPAddPinCode(selectUserPayload2Data?.current_address_pin);
            setSelectedPAddState(selectUserPayload2Data?.current_address_state);
            setSelectedPAddDistrict(selectUserPayload2Data?.current_address_district);
            setPCitySelected(selectUserPayload2Data?.current_address_area);
            setPAddAreaName(selectUserPayload2Data?.current_address_street);
            setPAddDoorNo(selectUserPayload2Data?.current_address_house_no);
        } else {
            setSameAddress(false);
            setPAddPinCode("");
            setSelectedPAddState("");
            setSelectedPAddDistrict("");
            setPCitySelected("");
            setPAddAreaName("");
            setPAddDoorNo("");
        }
    }

    useEffect(() => {
        if (selectUserPayload2Data?.permanent_address_pin) {
            getPincodeData(selectUserPayload2Data?.permanent_address_pin);
        }
        if (selectUserPayload2Data?.is_permanent_address_same_as_current) {
            setValidateFlag(true);
        }
    }, [selectUpdateUserReqStatus?.state]);

    useEffect(() => {
        if (selectUserPayload2Data?.is_permanent_address_same_as_current) {
            setValidateFlag(true);
        }
    }, [selectUserPayload2Data?.is_permanent_address_same_as_current]);

    useEffect(() => {
        if (selectUserPayload2Data) {
            setPAddPinCode(selectUserPayload2Data?.permanent_address_pin);
            setSelectedPAddState(selectUserPayload2Data?.permanent_address_state);
            setSelectedPAddDistrict(selectUserPayload2Data?.permanent_address_district);
            setPCitySelected(selectUserPayload2Data?.permanent_address_area);
            setPAddAreaName(selectUserPayload2Data?.permanent_address_street);
            setPAddDoorNo(selectUserPayload2Data?.permanent_address_house_no);
        }

    }, [selectUserPayload2Data]);

    const managePermanentAddressHandler = async () => {
        setUploading(true);
        let input = {
            // flag: true,
            // isSaved: true,
            // pinCode: pAddPinCode,
            // state: selectedpAddState,
            // district: selectedpAddDistrict,
            // city: selectPCity,
            // areaName: pAddAreaName,
            // doorNo: pAddDoorNo,
            // sameAddress: sameAddress

            permanent_address_pin: pAddPinCode,
            permanent_address_state: selectedpAddState,
            permanent_address_district: selectedpAddDistrict,
            permanent_address_area: selectPCity,
            permanent_address_street: pAddAreaName,
            permanent_address_house_no: pAddDoorNo,
            is_permanent_address_same_as_current: sameAddress,
        };
        let payload = {
            user_id: userId,
            payload1: FilterPayload1Items('addressAndHousingFilled', 'tab3'),
            payload2: FilterPayload2Items(input,  selectUserPayload2Data)
            // 'addressHousing', 'permanentAddress',
        };

        let response = await postPayload2Data(payload);
        if (response.status == 200) {
            dispatch(fetchUserRequest({ userId }));
            onNextTabHandler('houseDetails');
        }
        setUploading(false);
    };

    const handleKeyPress = (event) => {
        getPincodeData(event);
    }

    const clearDropdown = () => {
        setPAddState([]);
        setPAddDistrict([]);
        setPAddCity([]);
    }

    function getPincodeData(data) {
        if (data.length === 6) {
            setUploading(true);
            getPincodeVerificationData(data)
                .then(response => {
                    if (response.status === 200 && response.data[0].Status === "Success") {
                        let resData = response.data[0].PostOffice;
                        const uniqueStateName = resData.map(item => item.State)
                            .filter((value, index, self) => self.indexOf(value) === index);
                        const uniqueDistrictName = resData.map(item => item.District)
                            .filter((value, index, self) => self.indexOf(value) === index)
                        setPAddState(uniqueStateName);
                        setPAddDistrict(uniqueDistrictName);
                        setPAddCity(resData);
                    } else {
                        clearDropdown();
                    }
                    setUploading(false);
                })
                .catch(err => {
                    console.log('An error occurred:', err.res);
                    clearDropdown();
                    setUploading(false);
                });
        }
    }

    return (
        <>
            {selectDataUserReqStatus?.state === 'loading' ? <Spinner /> :
                <div className="container-fluid">
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-sm-3 ">
                                <h5 className="headingTitle">Permanent Address</h5>
                                <p className="contentdata">Please enter your permanent address details here. </p>
                            </div>
                            <div className="col-sm-6 offset-sm-1">
                                {
                                    validateFlag ? <Form.Group className="mb-3" style={{ textAlign: "left" }}>
                                        <Form.Check
                                            label="My permanent address is the same as my current address"
                                            value={validateFlag}
                                            checked={validateFlag}
                                            onClick={(e) => setValidateFlag(false)}
                                        />

                                    </Form.Group>
                                        : <Form.Group className="mb-3" style={{ textAlign: "left" }}>
                                            <Form.Check
                                                label="My permanent address is the same as my current address"
                                                value="false"
                                                onClick={(e) => validateSameAddress(e.target.value)}
                                            />

                                        </Form.Group>}
                                <div className="row" style={{ marginTop: "15px" }}>
                                    <div className="col">
                                        <Form.Group className="form-group" controlId="pAddPinCode" >
                                            <Form.Label>Pin Code</Form.Label>
                                            <Form.Control
                                                className="form-control"
                                                type="text"
                                                disabled={sameAddress || validateFlag}
                                                placeholder="6 digit Pin Code"
                                                value={pAddPinCode}
                                                maxLength={6}
                                                onChange={(e) => setPAddPinCode(e.target.value)}
                                                onKeyUp={(e) => handleKeyPress(e.target.value)}
                                                onMouseLeave={(e) => handleKeyPress(e.target.value)}
                                                required
                                            ></Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                Please provide 6 digit Pin Code.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                    <div className="col">
                                        {uploading ? null : <Form.Group className="form-group" controlId="selectedpAddState">
                                            <Form.Label>State</Form.Label>
                                            <Form.Control as='select' disabled={sameAddress || validateFlag} className="form-control" value={selectedpAddState}
                                                onChange={(e) => setSelectedPAddState(e.target.value)} required>
                                                <option value="">Select the State</option>
                                                {
                                                    pAddState && pAddState.map((data, key) => (
                                                        <option key={key} value={data}>{data}  </option>
                                                    ))}
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                Please Select State
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        }
                                    </div>
                                </div>
                                {uploading ? <div className="col text-center"><Spinner /></div> :
                                    <div className="row">
                                        <div className="col">
                                            <Form.Group className="form-group" controlId="selectedpAddDistrict">
                                                <Form.Label>District</Form.Label>
                                                <Form.Control as='select' disabled={sameAddress || validateFlag} className="form-control" value={selectedpAddDistrict}
                                                    onChange={(e) => setSelectedPAddDistrict(e.target.value)} required>
                                                    <option value="">Select the District</option>
                                                    {
                                                        pAddDistrict && pAddDistrict.map((data, key) => (
                                                            <option key={key} value={data}>
                                                                {data}
                                                            </option>
                                                        ))}
                                                </Form.Control>
                                                <Form.Control.Feedback type="invalid">
                                                    Please Select District
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </div>

                                        <div className="col">
                                            <Form.Group className="form-group" controlId="selectPCity">
                                                <Form.Label>Area</Form.Label>
                                                <Form.Control as='select' disabled={sameAddress || validateFlag} className="form-control" value={selectPCity}
                                                    onChange={(e) => setPCitySelected(e.target.value)} required>
                                                    <option value="">Select the Area</option>
                                                    {
                                                        pAddCity && pAddCity.map((data, key) => (
                                                            <option key={key} value={data.Name}>
                                                                {data.Name}
                                                            </option>
                                                        ))}
                                                </Form.Control>
                                                <Form.Control.Feedback type="invalid">
                                                    Please Select Area
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                    </div>}
                                <Form.Group className="form-group" controlId="pAddAreaName" >
                                    <Form.Label>Area Name / Street Name</Form.Label>
                                    <Form.Control
                                        className="form-control"
                                        type="text"
                                        disabled={sameAddress || validateFlag}
                                        placeholder="Area Name"
                                        value={pAddAreaName}
                                        maxLength={50}
                                        onChange={(e) => setPAddAreaName(e.target.value)}
                                        required
                                    ></Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        Please provide Area Name / Street Name.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="form-group" controlId="pAddDoorNo" >
                                    <Form.Label>House / Flat / Door No.</Form.Label>
                                    <Form.Control
                                        className="form-control"
                                        type="text"
                                        disabled={sameAddress || validateFlag}
                                        placeholder="House / Flat / Door No."
                                        value={pAddDoorNo}
                                        maxLength={50}
                                        onChange={(e) => setPAddDoorNo(e.target.value)}
                                        required
                                    ></Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        Please provide House / Flat / Door No.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <button className="btn btn-primary mt-5" type="submit" >Save & Proceed</button>
                            </div>
                        </div>
                    </Form>
                </div>
            }
        </>
    )
}

export default PermanentAddress