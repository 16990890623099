import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { updateRequestStatus, selectUpdateUserReqStatus, fetchUserRequest, selectUserRequestData } from "../../redux/slices/userRequestSlice";
import { FilterPayload1Items, FilterPayload2Items } from "../../services/PayloadAPIService.js";
import { selectUserPayload1, selectUserPayload2 } from "../../redux/slices/userRequestSlice";
import Spinner from "../Spinner";
import '../../common/FileUploadStyle.scss';
import '../../common/FileUploadStyle2.scss';
import '../../common/FileUploadStyle3.scss';
// import '../../common/FileUpload.js';
import { fileUpload } from '../../services/PayloadAPIService.js';
import { postPayload2Data } from '../../services/PayloadAPIService.js';
import SuccessToast from '../../common/SuccessToast';
import Modal from "react-bootstrap/Modal";
import { useHistory } from "react-router-dom";

const UploadDocuments = ({ userId, onNextTabHandler }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const selectUserPayload1Data = useSelector(selectUserPayload1);
  const selectUserPayload2Data = useSelector(selectUserPayload2);
  const [fileUpload1, setFileUpload1] = useState(null);
  const [fileUpload2, setFileUpload2] = useState(null);
  const [fileUpload3, setFileUpload3] = useState(null);
  const [fileSize1, setfileSize1] = useState(false);
  const [fileSize2, setfileSize2] = useState(false);
  const [fileSize3, setfileSize3] = useState(false);

  const [aadhaarFrontSideImage, setAadhaarFrontSideImage] = useState(false);
  const [aadhaarBackSideImage, setAadhaarBackSideImage] = useState(false);
  const [panCardImage, setPanCardImage] = useState(false);
  const [responseAlert, setResponseAlert] = useState(false);

  const [uploading, setUploading] = useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (selectUserPayload2Data) {
      setAadhaarFrontSideImage(selectUserPayload2Data?.applicant_aadhaar_front_file_name);
      setAadhaarBackSideImage(selectUserPayload2Data?.applicant_aadhaar_back_file_name)
      setPanCardImage(selectUserPayload2Data?.applicant_pan_file_name)
    }
  }, [selectUserPayload2Data]);


  const manageUserHandler = async (fileNames) => {

    let input = {
      aadhaarFrontSideImage: true,
      applicant_aadhaar_front_file_name: fileNames.fileName1,
      applicant_aadhaar_front_upload_timestamp: Date.now(),
      aadhaarBackSideImage: true,
      applicant_aadhaar_back_file_name: fileNames.fileName2,
      applicant_aadhaar_back_upload_timestamp: Date.now(),
      panCardImage: true,
      applicant_pan_file_name: fileNames.fileName3,
      applicant_pan_upload_timestamp: Date.now(),
    };
    let payload = {
      user_id: userId,
      payload1: FilterPayload1Items('identificationFilled', 'lastTab'),
      payload2: FilterPayload2Items(input, selectUserPayload2Data)
    };

    let response = await postPayload2Data(payload);
    if (response.status == 200) {
      dispatch(fetchUserRequest({ userId }));
      history.push(`/dashboard/addresshousing`);
    }
    setUploading(false);
  };

  const existingManageUserHandler = async (fileName, reupload) => {
    let input = {
      ...selectUserPayload2Data
    };
    if (reupload === 'reupload1') {
      input.aadhaarFrontSideImage = true;
      input.applicant_aadhaar_front_file_name = fileName;
    } else if (reupload === 'reupload2') {
      input.aadhaarBackSideImage = true;
      input.applicant_aadhaar_back_file_name = fileName;
    } else if (reupload === 'reupload3') {
      input.panCardImage = true;
      input.applicant_aadhaar_back_file_name = fileName;
    }
    let payload = {
      user_id: userId,
      payload1: FilterPayload1Items('identificationFilled', 'lastTab'),
      payload2: FilterPayload2Items(input, selectUserPayload2Data)
    };

    let response = await postPayload2Data(payload);
    if (response.status == 200) {
      dispatch(fetchUserRequest({ userId }));
      setResponseAlert(true);
      handleShow(true);
    }
    setUploading(false);
  };

  const uploadFileOnS3Handler = async () => {
    let fileNames = {
      fileName1: "",
      fileName2: "",
      fileName3: "",
    };
    if (fileUpload1) {
      setUploading(true)

      const formData = new FormData();

      formData.append("file", fileUpload1);

      const response = await fileUpload(formData);
      if (response.status === 200) {
        fileNames.fileName1 = response.data.filename;
        if (selectUserPayload2Data?.aadhaarFrontSideImage === true) {
          existingManageUserHandler(response.data.filename, 'reupload1')
        }
      }
    }
    if (fileUpload2) {
      setUploading(true)

      const formData = new FormData();
      formData.append("file", fileUpload2);

      const response = await fileUpload(formData);
      if (response.status === 200) {
        fileNames.fileName2 = response.data.filename;
        if (selectUserPayload2Data?.aadhaarFrontSideImage === true) {
          existingManageUserHandler(response.data.filename, 'reupload2')
        }
      }
    }
    if (fileUpload3) {
      setUploading(true)

      const formData = new FormData();
      formData.append("file", fileUpload3);

      const response = await fileUpload(formData);
      if (response.status === 200) {
        fileNames.fileName3 = response.data.filename;
        if (selectUserPayload2Data?.aadhaarFrontSideImage === true) {
          existingManageUserHandler(response.data.filename, 'reupload3')
        } else {
          manageUserHandler(fileNames)
        }
      }
    }

  }


  useEffect(() => {

    // starting ---------- 1st image upload code -------------------
    function Init() {
      var fileSelect = document.getElementById('file-upload'),
        fileDrag = document.getElementById('file-drag'),
        submitButton = document.getElementById('submit-button');
      fileSelect.addEventListener('change', fileSelectHandler, false);
      // Is XHR2 available?
      var xhr = new XMLHttpRequest();
      if (xhr.upload) {
        // File Drop
        fileDrag.addEventListener('dragover', fileDragHover, false);
        fileDrag.addEventListener('dragleave', fileDragHover, false);
        fileDrag.addEventListener('drop', fileSelectHandler, false);
      }
    }

    function fileDragHover(e) {
      var fileDrag = document.getElementById('file-drag');
      e.stopPropagation();
      e.preventDefault();
      fileDrag.className = (e.type === 'dragover' ? 'hover' : 'modal-body file-upload');
    }
    function fileSelectHandler(e) {
      // Fetch FileList object
      var files = e.target.files || e.dataTransfer.files;

      // Cancel event and hover styling
      fileDragHover(e);
      // Process all File objects
      for (var i = 0, f; f = files[i]; i++) {
        parseFile(f);
        //uploadFile(f);
      }
    }

    // Output
    function output(msg) {
      // Response
      var m = document.getElementById('messages');
      m.innerHTML = msg;
    }

    function parseFile(file) {
      setFileUpload1(file)
      output(
        '<strong>' + encodeURI(file.name) + '</strong>'
      );
      let fileSizeUpload = (file.size / (1024 * 1024)).toFixed(2);
      if (fileSizeUpload >= 2) {
        setfileSize1(true);
      } else {
        setfileSize1(false)
      }
      var imageName = file.name;
      var pdfFlag = (/\.(?=pdf)/gi).test(imageName);
      if (pdfFlag) {
        document.getElementById('start').classList.add("hidden");
        document.getElementById('response').classList.remove("hidden");
        document.getElementById('notimage').classList.add("hidden");
      } else {
        var isGood = (/\.(?=gif|jpg|png|jpeg)/gi).test(imageName);
        if (isGood) {
          document.getElementById('start').classList.add("hidden");
          document.getElementById('response').classList.remove("hidden");
          document.getElementById('notimage').classList.add("hidden");
          // Thumbnail Preview
          document.getElementById('file-image').classList.remove("hidden");
          document.getElementById('file-image').src = URL.createObjectURL(file);
        }
        else {
          document.getElementById('file-image').classList.add("hidden");
          document.getElementById('notimage').classList.remove("hidden");
          document.getElementById('start').classList.remove("hidden");
          document.getElementById('response').classList.add("hidden");
          document.getElementById("file-upload-form").reset();
        }
      }
    }

    // Check for the various File API support.
    if (window.File && window.FileList && window.FileReader) {
      Init();
    } else {
      document.getElementById('file-drag').style.display = 'none';
    }

    // starting ---------- 2nd image upload code -------------------
    function Init2() {

      var fileSelect = document.getElementById('file-upload2'),
        fileDrag = document.getElementById('file-drag2'),
        submitButton = document.getElementById('submit-button2');

      fileSelect.addEventListener('change', fileSelectHandler2, false);
      // Is XHR2 available?
      var xhr = new XMLHttpRequest();
      if (xhr.upload) {
        // File Drop
        fileDrag.addEventListener('dragover', fileDragHover2, false);
        fileDrag.addEventListener('dragleave', fileDragHover2, false);
        fileDrag.addEventListener('drop', fileSelectHandler2, false);
      }
    }

    function fileDragHover2(e) {
      var fileDrag = document.getElementById('file-drag2');
      e.stopPropagation();
      e.preventDefault();
      fileDrag.className = (e.type === 'dragover' ? 'hover' : 'modal-body file-upload2');
    }

    function fileSelectHandler2(e) {
      // Fetch FileList object
      var files = e.target.files || e.dataTransfer.files;
      // Cancel event and hover styling
      fileDragHover2(e);
      // Process all File objects
      for (var i = 0, f; f = files[i]; i++) {
        parseFile2(f);
      }
    }

    // Output
    function output2(msg) {
      var m = document.getElementById('messages2');
      m.innerHTML = msg;
    }

    function parseFile2(file) {
      setFileUpload2(file)
      output2(
        '<strong>' + encodeURI(file.name) + '</strong>'
      );
      let fileSizeUpload = (file.size / (1024 * 1024)).toFixed(2);
      if (fileSizeUpload >= 2) {
        setfileSize2(true);
      } else {
        setfileSize2(false)
      }
      var imageName = file.name;
      var pdfFlag = (/\.(?=pdf)/gi).test(imageName);
      if (pdfFlag) {
        document.getElementById('start2').classList.add("hidden");
        document.getElementById('response2').classList.remove("hidden");
        document.getElementById('notimage2').classList.add("hidden");
      } else {
        var isGood = (/\.(?=gif|jpg|png|jpeg)/gi).test(imageName);
        if (isGood) {
          document.getElementById('start2').classList.add("hidden");
          document.getElementById('response2').classList.remove("hidden");
          document.getElementById('notimage2').classList.add("hidden");
          // Thumbnail Preview
          document.getElementById('file-image2').classList.remove("hidden");
          document.getElementById('file-image2').src = URL.createObjectURL(file);
        }
        else {
          document.getElementById('file-image2').classList.add("hidden");
          document.getElementById('notimage2').classList.remove("hidden");
          document.getElementById('start2').classList.remove("hidden");
          document.getElementById('response2').classList.add("hidden");
          document.getElementById("file-upload-form2").reset();
        }
      }

    }

    // Check for the various File API support.
    if (window.File && window.FileList && window.FileReader) {
      Init2();
    } else {
      document.getElementById('file-drag2').style.display = 'none';
    }

    // starting ---------- 3rd image upload code -------------------
    function Init3() {

      var fileSelect = document.getElementById('file-upload3'),
        fileDrag = document.getElementById('file-drag3'),
        submitButton = document.getElementById('submit-button3');

      fileSelect.addEventListener('change', fileSelectHandler3, false);
      // Is XHR2 available?
      var xhr = new XMLHttpRequest();
      if (xhr.upload) {
        // File Drop
        fileDrag.addEventListener('dragover', fileDragHover3, false);
        fileDrag.addEventListener('dragleave', fileDragHover3, false);
        fileDrag.addEventListener('drop', fileSelectHandler3, false);
      }
    }

    function fileDragHover3(e) {
      var fileDrag = document.getElementById('file-drag3');
      e.stopPropagation();
      e.preventDefault();
      fileDrag.className = (e.type === 'dragover' ? 'hover' : 'modal-body file-upload3');
    }

    function fileSelectHandler3(e) {
      // Fetch FileList object
      var files = e.target.files || e.dataTransfer.files;

      // Cancel event and hover styling
      fileDragHover3(e);
      // Process all File objects
      for (var i = 0, f; f = files[i]; i++) {
        parseFile3(f);
      }
    }

    // Output
    function output3(msg) {
      var m = document.getElementById('messages3');
      m.innerHTML = msg;
    }

    function parseFile3(file) {
      setFileUpload3(file)
      output3(
        '<strong>' + encodeURI(file.name) + '</strong>'
      );
      let fileSizeUpload = (file.size / (1024 * 1024)).toFixed(2);
      if (fileSizeUpload >= 2) {
        setfileSize3(true);
      } else {
        setfileSize3(false)
      }
      var imageName = file.name;
      var pdfFlag = (/\.(?=pdf)/gi).test(imageName);
      if (pdfFlag) {
        document.getElementById('start3').classList.add("hidden");
        document.getElementById('response3').classList.remove("hidden");
        document.getElementById('notimage3').classList.add("hidden");
      } else {
        var isGood = (/\.(?=gif|jpg|png|jpeg)/gi).test(imageName);
        if (isGood) {
          document.getElementById('start3').classList.add("hidden");
          document.getElementById('response3').classList.remove("hidden");
          document.getElementById('notimage3').classList.add("hidden");
          // Thumbnail Preview
          document.getElementById('file-image3').classList.remove("hidden");
          document.getElementById('file-image3').src = URL.createObjectURL(file);
        }
        else {
          document.getElementById('file-image3').classList.add("hidden");
          document.getElementById('notimage3').classList.remove("hidden");
          document.getElementById('start3').classList.remove("hidden");
          document.getElementById('response3').classList.add("hidden");
          document.getElementById("file-upload-form3").reset();
        }
      }
    }

    // Check for the various File API support.
    if (window.File && window.FileList && window.FileReader) {
      Init3();
    } else {
      document.getElementById('file-drag3').style.display = 'none';
    }
  });

  const removeImage=()=>{
    setAadhaarFrontSideImage(false);
    document.getElementById('file-image').classList.add("hidden");
    document.getElementById('notimage').classList.remove("hidden");
    document.getElementById('start').classList.remove("hidden");
    document.getElementById('response').classList.add("hidden");
    document.getElementById("file-upload-form").reset();
    setFileUpload1(null);
    setfileSize1(false);
  }
  const removeImage2=()=>{
     setAadhaarBackSideImage(false);
    // setPanCardImage(false);
    document.getElementById('file-image2').classList.add("hidden");
    document.getElementById('notimage2').classList.remove("hidden");
    document.getElementById('start2').classList.remove("hidden");
    document.getElementById('response2').classList.add("hidden");
    document.getElementById("file-upload-form2").reset();
    setFileUpload2(null);
    setfileSize2(false);
  }

  const removeImage3=()=>{
  setPanCardImage(false);
   document.getElementById('file-image3').classList.add("hidden");
   document.getElementById('notimage3').classList.remove("hidden");
   document.getElementById('start3').classList.remove("hidden");
   document.getElementById('response3').classList.add("hidden");
   document.getElementById("file-upload-form3").reset();
   setFileUpload3(null);
   setfileSize3(false);
 }
  return (
    <div className="container-fluid">
      {responseAlert ?
        <Modal  show={show} size="md" onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title >
              Alert
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            File uploaded successfully.
          </Modal.Body>
        </Modal>
        :
        null
      }
      {/* // <SuccessToast showDialogue={true} type={'success'} message={'File uploaded successfully.'} /> : null} */}
      <div className="row">
        <div className="col-sm-3 ">
          <h5 className="headingTitle">Aadhaar Card</h5>
          <p className="contentdata">Upload the Aadhaar of the head of family - both Front and Back images.
          </p>
          {fileSize1 || fileSize2 ? <p className="imgwarning">size of upload document should not be more than 2 MB</p> : ""}
        </div>
        <div className="col">
          {/* <!-- Upload section 1 --> */}
          {aadhaarFrontSideImage ? <div style={{padding: 10}}>
            Front image of aadhaar has been <span style={{color:'#56cdad'}}>successfully uploaded</span>. If you wish to reupload, <a onClick={() => setAadhaarFrontSideImage(false)} style={{cursor:"pointer", textDecoration:"underline"}}>click here</a>
          </div> : null}
          
          <form id="file-upload-form" style={{ display: aadhaarFrontSideImage ? 'none' : 'block' }} className="fileuploader">
            <input id="file-upload" type="file" name="fileUpload" accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps" />
            <label for="file-upload" id="file-drag" className="fileUploadLabel">
              <div className="file-pv-container">
              <img id="file-image" src="#" alt="Preview" className="hidden" />
              {fileUpload1 ? <button type="button" class="btn btn-outline-primary btn-sm" onClick={removeImage}>X</button> : null}
              </div>
              
              <div id="start">
                <i className="fa fa-file-image-o" aria-hidden="true"></i>
                <div className="imagelabel">Click to add or drag and drop aadhaar front side image</div>
                <p className="contentdata ">PNG,JPG or PDF file</p>
                <div id="notimage" className="hidden">Please select an image or PDF</div>
              </div>
              <div id="response" className="hidden">
                <div id="messages"></div>
              </div>
            </label>
          </form>
        </div>
        <div className="col">
          {/* <!-- Upload sectio 2 --> */}
          {aadhaarBackSideImage ? <div style={{padding: 10}}>
            Back image of aadhaar has been <span style={{color:'#56cdad'}}>successfully uploaded</span>. If you wish to reupload, <a onClick={() => setAadhaarBackSideImage(false)} style={{cursor:"pointer", textDecoration:"underline"}}>click here</a>
          </div> : null}
          <form id="file-upload-form2" style={{ display: aadhaarBackSideImage ? 'none' : 'block' }} className="fileuploader2">
            <input id="file-upload2" type="file" name="fileUpload" accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps" />
            <label for="file-upload2" id="file-drag2" className="fileUploadLabel2">
              
              <div className="file-pv-container">
              <img id="file-image2" src="#" alt="Preview" className="hidden" />
              {fileUpload2 ? <button type="button" class="btn btn-outline-primary btn-sm" onClick={removeImage2}>X</button> : null}
              </div>
              <div id="start2">
                <i className="fa fa-file-image-o" aria-hidden="true"></i>
                <div className="imagelabel2">Click to add or drag and drop aadhaar back side image</div>
                <p className="contentdata ">PNG,JPG or PDF file</p>
                <div id="notimage2" className="hidden">Please select an image or PDF</div>
              </div>
              <div id="response2" className="hidden">
                <div id="messages2"></div>
              </div>
            </label>
          </form>
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-sm-3 ">
          <h5 className="headingTitle">PAN Card</h5>
          <p className="contentdata">Upload the PAN Card for the head of family, with the PAN number clearly visible.
          </p>
          {fileSize3 ? <p className="imgwarning">size of upload document should not be more than 2 MB</p> : ""}
        </div>
        <div className="col-md-3 mx-auto">
          {/* <!-- Upload sectio 3 --> */}
          {panCardImage ? <div style={{padding: 10}}>
            PAN has been <span style={{color:'#56cdad'}}>successfully uploaded</span>. If you wish to reupload, <a onClick={() => setPanCardImage(false)} style={{cursor:"pointer", textDecoration:"underline"}}>click here</a>
          </div> : null}
          <form id="file-upload-form3" style={{ display: panCardImage ? 'none' : 'block' }} className="fileuploader3">
            <input id="file-upload3" type="file" name="fileUpload" accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps" />
            <label for="file-upload3" id="file-drag3" className="fileUploadLabel3">
             
              <div className="file-pv-container">
              <img id="file-image3" src="#" alt="Preview" className="hidden" />
              {fileUpload3 ? <button type="button" class="btn btn-outline-primary btn-sm" onClick={removeImage3}>X</button> : null}
              </div>
              <div id="start3">
                <i className="fa fa-file-image-o" aria-hidden="true"></i>
                <div className="imagelabel3">Click to add or drag and drop Pan Card image</div>
                <p className="contentdata ">PNG,JPG or PDF file</p>
                <div id="notimage3" className="hidden">Please select an image or PDF</div>
              </div>
              <div id="response3" className="hidden">
                <div id="messages3"></div>
              </div>
            </label>
          </form>

        </div>
      </div>
      <hr />

      <div className="row">
        <div className="col">
          {selectUpdateUserReqStatus?.state === 'loading' ? <Spinner /> : (aadhaarFrontSideImage && aadhaarBackSideImage && panCardImage) ? <button className="btn btn-primary mt-5" type="button" onClick={() => history.push(`/dashboard/addresshousing`)}>
            Save & Proceed
          </button> :
            <button className="btn btn-primary mt-5" type="button" disabled={!(fileUpload1 && fileUpload2 && fileUpload3 || aadhaarFrontSideImage || aadhaarBackSideImage || panCardImage) || (fileSize1 || fileSize2 || fileSize3)} onClick={() => uploadFileOnS3Handler()}>
              Save & Proceed
            </button>}
        </div>
      </div>

    </div>
  )
}

export default UploadDocuments