import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
// import { fetchUserRequest, selectUserRequestData } from "../../redux/slices/userRequestSlice";
import Spinner from "../Spinner";
import {
  fetchUserRequest,
  selectUserRequestData,
  selectUpdateUserReqStatus,
  updateRequestStatus,
  updateStatusRequest,
} from "../../redux/slices/userRequestSlice";
// import Spinner from "../Spinner";
// import { updateRequestStatus, selectUpdateUserReqStatus, fetchUserRequest, selectUserRequestData } from "../../redux/slices/userRequestSlice";
import { onlinePayment } from "../../services/PayloadAPIService";
import { selectUserPayload2 } from "../../redux/slices/userRequestSlice";
import Badge from "react-bootstrap/Badge";
import Stack from "react-bootstrap/Stack";
import moment from "moment";
import Modal from "react-bootstrap/Modal";

const OnlinePayment = ({
  userId,
  onNextTabHandler,
  selectUserPaymentDetails,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const selectUserRequest = useSelector(selectUserRequestData);
  // const [uploading, setUploading] = useState(false);
  const selectUserPayload2Data = useSelector(selectUserPayload2);
  const selectUpdateUserReqStatus = useSelector(
    (state) => state.userRequest?.updateStatusRequest
  );
  const selectDataUserReqStatus = useSelector(
    (state) => state.userRequest?.userRequestListState
  );
  const [uploading, setUploading] = useState(false);
  const [applicationId, setApplicationId] = useState("");
  const [panNoVerification, setPanNoVerification] = useState(false);
  const [aadhaarVerification, setAadhaarVerification] = useState(false);

  const [akurdiProj, setAkurdiProj] = useState([]);
  const [pimpriProj, setPimpriProj] = useState([]);
  const [dudalgaonProj, setDudalgaonProj] = useState([]);
  const [DudleProj, setDudleProj] = useState([]);
  const [userMobile, setUserMobile] = useState("");
  const [showBilldeskCharges, setShowBilldeskCharges] = useState(false);

  const [show, setShow] = useState(false);
  const handleClose = () => setShowBilldeskCharges(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (selectUserPaymentDetails) {
      setDudalgaonProj(selectUserPaymentDetails.DUDULGAON);
      // setPimpriProj(selectUserPaymentDetails.DUDULGAON);
    }
  }, [selectUserPaymentDetails]);

  useEffect(() => {
    if (selectUserPayload2Data?.identification?.information) {
      setPanNoVerification(
        selectUserPayload2Data?.identification?.information?.pancardVerified
      );
      setAadhaarVerification(
        selectUserPayload2Data?.identification?.verification?.aadhaarVerified
      );
    }
  }, [selectUserPayload2Data?.identification?.information]);

  useEffect(() => {
    if (localStorage.getItem("mobileNo")) {
      setUserMobile(localStorage.getItem("mobileNo"));
    }
  }, []);

  useEffect(() => {
    if (selectUserRequest?.data?.application_id) {
      setApplicationId(selectUserRequest?.data?.application_id);
    }
  }, [selectUserRequest?.data?.application_id]);

  useEffect(() => {
    if (userId) dispatch(fetchUserRequest({ userId }));
  }, [userId]);

  const managePaymentHandler = async (projName) => {
    // if (panNoVerification && aadhaarVerification) {
    handleShow(false);
    onlinePayment(projName, userId, userMobile, applicationId)
      .then((response) => {
        if (response.status === 200) {
          let resData = response.data;
          postToUrl(resData.billdesk_url, { msg: resData.msg });
        }
      })
      .catch((err) => {
        console.log("An error occurred:", err.res);
      });
    // } else {
    //     handleShow(true);
    // }
  };

  function postToUrl(path, params, method = "post") {
    const form = document.createElement("form");
    form.method = method;
    form.action = path;
    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        const hiddenField = document.createElement("input");
        hiddenField.type = "hidden";
        hiddenField.name = key;
        hiddenField.value = params[key];
        form.appendChild(hiddenField);
      }
    }
    document.body.appendChild(form);
    form.submit();
  }

  const clickToPaymentStausPage = (area) => {
    onNextTabHandler("paymentStatus");
    history.push(`/dashboard/online-payment?tab=3&area=${area}`);
  };

  return (
    <>
      {/* {!panNoVerification && !aadhaarVerification ?
                <Modal show={show} size="md" onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title >
                            Alert
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Please enter and validate your PAN and Aadhaar No to proceed with payment options.
                    </Modal.Body>
                </Modal>
                : null
            } */}
      {selectUpdateUserReqStatus?.state === "loading" ||
      selectDataUserReqStatus?.state === "loading" ? (
        <Spinner />
      ) : (
        <div className="container-fluid">
          <div className="row mt-5">
            <div className="col-sm-4">
              <h5 className="headingTitle">Payment </h5>
              <p className="contentdata">
                Please select the project under which you are applying to the
                Pradhan Mantri Awas Yojana, and make the payment accordingly to
                be eligible.
              </p>
              {/* <p className="contentdata">You can pay and apply for both projects under the same login, one at a time.</p> */}
              {/* <p className="contentdata">Fees will be charged separately for each project you apply for.</p> */}
            </div>

            <div className="col-sm-8 ">
              <div className="row">
                <div className="col-sm-12">
                  <h5 className="headingTitle">Project DUDULGAON </h5>
                </div>
                {/* <div className="col-sm-12">
                                    <p className="contentdata">DUDULGAON (568 flats) - Beneficiary share per flat Rs. 7,35,255</p>
                                </div> */}
              </div>
              <div className="row">
                <div className="col-sm-7">
                  {dudalgaonProj && dudalgaonProj?.txn_status == "FAILURE" ? (
                    <p>
                      You tried to make a payment of Rs. 10,500 for this project
                      on{" "}
                      {moment(dudalgaonProj.request_time).format("DD MMM YYYY")}
                      , however you payment attempt failed from your bank.
                      Please try payment again. You can see the payment details
                      by clicking{" "}
                      <a
                        className="atag"
                        onClick={(e) => clickToPaymentStausPage("DUDULGAON")}
                      >
                        here.
                      </a>
                    </p>
                  ) : null}
                  {(dudalgaonProj && dudalgaonProj?.txn_status == "SUCCESS") ||
                  Object.keys(selectUserPaymentDetails).length == 0 ? (
                    <p>
                      You have made the payment of Rs. 10,500 for this project.
                      Your payment has been successfully received. You can see
                      the payment details by clicking{" "}
                      <a
                        className="atag"
                        onClick={(e) => clickToPaymentStausPage("TEST")}
                      >
                        here.
                      </a>
                    </p>
                  ) : null}
                </div>

                <div className="col-sm-5">
                  <Stack direction="horizontal">
                    <Badge
                      className="alignbadge"
                      pill
                      bg={`${
                        dudalgaonProj?.txn_status === "SUCCESS"
                          ? "success"
                          : dudalgaonProj?.txn_status === "FAILURE"
                          ? "danger"
                          : null
                      }`}
                    >
                      {dudalgaonProj?.txn_status === "SUCCESS"
                        ? "Paid"
                        : dudalgaonProj?.txn_status === "FAILURE"
                        ? "Failed"
                        : null}
                    </Badge>
                  </Stack>
                </div>
              </div>
              {(dudalgaonProj && dudalgaonProj?.txn_status === "FAILURE") ||
              (dudalgaonProj && dudalgaonProj?.txn_status === "PENDING") ||
              dudalgaonProj === null ? (
                <div className="row">
                  <div className="col-sm-7">
                    {(dudalgaonProj &&
                      dudalgaonProj?.txn_status == "PENDING") ||
                    dudalgaonProj?.txn_status == "FAILURE" ||
                    dudalgaonProj == null ? (
                      <p className="contentdata">Total Amount payable</p>
                    ) : null}
                    {/* {!identification_is_pan_verified && !identification_is_aadhaar_verified ?
                                                <p className="contentdata">Please enter and validate your PAN and Aadhaar No to proceed with payment options.</p>
                                                : null
                                            } */}
                  </div>
                  <div className="col-sm-4 ">
                    <h5>₹ 10,500.00</h5>
                  </div>

                  <div className="col-sm-3 offset-sm-7 mt15">
                    {dudalgaonProj &&
                    dudalgaonProj?.txn_status === "PENDING" ? (
                      <button className="btn btn-warning" type="button">
                        Pending
                      </button>
                    ) : (dudalgaonProj &&
                        dudalgaonProj?.txn_status === "FAILURE") ||
                      dudalgaonProj === null ? (
                      selectUpdateUserReqStatus?.state === "loading" ||
                      selectDataUserReqStatus?.state === "loading" ? (
                        <Spinner />
                      ) : (
                        <button
                          className="btn btn-primary"
                          type="button"
                          onClick={() => setShowBilldeskCharges(true)}
                        >
                          {dudalgaonProj &&
                          dudalgaonProj?.txn_status === "FAILURE"
                            ? "Pay Again"
                            : "Pay Now"}
                        </button>
                      )
                    ) : null}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          <Modal show={showBilldeskCharges} size="lg">
            <Modal.Header>
              <Modal.Title>Payment Charges</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <table border="1" cellpadding="10" cellspacing="0">
                <thead>
                  <tr>
                    <th>Transaction Type</th>
                    <th>Charges</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>For Debit Card Upto Rs 2000/- (Master/Visa)</td>
                    <td>0.40 % + GST</td>
                  </tr>
                  <tr>
                    <td>For Debit Card Above Rs 2000/- (Master/Visa)</td>
                    <td>0.90 % + GST</td>
                  </tr>
                  <tr>
                    <td>Rupay Debit Card</td>
                    <td>NIL</td>
                  </tr>
                  <tr>
                    <td>For Credit Card Consumer</td>
                    <td>2.00 % + GST</td>
                  </tr>
                  <tr>
                    <td>For Credit Card Commercial</td>
                    <td>2.00 % + GST</td>
                  </tr>
                  <tr>
                    <td>
                      For UPI Transactions - (UPI Dynamic QR Code/ UPI VPA)
                    </td>
                    <td>NIL</td>
                  </tr>
                  <tr>
                    <td>BOB NetBanking</td>
                    <td>Rs 22/- + GST</td>
                  </tr>
                  <tr>
                    <td>All Other Banks NetBanking</td>
                    <td>Rs 22/- + GST</td>
                  </tr>
                  <tr>
                    <td>Wallet</td>
                    <td>1.80 % + GST</td>
                  </tr>
                </tbody>
              </table>
            </Modal.Body>
            <Modal.Footer>
                <button onClick={()=> managePaymentHandler("DUDULGAON")} className="btn btn-primary"
                          type="button">
                    I Accept Terms and conditions
                </button>
            </Modal.Footer>
          </Modal>
          {/* <hr className="mt-5" />
                    <div className="mt-5">
                        <div className="row">
                            <div className="col-sm-4">
                                <p className="contentdata">
                                    जर अर्जदारास आकुर्डी व पिंपरी या दोन्ही प्रकल्पांसाठी अर्ज सादर करावयाचा असेल, तर त्यांनी एका वेळी एक प्रकल्प निवडून स्वतंत्ररित्या अनामत रक्कम भरावयाची आहे
                                </p>
                            </div>
                            <div className="col-sm-8">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <h5 className="headingTitle">Project TEST</h5>
                                    </div>
                                    <div className="col-sm-12">
                                        <p className="contentdata">TEST (370 flats) - Beneficiary share per flat Rs. 7,92,699</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-7">
                                        {
                                            pimpriProj && pimpriProj?.txn_status == "FAILURE" ?
                                                <p>You tried to make a payment of Rs. 10,500 for this project on {moment(pimpriProj.request_time).format("DD MMM YYYY")}, however you payment attempt failed from your bank. Please try payment again. You can see the payment details by clicking <a className="atag" onClick={(e) => clickToPaymentStausPage('TEST')}>here.</a></p>
                                                : null
                                        }
                                        {
                                            pimpriProj && pimpriProj?.txn_status == "SUCCESS" ?
                                                <p>You have made the payment of Rs. 10,500 for this project. Your payment has been successfully received. You can see the payment details by clicking <a className="atag" onClick={(e) => clickToPaymentStausPage('TEST')}>here.</a></p>
                                                : null
                                        }
                                    </div>
                                    <div className="col-sm-5">
                                        <Stack direction="horizontal">
                                            <Badge className="alignbadge" pill
                                                bg={`${pimpriProj?.txn_status === "SUCCESS" ?
                                                    "success" : pimpriProj?.txn_status === "FAILURE" ? "danger" : null}`
                                                }
                                            >
                                                {pimpriProj?.txn_status === "SUCCESS" ?
                                                    "Paid" : pimpriProj?.txn_status === "FAILURE" ? "Failed" : null
                                                }
                                            </Badge>
                                        </Stack>
                                    </div>
                                </div>
                                {
                                    pimpriProj && pimpriProj?.txn_status === "FAILURE" || pimpriProj && pimpriProj?.txn_status === "PENDING" || pimpriProj === null ?
                                        <div className="row ">
                                            <div className="col-sm-7">
                                                {
                                                    pimpriProj && pimpriProj?.txn_status == "PENDING" || dudalgaonProj?.txn_status == "FAILURE" || pimpriProj == null ?
                                                        <p className="contentdata">Total Amount payable</p>
                                                        : null
                                                }
                                                {!panNoVerification && !aadhaarVerification ?
                                                    <p className="contentdata">Please enter and validate your PAN and Aadhaar No to proceed with payment options.</p>
                                                    : null
                                                }
                                            </div>
                                            <div className="col-sm-4 ">
                                                <h5>₹ 10,500.00</h5>
                                            </div>

                                            <div className="col-sm-3 offset-sm-7 mt15">
                                                {pimpriProj && pimpriProj?.txn_status === "PENDING" ?
                                                    <button className="btn btn-warning" type="button">
                                                        Pending
                                                    </button>
                                                    : pimpriProj && pimpriProj?.txn_status === "FAILURE" || pimpriProj == null ?
                                                        selectUpdateUserReqStatus?.state === 'loading' || selectDataUserReqStatus?.state === 'loading' ? <Spinner /> :
                                                            <button className="btn btn-primary" type="button" onClick={() => managePaymentHandler("TEST")}>
                                                                {
                                                                    pimpriProj && pimpriProj?.txn_status === "FAILURE" ?
                                                                        "Pay Again" : "Pay Now"
                                                                }
                                                            </button>
                                                        :
                                                        null
                                                }

                                            </div>
                                        </div>
                                        : null
                                }
                            </div>
                        </div>
                    </div> */}
        </div>
      )}
    </>
  );
};
export default OnlinePayment;
