import React, { useState, useEffect } from "react";
import { Form, InputGroup, Button } from "react-bootstrap";
import {
  updateRequestStatus,
  selectUpdateUserReqStatus,
  fetchUserRequest,
  selectUserRequestData,
} from "../../redux/slices/userRequestSlice";
import { useDispatch, useSelector } from "react-redux";
import { UserModalData } from "../../services/PayloadModalData";
import {
  FilterPayload1Items,
  FilterPayload2Items,
} from "../../services/PayloadAPIService.js";
import { selectUserProfile } from "../../redux/slices/userProfileSlice";
import {
  selectUserPayload1,
  selectUserPayload2,
} from "../../redux/slices/userRequestSlice";
import { Utility } from "../../utils/utility";
import Spinner from "../Spinner";
import { postPayload2Data } from "../../services/PayloadAPIService.js";
import { useHistory } from "react-router-dom";

const Information = ({ userId, onNextTabHandler }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const selectUpdateUserReqStatus = useSelector(
    (state) => state.userRequest?.updateStatusRequest
  );
  const selectDataUserReqStatus = useSelector(
    (state) => state.userRequest?.userRequestListState
  );
  const selectUserPayload2Data = useSelector(selectUserPayload2);
  const selectUserPayload1Data = useSelector(selectUserPayload1);

  const [nameOfHead, setNameOfHead] = useState("");
  const [emailId, setEmailId] = useState("");
  const [altPhoneNo, setAltPhoneNo] = useState("");
  const [panNumber, setPanNumber] = useState("");
  const [aadhaarNumber, setAadhaarNumber] = useState("");
  const [aadhaarVerified, setAadhaarVerified] = useState(false);
  const [pancardVerified, setPancardVerified] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");

  const [validated, setValidated] = useState(false);
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    if (selectUserPayload2Data) {
      setNameOfHead(selectUserPayload2Data?.identification_name_as_per_aadhaar);
      setEmailId(selectUserPayload2Data?.identification_primary_email_id);
      setAltPhoneNo(selectUserPayload2Data?.identification_alternate_mobile_no);
      setPanNumber(selectUserPayload2Data?.identification_pan_no);
      setAadhaarNumber(selectUserPayload2Data?.identification_aadhaar_no);
      setAadhaarVerified(
        selectUserPayload2Data?.identification_is_aadhaar_verified
      );
      setPancardVerified(
        selectUserPayload2Data?.identification_is_pan_verified
      );
      setMobileNumber(localStorage.getItem("mobileNo"));
    }
  }, [selectUserPayload2Data]);

  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    } else {
      manageUserHandler();
      event.preventDefault();
      event.stopPropagation();
    }
  };

  const manageUserHandler = async () => {
    setUploading(true);

    let input = {
      identification_name_as_per_aadhaar: nameOfHead,
      identification_primary_email_id: emailId,
      //identification_primary_mobile_no: altPhoneNo,
      identification_alternate_mobile_no: altPhoneNo,
      identification_pan_no: panNumber,
      identification_aadhaar_no: aadhaarNumber,
      identification_is_aadhaar_verified: aadhaarVerified,
      identification_is_pan_verified: pancardVerified,
    };
    let payload = {
      user_id: userId,
      payload1: FilterPayload1Items("identificationFilled", "tab2"),
      payload2: FilterPayload2Items(input, selectUserPayload2Data),
    };

    let response = await postPayload2Data(payload);
    if (response.status == 200) {
      dispatch(fetchUserRequest({ userId }));
      onNextTabHandler("verification");
      history.push(`/dashboard/online-payment`);
    }

    setUploading(false);
  };

  return (
    <div className="container-fluid">
      {selectUpdateUserReqStatus?.state === "loading" ||
      selectDataUserReqStatus?.state === "loading" ? (
        <Spinner />
      ) : (
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-sm-3 ">
              <h5 className="headingTitle">Basic Information</h5>
              <p className="contentdata">
                Please add the Identification information for the applicant.
                Please ensure everything matches the identification proofs -
                Aadhar and PAN card.
              </p>
              <p className="contentdata">
                You will be asked to verify and upload them in the next steps.
              </p>
            </div>
            <div className="col-sm-6 offset-sm-1">
              <Form.Group className="form-group" controlId="nameOfHead">
                <Form.Label>Name of Applicant as per Aadhaar</Form.Label>
                <Form.Control
                  className="form-control"
                  type="text"
                  placeholder="Name of Applicant "
                  value={nameOfHead}
                  onChange={(e) => setNameOfHead(e.target.value)}
                  required
                ></Form.Control>
                <Form.Control.Feedback type="invalid">
                  Please provide Name of Applicant as per Aadhaar.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="form-group" controlId="emailId">
                <Form.Label>Email Id</Form.Label>
                <Form.Control
                  className="form-control"
                  type="text"
                  placeholder="Enter your email address"
                  value={emailId}
                  maxLength={50}
                  isInvalid={emailId && !Utility.validateEmail(emailId)}
                  onChange={(e) => setEmailId(e.target.value)}
                  required
                ></Form.Control>
                <Form.Control.Feedback type="invalid">
                  Please provide valid Email Id.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="form-group" controlId="altPhoneNo">
                <Form.Label>Alternate Mobile Number</Form.Label>
                <Form.Control
                  className="form-control"
                  type="text"
                  placeholder="Enter an alternate phone number (not the one you used to login)"
                  value={altPhoneNo}
                  maxLength={"10"}
                  isInvalid={
                    (altPhoneNo && !Utility.validateMobileNumber(altPhoneNo)) ||
                    altPhoneNo === mobileNumber
                  }
                  onChange={(e) => setAltPhoneNo(e.target.value)}
                  required
                ></Form.Control>
                <Form.Control.Feedback type="invalid">
                  {altPhoneNo === mobileNumber
                    ? "Alternate phone number cannot be same as primary phone number."
                    : "Please provide a valid Alternate Mobile Number."}
                </Form.Control.Feedback>
              </Form.Group>
              <button className="btn btn-primary mt-5" type="submit">
                Save & Proceed
              </button>
            </div>
          </div>
        </Form>
      )}
    </div>
  );
};

export default Information;
