import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { fetchUserRequest, selectUserRequestData } from "../../redux/slices/userRequestSlice";
import { useDispatch, useSelector } from "react-redux";
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import { getApplicationReview, submitApplicationAfterEdit } from "../../services/PayloadAPIService";
import SubmitIssuesModal from "../../common/SubmitIssuesModal";
function Home({ onNextTabHandler }) {
  const [languageChnage, setLanguageChnage] = useState(false);
  const selectUserRequest = useSelector(selectUserRequestData);

  const [userId, setUserId] = useState("");
  const [issueFound, setIssueFound] = useState("");
  const [issueFoundflag, setIssueFoundflag] = useState(false);
  const [resubmitMsg, setResubmitMsg] = useState('');
  const history = useHistory();
  const dispatch = useDispatch();

  const clickHandle = (evt) => {
    history.push(`/dashboard/identification`);
  }

  useEffect(() => {
    if (localStorage.getItem("userId")) {
      setUserId(localStorage.getItem("userId"));
    }
  }, []);

  useEffect(() => {
    if (userId)
      dispatch(fetchUserRequest({ userId }));
  }, [userId]);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (selectUserRequest?.data?.application_id) {
      getApplicationReveiwData(selectUserRequest?.data?.application_id);
    }
  }, [selectUserRequest?.data?.application_id]);

  function getApplicationReveiwData(application_id) {
    getApplicationReview(application_id)  //"PMAYPCMC202363922842"
      .then(response => {
        if (response?.status === 200 && response?.data?.status === "success" && response?.data?.issues_data?.length > 0) {
          setIssueFound(response?.data?.issues_data);
          setIssueFoundflag(true);
        } else {
          setIssueFoundflag(false);
        }
      })
      .catch(err => {
        console.log('An error occurred:', err.res);
      });
  }

  const reSubmitApplicationAfterEdits = () => {
    submitApplicationAfterEdit(selectUserRequest?.data?.application_id)
    .then(response => {
      if (response?.status === 200 && response?.data?.status === "success") {
        setResubmitMsg(response?.data?.message);
      } else {
        setResubmitMsg('Something went wrong!');
      }
    })
    .catch(err => {
      console.log('An error occurred:', err.res);
    });
  }

  return (
    <div className="container-fluid">
      <div className="row mt-5">
        <div className="col-sm-12">
          <div style={{ marginBottom: 50 }}>
            <h3 className="text-left heading-font" >Welcome to the PMAY-U application Portal.</h3>
            {/* <p className="contentdata text-left">Currently Open Projects: Projects Akurdi (568 flats) and Pimpri (370 flats)</p> */}
          </div>

        </div>
        {
          issueFoundflag && issueFoundflag ?
            <div className="col-sm-12 welcom mb50">
              <Card>
                <Card.Header style={{ background: "#fff" }}>
                  <p className="tdfontalign"><i className="fa fa-info-circle  alignfa-warning"></i> अर्जाची समीक्षा - समस्या उठविल्या गेलेल्या आहेत</p>
                  <p className="contentdata">
                  आमच्या अर्ज समीक्षा संघटनेने खालील समस्या उठविल्या आहेत. कृपया ही समस्या सोडवा आणि खालील पुन्हा सादर करा बटणावर क्लिक करुन आपला अर्ज पुन्हा सादर करा. जर ह्या समस्यांना सोडवल्या गेलेल्या नसतील, तर आपला अर्ज लॉटरी प्रक्रियेसाठी विचारात घेतला जाणार नाही. कृपया सर्व समस्या सोडवा आणि सर्व बाकीच्या कागदपत्रांना अपलोड करा पुन्हा सादर करा बटणावर क्लिक करण्यापूर्वी.

                    {/* The following issues have been flagged by our application review team. Please resolve them and resubmit your application by
                    clicking the <span className="contentsup">Resubmit</span> button below. <span style={{ fontWeight: 600, color: "#000" }} >If these are not resolved your application will not be considered for the lottery</span> */}
                  </p>
                </Card.Header>
                <Card.Body>
                  <Table striped>
                    <tbody>
                      {
                        issueFound && issueFound.map((data, key) => (
                          <tr key={key}>
                            <td style={{ fontWeight: 600, textTransform: "capitalize" }}>{data?.issue_found_in?.split('_').join(' ')}</td>
                            <td>{data?.issue_category}</td>
                            <td>{data?.issue_description}</td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </div>
            :
            <div className="col-sm-12 welcom mb50">
              <h3 className="text-left heading-font">
                Introduction
                {!languageChnage ? (
                  <a
                    style={{
                      color: "#4640de",
                      fontSize: 16,
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: 1.63,
                      letterSpacing: "normal",
                      marginLeft: 15,
                      cursor: "Pointer"
                    }}
                    onClick={() => setLanguageChnage(true)}
                  >
                    {" "}
                    (For English click here)
                  </a>
                ) : (
                  <a
                    style={{
                      color: "#4640de",
                      fontSize: 16,
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: 1.63,
                      letterSpacing: "normal",
                      marginLeft: 15,
                      cursor: "Pointer"
                    }}
                    onClick={() => setLanguageChnage(false)}
                  >
                    {" "}
                    (मराठीत साठी येथे क्लिक करा)
                  </a>
                )}
              </h3>

              {!languageChnage ? (
                <div>
                  <p className="contentdata text-left">
                    प्रधानमंत्री आवास योजना - शहरी अंतर्गत पिंपरी चिंचवड महानगर पालिकेमार्फत राबविण्यात येत असलेल्या प्रकल्पाकरिता अर्ज करण्यासाठी सदर संकेतस्थळाचा वापर करावा. सद्यस्थितीत 1 प्रकल्पाकरिता अर्ज करण्याची प्रक्रिया चालू आहे. परंतु पुढील नवीन प्रकल्पाकरिता सुद्धा याच संकेत स्थळाद्वारे अर्ज करू शकता. सदर अर्ज 3 टप्प्यात असणार आहे वैयक्तिक माहिती, अनामत रक्कम भरणे व वैयक्तिक माहितीच्या अनुषंगाने कागदपत्रे अपलोड करणे. सदर 3 टप्प्यांची पूर्तता केल्यानंतरच आपले अर्ज पूर्ण होईल व कागदपत्रे तपासणीची पुढील कार्यवाही सुरु होईल.
                  </p>
                  {/* <p className="contentdata text-left">
                  ऑनलाईन अर्ज नोंदणीची अंतिम तारीख 12 ऑगस्ट 2023 पर्यंत वाढवण्यात आली आहे
                  </p> */}
                </div>
              ) : (
                <div>
                  <p className="contentdata text-left">
                    Please use this portal to apply for the Pradhan Mantri Awas Yojana - Urban Scheme under Pimpri Chinchwad Municipal Corporation. Currently 1 projects are open for application but any new projects will be added to the same portal. There are 3 parts to the application, the personal information, payment and upload of documents supporting the personal information. You are required to do all three for your application to be complete, and the documents verification process will start only when all three steps are completed.
                  </p>
                  {/* <p className="contentdata text-left">Last date for Online Application Registration has been extended till 12th August 2023</p> */}
                </div>
              )}

              {languageChnage ?
                <>
                  <h3 className="text-left heading-font" style={{ marginTop: 85 }}>How to Apply</h3>
                  <div className="row">
                    <div className="col-md-12">
                      <img src="/assets/check-icon.svg" alt="check mark" />
                      <p className="ptagalign">An account with your mobile number has already been created.</p>
                    </div>

                    <div className="col-md-12">
                      <img src="/assets/check-icon.svg" alt="check mark" />
                      <p className="ptagalign">When you press Apply below, you will be asked to read and accept a a disclaimer and enter your personal details.</p>
                    </div>

                    <div className="col-md-12">
                      <img src="/assets/check-icon.svg" alt="check mark" />
                      <p className="ptagalign">Make the payment, according to the number of projects you wish to apply for.</p>
                    </div>

                    <div className="col-md-12">
                      <img src="/assets/check-icon.svg" alt="check mark" />
                      <p className="ptagalign">Once your payment is received you will be able to fill up the remaining details and upload documents.</p>
                    </div>

                    <div className="col-md-12">
                      <img src="/assets/check-icon.svg" alt="check mark" />
                      <p className="ptagalign">Application will only be complete and will go for verification once the payment </p>
                      <p className="ptagalign"> personal details and documents all have been completed.</p>
                    </div>

                    {/* <div className="col-md-12">
                      <img src="/assets/check-icon.svg" alt="check mark" />
                      <p className="ptagalign">An account with your mobile number has already been created.</p>
                    </div> */}
                  </div>
                </>
                :
                <>
                  <h3 className="text-left heading-font" style={{ marginTop: 85 }}>अर्ज कसा करावा</h3>
                  <div className="row">
                    <div className="col-md-12">
                      <img src="/assets/check-icon.svg" alt="check mark" />
                      <p className="ptagalign">तुमच्या मोबाईल नंबरसह खाते आधीच तयार केले गेले आहे.</p>
                    </div>

                    <div className="col-md-12">
                      <img src="/assets/check-icon.svg" alt="check mark" />
                      <p className="ptagalign">जेव्हा तुम्ही खाली लागू करा दाबाल, तेव्हा तुम्हाला अस्वीकरण वाचण्यास आणि स्वीकारण्यास आणि तुमचे वैयक्तिक तपशील प्रविष्ट करण्यास सांगितले जाईल.</p>
                    </div>

                    <div className="col-md-12">
                      <img src="/assets/check-icon.svg" alt="check mark" />
                      <p className="ptagalign">तुम्ही अर्ज करू इच्छित असलेल्या प्रकल्पांनुसार अनामत रक्कम भरा.</p>
                    </div>

                    <div className="col-md-12">
                      <img src="/assets/check-icon.svg" alt="check mark" />
                      <p className="ptagalign">एकदा तुमचे अनामत रक्कम प्राप्त झाल्यानंतर उर्वरित माहिती भरण्यास आणि कागदपत्रे अपलोड करु शकता.</p>
                    </div>

                    <div className="col-md-12">
                      <img src="/assets/check-icon.svg" alt="check mark" />
                      <p className="ptagalign">वैयक्तिक तपशील, अनामत रक्कम आणि कागदपत्रे अपलोड झाल्यावर आपले अर्ज पूर्ण होईल व पडताळणीसाठी पुढे जाईल.</p>
                    </div>

                    <div className="col-md-12">
                      <img src="/assets/check-icon.svg" alt="check mark" />
                      <p className="ptagalign">तुमच्या मोबाईल नंबरसह खाते आधीच तयार केले गेले आहे.</p>
                    </div>
                  </div>

                </>
              }
            </div>
        }

        <div className="col-sm-12 text-right">

          {
            issueFoundflag && issueFoundflag ?
              <button className="btn btn-primary pl-5 pr-5 mt-3 pull-right" type="button" onClick={() => reSubmitApplicationAfterEdits()}>
                {/* ReSubmit Application after Edits */}
                पुन्हा सादर करा
              </button>
              :
              <button className="btn btn-primary pl-5 pr-5 mt-3 pull-right" type="button" onClick={() => clickHandle()}>
                Apply to PMAY-U
              </button>
          }
         

        </div>
        <div className="col-sm-12 text-right">
        {resubmitMsg ? <SubmitIssuesModal message={resubmitMsg} showSubmitModal={true}/> : null }
        </div>

      </div>
    </div>
  );
};

export default Home

const General = styled.div`
  .alignatag {
    display: block;
    text-align: left;
    color: #848493;
    text-decoration: underline;
    margin-bottom: 10px;
    cursor: pointer;
  }
`;