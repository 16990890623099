import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Form } from "react-bootstrap";
import PaymentStatusPending from "./PaymentStatusPending";
import PaymentStatusSuccess from "./PaymentStatusSuccess";
import PaymentStatusFailure from "./PaymentStatusFailure";
import { Link, useLocation, BrowserRouter as Router } from "react-router-dom";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}
const PaymentStatus = ({userId, selectUserPaymentDetails}) => {
    let query = useQuery();
    const [selectedArea, setSelectedArea] = useState('');
    const [areaList, setAreaList] = useState('');
    const [selectedPaymentDetails, setSelectedPaymentDetails] = useState({});
    useEffect(() => {
        if(selectUserPaymentDetails){
            let arrayList = Object.keys(selectUserPaymentDetails);
            setAreaList(arrayList)
            onChangeAreaHandler(query.get("area") || arrayList[0])
        }
       
    }, [selectUserPaymentDetails]);

    const onChangeAreaHandler = (area) => {
        if(area){
            setSelectedArea(area);
            setSelectedPaymentDetails(selectUserPaymentDetails[area])
        }
    }

    return (
        <>
            <ContainerFluid className="container-fluid">
                <div className="row">
                    <div className="col-md-5">
                    <Form.Group className="form-group" controlId="selectedArea">
                            <Form.Label>Choose Project to check Payment Status</Form.Label>
                            <Form.Control as='select' className="form-control" value={selectedArea}
                                onChange={(e) => onChangeAreaHandler(e.target.value)} >
                                <option value=''>Select Area</option>
                                {
                                    areaList && areaList.map((data, key) => (
                                        <option key={key} value={data}>{data}  </option>
                                    ))}
                            </Form.Control>
                        </Form.Group>
                    </div>
                </div>
                <div className="row">
                    { selectedPaymentDetails?.txn_status==='PENDING' ? <PaymentStatusPending selectedArea={selectedArea} selectUserPaymentDetails={selectedPaymentDetails} /> : null}
                    { selectedPaymentDetails?.txn_status==='FAILURE'  ? <PaymentStatusFailure selectedArea={selectedArea} selectUserPaymentDetails={selectedPaymentDetails} /> : null}
                    { selectedPaymentDetails?.txn_status==='SUCCESS' ? <PaymentStatusSuccess userId={userId} selectedArea={selectedArea} selectUserPaymentDetails={selectedPaymentDetails} /> : null}
                </div>
            </ContainerFluid>
        </>
    )
}

export default PaymentStatus;


const ContainerFluid = styled.div`

    .payment-amount{
        font-size: 30px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.22;
        letter-spacing: normal;
        color: #232b39;
    }
    .pending-color{
        padding: 3px 15px 3px 15px;
        border-radius: 20px;
        background-color: #ffb836;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        color: #fff;
    }

    .table td, .table th {
        border-top: 0;
    }
    .table td.first{
        text-align:left;
    }
    .payment-pending-content .table td.second{
        text-align:right;
    }
    .success-color{
        padding: 3px 15px 3px 15px;
        border-radius: 20px;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        color: #fff;
        background-color: #56cdad;
    }
    .failure-color{
        padding: 3px 15px 3px 15px;
        border-radius: 20px;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        color: #fff;
        background-color: #ff6550;
    }
    .print-btn{
        padding: 5px 25px 5px 25px;
        border-radius: 10px !important;
        background-color: #f4f5f6;
    }
    .payment-reciept-content {
        padding: 10px 18px 37px;
        border-radius: 10px;
        box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
        border: solid 1px #f4f5f6;
        background-color: #fff;
        margin-top: 30px;
    }
    .payment-reciept-content .table td {
        padding: 6px 0;
    }
    .payment-reciept-content .table td.second{
        text-align: right;
    }

`;
