import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import ApiDataService from "../../services/api-service";
import { useDispatch, useSelector } from "react-redux";
import { FilterPayload1Items, FilterPayload2Items } from "../../services/PayloadAPIService";
import { useHistory } from "react-router-dom";
import { updateRequestStatus, selectUpdateUserReqStatus, selectUserPayload2, fetchUserRequest, selectUserRequestData } from "../../redux/slices/userRequestSlice";
import Spinner from "../Spinner";
import { selectUserDropdownData } from "../../redux/slices/MasterDropdownSlice";
import { postPayload2Data, getPincodeVerificationData } from "../../services/PayloadAPIService";
const HousingStatus = ({ userId, onNextTabHandler }) => {
    const dispatch = useDispatch();
    const selectUpdateUserReqStatus = useSelector((state) => state.userRequest?.updateStatusRequest);
    const masterDropdownList = useSelector(selectUserDropdownData);
    // const [housingPinCode, setHousingPinCode] = useState('');
    const [housingState, setHousingState] = useState([]);
    const [selecthousingState, setSelecthousingState] = useState([]);
    const [housingDistrict, setHousingDistrict] = useState([]);
    const [selecthousingDistrict, setSelecthousingDistrict] = useState([]);
    const [housingCity, setHousingCity] = useState([]);
    const [selectCity, setCitySelected] = useState('');
    const [wardName, setWardName] = useState('');
    const [selectedwardName, setSelectedWardName] = useState('');
    const [slumName, setSlumName] = useState('');
    const [selectedSlumName, setSelectedSlumName] = useState('');
    const [areaName, setAreaName] = useState('');
    const [selectedAreaName, setSelectedAreaName] = useState('');
    const [validated, setValidated] = useState(false);

    const history = useHistory();
    const [uploading, setUploading] = useState(false);
    const selectUserPayload2Data = useSelector(selectUserPayload2);

    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
        } else {
            manageHousingStatusHandler();
            event.preventDefault();
            event.stopPropagation();
        }
    };

    useEffect(() => {
        if (masterDropdownList?.config_id) {
            setWardName(masterDropdownList?.config_id?.ward_name);
            setSlumName(masterDropdownList?.config_id?.slum_Names);
            setAreaName(masterDropdownList?.config_id?.area_name);
        }
    }, [masterDropdownList?.config_id]);

    useEffect(() => {
        setSelectedWardName(selectUserPayload2Data?.housing_ward);
        setSelectedSlumName(selectUserPayload2Data?.housing_slum_name);
        setSelectedAreaName(selectUserPayload2Data?.housing_area_name);
    }, [selectUserPayload2Data]);

    const manageHousingStatusHandler = async () => {
        setUploading(true);
        let input = {
            // flag: true,
            // isSaved: true,
            // // pinCode: housingPinCode,
            // // state: selecthousingState,
            // // district: selecthousingDistrict,
            // // city: selectCity,
            // wardName: selectedwardName,
            // slumName: selectedSlumName,
            // areaName: selectedAreaName,
            // hfaMission: selectedHFA,

            housing_ward: selectedwardName,
            housing_slum_name: selectedSlumName,
            housing_area_name: selectedAreaName,
        };
        let payload = {
            user_id: userId,
            payload1: FilterPayload1Items('addressAndHousingFilled', 'tab1'),
            payload2: FilterPayload2Items(input, selectUserPayload2Data)
        };

        let response = await postPayload2Data(payload);
        if (response.status == 200) {
            dispatch(fetchUserRequest({ userId }));
            onNextTabHandler('currentAddress');
        }
        setUploading(false);
    };

    const handleKeyPress = (event) => {
        getPincodeData(event);
    }

    const clearDropdown = () => {
        setSelecthousingState([]);
        setSelecthousingDistrict([]);
        setCitySelected([]);
    }

    function getPincodeData(pincode) {
        getPincodeVerificationData(pincode)
            .then(response => {
                if (response.status === 200 && response.data[0].Status === "Success") {
                    let resData = response.data[0].PostOffice;
                    let uniqueStateName = resData.map(item => item.State)
                        .filter((value, index, self) => self.indexOf(value) === index);
                    let uniqueDistrictName = resData.map(item => item.District)
                        .filter((value, index, self) => self.indexOf(value) === index)
                    setHousingState(uniqueStateName);
                    setHousingDistrict(uniqueDistrictName);
                    setHousingCity(resData);
                } else {
                    clearDropdown();
                }
            })
            .catch(err => {
                console.log('An error occurred:', err.res);
                clearDropdown();
            });
    }

    return (
        <div className="container-fluid">
            {selectUpdateUserReqStatus?.state === 'loading' ? <Spinner /> :
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-sm-3 ">
                            <h5 className="headingTitle">Housing Situation</h5>
                            <p className="contentdata">Please enter the following
                                information on your current
                                housing situation
                            </p>

                        </div>
                        <div className="col-sm-6 offset-sm-1">
                            <Form.Group className="form-group" controlId="selectedwardName" style={{ marginTop: "15px" }}>
                                <Form.Label>Ward Name</Form.Label>
                                <Form.Control as='select' multiple={false} className="form-control" value={selectedwardName}
                                    onChange={(e) => setSelectedWardName(e.target.value)} required>
                                    <option value=''>Select a Ward Name:</option>
                                    {
                                        wardName && wardName.map((data, key) => {
                                            return (<option key={key} value={data}>{data}</option>)
                                        })}
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    Please Select Ward Name
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group className="form-group" controlId="selectedSlumName">
                                <Form.Label>Slum Name (Only if presently living in a slum)</Form.Label>
                                <Form.Control as='select' multiple={false} className="form-control" value={selectedSlumName}
                                    onChange={(e) => setSelectedSlumName(e.target.value)} required>
                                    <option value=''>Select a Slum Name:</option>
                                    {slumName && slumName.map((data, key) => {
                                        return (<option key={key} value={data}>{data}</option>)
                                    })}
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    Please Select Slum Name
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="form-group" controlId="selectedAreaName">
                                <Form.Label>Area Name</Form.Label>
                                <Form.Control as='select' multiple={false} className="form-control" value={selectedAreaName}
                                    onChange={(e) => setSelectedAreaName(e.target.value)} required>
                                    <option value=''>Select a Area Name:</option>
                                    {
                                        areaName && areaName.map((data, key) => {
                                            return (<option key={key} value={data}>{data}</option>)
                                        })}
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    Please Select Area Name
                                </Form.Control.Feedback>
                            </Form.Group>
                            <button className="btn btn-primary mt-5" type="submit" >Save & Proceed</button>
                        </div>
                    </div>
                </Form>}
        </div>
    )
}

export default HousingStatus