import React, { useState, useEffect } from "react";
import styled from "styled-components";
import "bootstrap/dist/css/bootstrap.min.css";
import "bs-stepper/dist/css/bs-stepper.min.css";
import AcceptTerms from "./AcceptTerms";
import Information from "./Information";
import Verification from "./Verification";
import Stepper from "bs-stepper";
import UploadDocuments from "./UploadDocuments";
import { useDispatch, useSelector } from "react-redux";
import { selectUserPayload2 } from "../../redux/slices/userRequestSlice";
import { useLocation } from "react-router-dom";
import { fetchUserRequest, selectUserRequestData } from "../../redux/slices/userRequestSlice";
import { selectUserWisePayment } from "../../redux/slices/userWisePaymentSlice";
import { useHistory } from "react-router-dom";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Identification = ({ getApplicationIdHandler }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  let query = useQuery();
  const [getTab, setTab] = useState(query.get("tab"));

  const [stepper, setStepper] = useState();
  const [pageInitialLoad, setPageInitialLoad] = useState('acceptTerms');
  const [userId, setUserId] = useState("");
  const [acceptTerms, setAcceptTerms] = useState(false);
  const selectUserPayload2Data = useSelector(selectUserPayload2);
  const selectUserRequest = useSelector(selectUserRequestData);

  useEffect(() => {
    if (getTab && stepper && acceptTerms) {
        if(Number(getTab)===3){
            stepper.to(3);
            setPageInitialLoad('verification')
        }
    }
},[getTab, stepper, acceptTerms ]);

useEffect(() => {
  if (selectUserPayload2Data) {
    setAcceptTerms(selectUserPayload2Data?.identification_terms_accepted);
  }
}, [selectUserPayload2Data])

  const selectUserPaymentDetails = useSelector(selectUserWisePayment);

  const [akurdiProj, setAkurdiProj] = useState(false);
  // const [pimpriProj, setPimpriProj] = useState(false);

  useEffect(() => {
    if (selectUserPaymentDetails && selectUserPaymentDetails?.projectwise_transactions?.DUDULGAON == null) {
      setAkurdiProj(false);
    } else {
      if (selectUserPaymentDetails && (selectUserPaymentDetails?.projectwise_transactions?.DUDULGAON?.txn_status === "SUCCESS")) {
        setAkurdiProj(true);
        setPageInitialLoad('verification')
      } 
    }
  }, [selectUserPaymentDetails]);

  useEffect(() => {
    setStepper(
      new Stepper(document.querySelector("#mainTabStepper"), {
        linear: false,
        animation: true,
      })
    );
  }, []);

  useEffect(() => {
    if(selectUserRequest?.data?.application_id){
      getApplicationIdHandler(selectUserRequest?.data?.application_id)
    }
  }, [selectUserRequest?.data?.application_id]);

  useEffect(() => {
    if (localStorage.getItem("userId")) {
      setUserId(localStorage.getItem("userId"))
    }
  }, []);

  // useEffect(() => {
  //   if (userId){
  //     setPageInitialLoad('acceptTerms');
  //     dispatch(fetchUserRequest({ userId }));
  //   }
  // }, [userId]);

  const onNextTabHandler = (page) => {
    if (stepper && page){
      stepper.next();
      setPageInitialLoad(page)
    }
  };
  return (
    <MainTabStepper>
      <Title>Basic Applicant Identification</Title>
      <div id="mainTabStepper" className="bs-stepper">
        <div className="bs-stepper-header">
          <div className="step active" data-target="#basic-information-1">
            <button className="step-trigger step-trigger1"
              id="basic-information-btn-1"
              data-bs-toggle="pill"
              href="#basic-information-1"
              role="tab"
              aria-controls="employee-policy-t"
              aria-selected="true"
              onClick={() => { setPageInitialLoad('acceptTerms') }} >
                <span className="bs-stepper-circle">
                <img
                  src="/assets/icons/icon-thumb-up-grey.svg"
                  className="img-fluid"
                  alt="Status"
                />
                <img
                  src="/assets/icons/white/Thumb Up.svg"
                  className="img-fluid active-icon"
                  alt="Status"
                />
              </span>
            
              <span className="bs-stepper-label">
                <div style={{ color: "#4640de", fontSize: 14, fontWeight: 500 }}>
                  Step 1/4
                </div>
                <div>Accept Terms</div>
              </span>
            </button>
          </div>
          <div className="line"></div>
          <div className="step" data-target="#basic-information-2">
            <button className="step-trigger" onClick={() => { setPageInitialLoad('informaton') }}>
            <span className="bs-stepper-circle">
                <img
                  src="/assets/icons/briefcase-grey.svg"
                  className="img-fluid"
                  alt="Status"
                />
                <img
                  src="/assets/icons/Icon.svg"
                  className="img-fluid active-icon"
                  alt="Status"
                />
              </span>
             
              <span className="bs-stepper-label">
                <div
                  style={{ color: "#4640de", fontSize: 14, fontWeight: 500 }}
                >
                  Step 2/4
                </div>
                <div>Enter Information</div>
              </span>
            </button>
          </div>
          <div className="line"></div>
          <div className="step" data-target="#basic-information-3">
            <button className="step-trigger"  disabled={akurdiProj ? false : true}
            //  disabled={akurdiProj ? false : true}
            onClick={() => { setPageInitialLoad('verification') }}>
            <span className="bs-stepper-circle">
                <img
                  src="/assets/icons/icon-check-grey.svg"
                  className="img-fluid"
                  alt="Status"
                />
                <img
                  src="/assets/icons/white/check.svg"
                  className="img-fluid active-icon"
                  alt="Status"
                />
              </span>
             
              <span className="bs-stepper-label">
                <div
                  style={{ color: "#4640de", fontSize: 14, fontWeight: 500 }}
                >
                  Step 3/4
                </div>
                <div>Aadhaar & PAN</div>
              </span>
            </button>
          </div>
          <div className="line"></div>
          <div className="step" data-target="#basic-information-4">
            <button className="step-trigger" disabled={akurdiProj ? false : true}
            //   disabled={akurdiProj ? false : true} 
            onClick={() => { setPageInitialLoad('uploadDocuments') }}>
            <span className="bs-stepper-circle">
                <img
                  src="/assets/icons/Icon 2.svg"
                  className="img-fluid"
                  alt="Status"
                />
                <img
                  src="/assets/icons/white/Clipboard.svg"
                  className="img-fluid active-icon"
                  alt="Status"
                />
              </span>

              <span className="bs-stepper-label">
                <div
                  style={{ color: "#4640de", fontSize: 14, fontWeight: 500 }}
                >
                  Step 4/4
                </div>
                <div>Upload Documents</div>
              </span>
            </button>
          </div>
        </div>
        <div className="bs-stepper-content">
          <div id="basic-information-1" role="tabpanel" style={{ display: pageInitialLoad==='acceptTerms' ? 'block' : 'none' }}
             className="content fade active dstepper-block">
            {pageInitialLoad==='acceptTerms' ?  <AcceptTerms userId={userId} onNextTabHandler={onNextTabHandler} /> : null }
            
          </div>
          <div id="basic-information-2" className="content" style={{ display: pageInitialLoad==='informaton' ? 'block' : 'none' }}>
          {pageInitialLoad==='informaton' ? <Information userId={userId} onNextTabHandler={onNextTabHandler} /> : null }
         
          </div> 
          <div id="basic-information-3" className="content" style={{ display: pageInitialLoad==='verification' ? 'block' : 'none' }}>
          {pageInitialLoad==='verification' ? <Verification userId={userId} onNextTabHandler={onNextTabHandler} /> : null }
         
          </div>
          <div id="basic-information-4" className="content text-center" style={{ display: pageInitialLoad==='uploadDocuments' ? 'block' : 'none' }}>
          {pageInitialLoad==='uploadDocuments' ? <UploadDocuments userId={userId} onNextTabHandler={onNextTabHandler} /> : null }
          </div>
        </div>
      </div>
    </MainTabStepper>
  );
};

export default Identification;

const Title = styled.h3`
  font-family: ClashDisplay;
  font-size: 32px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  color: #25324b;
  margin-bottom: 15px;

  @media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait){
    font-family: ClashDisplay;
    font-size: 20px;
  }
`;

const MainTabStepper = styled.div`
   padding:15px;
  .bs-stepper-header {
    justify-content: space-between;
    border-radius: 4px;
    border: solid 1px #d6ddeb;
    background-color: #fff;
    margin-bottom: 25px;
    overflow-x: auto;
  }
  .bs-stepper .line, .bs-stepper-line {
      display: none;
  }
  .bs-stepper .step{
    flex: 1;
    padding: 5px;
  }
  .bs-stepper .step-trigger{
    border-left: 1px solid #d6ddeb;
    padding: 0px;
    border-radius: 0;
    width: 100%;
    text-align: left;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .bs-stepper .step-trigger1{
    border-left: 0;
  }
  .bs-stepper-circle {
    width: 3em;
    height: 3em;
    padding: 12px;
    border-radius: 50%;
    background-color: #e9ebfd;
  }
  .bs-stepper .step-trigger:hover {
    background: none;
    border-radius: 0px;
  }
  .bs-stepper-circle .active-icon{
    display:none;
    padding: 2px;
  }
  .active .bs-stepper-circle {
    fill: white;
  }
  .active .bs-stepper-circle {
    background-color: #4640de;
  }
  .active .bs-stepper-circle img {
    display:none;
  }
  .active .bs-stepper-circle .active-icon{
    display:block;
  }
  .active .bs-stepper-circle:hover  {
    // background-color: #007bff;
  }
  .bs-stepper-label{
    font-size: 14px;
  }
`;
