import React, { useState, useEffect } from "react";
import {
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  NavLink,
  useHistory
} from "react-router-dom";
import styled from "styled-components";
import Identification from "../components/identification/Identification";
import AddressHousing from "../components/addresshousing/AddressHousing";
import EmployementAndFinance from "../components/employement/EmployementAndFinance";
import BankDetails from "../components/bankdetail/BankDetails";
import FamilyDependent from "../components/familydependent/FamilyDependent";
import SpecialCase from "../components/special-case/SpecialCase";
import UploadDocuments from "../components/upload-documents/UploadDocuments";
import "./Dashboard.scss";
import PostApplicationProcess from "../components/PostApplicationProcess";
import { APP_NAME } from "../constants";
import SideVerticleStepper from "../components/SideVerticleStepper";
// import AuthRoute from "../components/routes/AuthRoute";
// import RoutPageContext from "../context/RoutPageContext";
import OnlinePayment from "../components/onlinepayment/OnlinePayment";
import { fetchMasterDropdownData } from "../redux/slices/MasterDropdownSlice";
import { useDispatch } from "react-redux";
// import { fetchUserRequest } from "../redux/slices/userRequestSlice";
import { logoutFromAPI } from "../services/PayloadAPIService";
import PaymentDetails from "../components/onlinepayment/PaymentDetails";
import Home from "../components/home/Home";
import MobileLeftNav from "../components/MobileLeftNav";
//import { selectUserProfile } from "../redux/slices/userProfileSlice";
const Dashboard = () => {
  const { path, url } = useRouteMatch();
  const dispatch = useDispatch();
  const history = useHistory();
  // const [userId, setUserId] = useState("");
  const [userMobile, setUserMobile] = useState("");
  const [applicationId, setApplicationId] = useState("");
  const [showGloble, setShowGloble] = useState({
    stage: 1,
    pageTitle: "Identification",
  });
  const [mobileViewContainer, setMobileViewContainer] = useState(false);

  const pageHandler = (obj) => {
    setShowGloble(obj);
  };

  const logoutHandler = () => {
    logoutFromAPI().then((response) => {
      if (response?.status === 200) {
        localStorage.removeItem("token");
        localStorage.removeItem("userId");
        localStorage.removeItem("mobileNo");
        history.push("/login");
      }
    });
  };

  useEffect(() => {
    dispatch(fetchMasterDropdownData());
  }, [dispatch]);

  // useEffect(() => {
  //   if (localStorage.getItem("userId")) {
  //     setUserId(localStorage.getItem("userId"));
  //   }
  // }, []);
  useEffect(() => {
    if (localStorage.getItem("mobileNo")) {
      setUserMobile(localStorage.getItem("mobileNo"));
    }
  }, []);

  // useEffect(() => {
  //   if (userId)
  //     dispatch(fetchUserRequest({ userId }));
  // }, [userId]);

  const getApplicationIdHandler = (id) => {
    if (id)
      setApplicationId(id);
  }

  function toggleNav() {
    var element = document.getElementById("mySidenav")
    var shown = element.getAttribute("data-isshown");
    if (shown == "true") {
      element.setAttribute("data-isshown", "false");
      element.style.width = "0";

    } else {
      element.setAttribute("data-isshown", "true");
      element.style.width = "250px";
      setMobileViewContainer(true)
    }
  }

  return (
    <DashboardContainer className="container-fluid">
      {/* <RoutPageContext.Provider
    value={{
      showGloble,
      setShowGloble,
    }}
  > */}
      <div className="row">
        <div className="col-md-3" style={{ paddingRight: 0, paddingLeft: 0 }}>
          <MobileLeftNav className="lg-hidden md-hidden" userMobile={userMobile} applicationId={applicationId} logoutHandler={logoutHandler} mobileViewContainer={mobileViewContainer} setMobileViewContainer={setMobileViewContainer} ></MobileLeftNav>
          <SidebarTabs className="xs-hidden">
            <SidebarTopSection>
              <LogoTitle>{APP_NAME}</LogoTitle>
              <NavLink to={`${url}/home`} activeClassName="active">
                <PageTitle>Home</PageTitle>
              </NavLink>
            </SidebarTopSection>

            <hr />
            <SidebarTopSection>
              <PageTitle>APPLICATION PROCESS</PageTitle>
              <SideVerticleStepper pageHandler={pageHandler} />
            </SidebarTopSection>
            <hr />
            <SidebarTopSection>
              <PageTitle>HELP AND SUPPORT</PageTitle>
              <PostApplicationProcess logoutHandler={logoutHandler} />
            </SidebarTopSection>
          </SidebarTabs>
        </div>
        <div className="col-md-9 dashboard-right-section" style={{ paddingLeft: 0, paddingRight: 0 }}>
          <MobileHeader className="lg-hidden md-hidden">
            <header class="header">
              <span style={{ fontSize: 30 }} onClick={() => toggleNav()}>☰</span>
              <h2> Online Application Process</h2>
            </header>
          </MobileHeader>
          <Header>
            <div style={{ display: "flex" }}>
              <div className="col-md-8">
                <HeaderTitle>
                  <p className="stage">
                    Stage {showGloble && showGloble.stage}
                  </p>
                  <p style={{ fontWeight: 600 }}>{showGloble && showGloble.pageTitle}</p>
                </HeaderTitle>
              </div>
              <div className="col-md-4 pull-right xs-hidden">
                <p className="stage">Mobile Number : {userMobile}</p>
                <p className="stage">Application Id : {applicationId}</p>
              </div>
            </div>
          </Header>
          <Switch>

            <Route
              path={`${url}/home`}
              component={() =>
                <Home getApplicationIdHandler={getApplicationIdHandler} />
              }
              exact
            />
            <Route
              path={`${url}/identification`}
              component={() =>
                <Identification getApplicationIdHandler={getApplicationIdHandler} />
              }
              exact
            />

            <Route
              path={`${url}/addresshousing`}
              component={() => <AddressHousing getApplicationIdHandler={getApplicationIdHandler} />}
              exact
            />
            <Route
              path={`${url}/employment-finacials`}
              component={() => <EmployementAndFinance getApplicationIdHandler={getApplicationIdHandler} />}
              exact
            />
            <Route
              path={`${url}/bank-details`}
              component={() => <BankDetails getApplicationIdHandler={getApplicationIdHandler} />}
              exact
            />
            <Route
              path={`${url}/family-dependent`}
              component={() => <FamilyDependent getApplicationIdHandler={getApplicationIdHandler} />}
              exact
            />
            <Route
              path={`${url}/special-case`}
              component={() => <SpecialCase getApplicationIdHandler={getApplicationIdHandler} />}
              exact
            />
            <Route
              path={`${url}/online-payment`}
              component={() =>
                <PaymentDetails getApplicationIdHandler={getApplicationIdHandler} />
                // <OnlinePayment getApplicationIdHandler={getApplicationIdHandler} />
              }

              exact
            />
            <Route
              path={`${url}/upload-documents`}
              component={() => <UploadDocuments getApplicationIdHandler={getApplicationIdHandler} />}
              exact
            />

            <Route path={`${path}`}>
              <Redirect to={`${url}/home`} getApplicationIdHandler={getApplicationIdHandler} />
            </Route>
          </Switch>
        </div>
      </div>
      {/* </RoutPageContext.Provider> */}
    </DashboardContainer>
  );
};

export default Dashboard;

const DashboardContainer = styled.div`
  background-color: #fff;
  width: 100%;
  min-height: 100vh;
  margin-right: auto;
  margin-left: auto;
  // overflow-x:hidden;
`;

const LogoTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.8;
  letter-spacing: -0.2px;
  color: #202430;
  margin-bottom: 25px;
`;
const PageTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0.56px;
  color: #202430;
`;

const Header = styled.div`
  margin-left: 1px;
  box-shadow: inset 0 -1px 0 0 #e2e7f5;
  background-color: #fff;
  margin-bottom: 0;
  padding: 5px;
  padding-left: 0px;
  .stage {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    letter-spacing: normal;
    color: #515b6f;
  }
  p {
    margin-bottom: 5px;
  }
`;

const HeaderTitle = styled.h3`
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #25324b;
`;

const SidebarTabs = styled.div`
  padding: 0;
  box-shadow: 1px 0 0 0 #d3d6db;
  background-color: #f8f8fd;

  min-height: 100vh;
  // flex: 0 0 auto;
  // width: 16%;
  /* min-width: 218px; */
  padding-top: 30px;
  // position: fixed;

  position: absolute;
    bottom: 0;
    top: 0;

    @media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
      position: relative;
      margin-top: 15px !important;
      .bs-stepper, .bs-stepper-header {
        overflow:hidden;
      }
      .bs-stepper-header button.nav-link {
          height: 17px;
      }
    }
   
`;

const SidebarTopSection = styled.div`
  padding: 5px 25px;
`;

const MobileHeader = styled.div`
.header {
  background:#fff;
  box-shadow:0 2px 5px rgba(0,0,0,0.2);
  padding: 15px 20px;
  width:100%;
  position:fixed;
  top:0;
  left:0;
  z-index:1;
  display: flex !important;
    flex-direction: row;
    gap: 16px;
    align-items: end;
  }
  .header h2 {
    font-family: ClashDisplay;
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
}
  }
}

`;

