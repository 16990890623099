import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
// import ApiDataService from "../../services/api-service";
import { useDispatch, useSelector } from "react-redux";
import { FilterPayload1Items, FilterPayload2Items } from "../../services/PayloadAPIService";
import { useHistory } from "react-router-dom";
import { selectUpdateUserReqStatus, selectUserPayload1, selectUserPayload2, fetchUserRequest } from "../../redux/slices/userRequestSlice";
import { selectUserDropdownData } from "../../redux/slices/MasterDropdownSlice";
import { Utility } from "../../utils/utility";
import Spinner from "../Spinner";
import { postPayload2Data } from "../../services/PayloadAPIService";

const HouseDetail = ({ userId, onNextTabHandler }) => {
    const selectDataUserReqStatus = useSelector((state) => state.userRequest?.userRequestListState);
    const [noOfYear, setNoOfYear] = useState('');
    const [ownership, setOwnership] = useState('');
    const [selectedownership, setSelectedOwnership] = useState('');
    const [roofType, setRoofType] = useState('');
    const [selectedroofType, setSelectedRoofType] = useState('');
    const [sizeOfDwelling, setSizeOfDwelling] = useState('');
    const [noOfRoom, setNoOfRoom] = useState('');
    const [validated, setValidated] = useState(false);

    const dispatch = useDispatch();
    const history = useHistory();
    const [uploading, setUploading] = useState(false);
    const selectUserPayload2Data = useSelector(selectUserPayload2);
    const selectUserPayload1Data = useSelector(selectUserPayload1);
    const masterDropdownList = useSelector(selectUserDropdownData);

    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
        } else {
            manageHousingDetailHandler();
            event.preventDefault();
            event.stopPropagation();
        }
    };

    useEffect(() => {
        if (masterDropdownList?.config_id) {
            setOwnership(masterDropdownList?.config_id?.house_ownership);
            setRoofType(masterDropdownList?.config_id?.house_type);
        }
    }, [masterDropdownList?.config_id]);

    useEffect(() => {
        if (selectUpdateUserReqStatus?.state === 'succeeded') {
            dispatch(fetchUserRequest({ userId }));
        }
    }, [selectUpdateUserReqStatus?.state]);

    useEffect(() => {
        if (selectUserPayload2Data) {
            setNoOfYear(selectUserPayload2Data?.house_details_years_lived);
            setSelectedOwnership(selectUserPayload2Data?.house_details_ownership_type);
            setSelectedRoofType(selectUserPayload2Data?.house_details_house_type);
            setSizeOfDwelling(selectUserPayload2Data?.house_details_house_size);
            setNoOfRoom(selectUserPayload2Data?.house_details_no_of_room);
        }
    }, [selectUserPayload2Data]);

    const manageHousingDetailHandler = async () => {
        setUploading(true);
        let input = {
            // flag: true,
            // isSaved: true,
            // noOfYear: noOfYear,
            // ownership: selectedownership,
            // roofType: selectedroofType,
            // sizeOfDwelling: sizeOfDwelling,
            // noOfRoom: noOfRoom,
            // owner:owner

            house_details_years_lived: noOfYear,
            house_details_ownership_type: selectedownership,
            house_details_house_type: selectedroofType,
            house_details_house_size: sizeOfDwelling,
            house_details_no_of_room: noOfRoom,
        };

        let payload = {
            user_id: userId,
            payload1: FilterPayload1Items('addressAndHousingFilled', 'lastTab'),
            payload2: FilterPayload2Items(input, selectUserPayload2Data)
        };

        let response = await postPayload2Data(payload);
        if (response.status == 200) {
            dispatch(fetchUserRequest({ userId }));
            history.push(`/dashboard/employment-finacials`);
        }
        setUploading(false);
    };

    return (
        <div className="container-fluid">
            {uploading || selectDataUserReqStatus?.state === 'loading' ? <Spinner /> : <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-sm-3 ">
                        <h5 className="headingTitle">Housing Situation</h5>
                        <p className="contentdata">Please enter your permanent address details here.
                        </p>
                    </div>
                    <div className="col-sm-6 offset-sm-1">
                        <Form.Group className="form-group" controlId="noOfYear" >
                            <Form.Label>No.of years you have lived in this town</Form.Label>
                            <Form.Control
                                className="form-control"
                                type="text"
                                placeholder="Enter No of years"
                                pattern='[0-9]{0,2}'
                                value={noOfYear}
                                maxLength={3}
                                onChange={(e) => setNoOfYear(e.target.value)}
                                isInvalid={noOfYear && !Utility.validateYearsUpto100(noOfYear)}
                                required
                            ></Form.Control>
                            <Form.Control.Feedback type="invalid">
                                Please provide valid No. of years.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <div className="row">
                            <div className="col">
                                <Form.Group className="form-group" controlId="selectedownership">
                                    <Form.Label>Ownership of existing house</Form.Label>
                                    <Form.Control as='select' className="form-control" value={selectedownership}
                                        onChange={(e) => setSelectedOwnership(e.target.value)} required>
                                        <option value=''>Select a Ownership:</option>
                                        {
                                            ownership && ownership.map((data, key) => (
                                                <option key={key} value={data}>{data}  </option>
                                            ))}
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        Please Select Ownership of existing house
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </div>
                            <div className="col">
                                <Form.Group className="form-group" controlId="selectedroofType">
                                    <Form.Label>Type of house by roof type</Form.Label>
                                    <Form.Control as='select' className="form-control" value={selectedroofType}
                                        onChange={(e) => setSelectedRoofType(e.target.value)} required>
                                        <option value=''>Select a Roof type:</option>
                                        {
                                            roofType && roofType.map((data, key) => (
                                                <option key={key} value={data}>{data}  </option>
                                            ))}
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        Please Select Type of house by roof type
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </div>
                        </div>
                        <Form.Group className="form-group" controlId="sizeOfDwelling" >
                            <Form.Label>Size of existing dwelling unit (Carpet area in square feet)</Form.Label>
                            <Form.Control
                                className="form-control"
                                type="text"
                                placeholder="Enter Size"
                                value={sizeOfDwelling}
                                pattern='[0-9]{0,4}'
                                maxLength={4}
                                onChange={(e) => setSizeOfDwelling(e.target.value)}
                                isInvalid={sizeOfDwelling && !Utility.validateSizeOfDwelling(sizeOfDwelling) && !Utility.validateNumber(sizeOfDwelling)}
                                required
                            ></Form.Control>
                            <Form.Control.Feedback type="invalid">
                                {!sizeOfDwelling ? 'Please provide Size of existing dwelling unit (Carpet area in square feet).' : null}
                                {sizeOfDwelling && !Utility.validateNumber(sizeOfDwelling) ? 'Carpet area in square feet should be a number' : sizeOfDwelling && !Utility.validateSizeOfDwelling(sizeOfDwelling) ? 'Carpet area in square feet should not more than 5000' : null}

                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="form-group" controlId="noOfRoom" >
                            <Form.Label>Number of rooms in dwelling unit excluding kitchen</Form.Label>
                            <Form.Control
                                className="form-control"
                                type="text"
                                placeholder="Enter Number of rooms"
                                value={noOfRoom}
                                pattern='[0-9]{0,4}'
                                onChange={(e) => setNoOfRoom(e.target.value)}
                                isInvalid={noOfRoom && !Utility.validateNoOfRoom(noOfRoom)}
                                required
                            ></Form.Control>
                            <Form.Control.Feedback type="invalid">
                                {noOfRoom && !Utility.validateNoOfRoom(noOfRoom) ? 'Number of rooms should not more than 20 '
                                    : ' Please provide Number of rooms in dwelling unit excluding kitchen.'}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-4 offset-sm-4">
                        <button className="btn btn-primary mt-5 " type="submit"
                            disabled={!Utility.validateSizeOfDwelling(sizeOfDwelling) || !Utility.validateNoOfRoom(noOfRoom)}>Save & Proceed</button>
                    </div>
                </div>
            </Form>}
        </div>
    )
}

export default HouseDetail