export const UserModalData = {
        identification_primary_mobile_no : "",
        identification_terms_accepted : false,
        identification_name_as_per_aadhaar : "",
        identification_primary_email_id : "",
        identification_is_email_verified : false,
        identification_alternate_mobile_no : "",
        identification_pan_no : "",
        identification_is_pan_verified : false,
        identification_aadhaar_no : "",
        identification_is_aadhaar_linked : false,
        identification_is_aadhaar_verified : false,
        identification_aadhaar_verification_type : "",
        housing_ward : "",
        housing_slum_name : "",
        housing_area_name : "",
        current_address_pin : "",
        current_address_state : "",
        current_address_district : "",
        current_address_area : "",
        current_address_street : "",
        current_address_house_no : "",
        is_permanent_address_same_as_current : false,
        permanent_address_pin : "",
        permanent_address_state : "",
        permanent_address_district : "",
        permanent_address_area : "",
        permanent_address_street : "",
        permanent_address_house_no : "",
        house_details_years_lived : "",
        house_details_ownership_type : "",
        house_details_house_type : "",
        house_details_house_size : "",
        house_details_no_of_room : "",
        bank_account_no:"",
        bank_confirm_account_no:"",
        bank_ifsc_code:"",
        bank_name:"",
        bank_branch:"",
        employment_status : "",
        employment_occupation : "",
        employment_avg_monthly_income : "",
        employment_is_bpl : false,
        employment_bpl_card_no : "",
        applicant_name : "",
        applicant_gender : "",
        applicant_dob : "",
        applicant_marital_status : "",
        applicant_religion : "",
        applicant_caste : "",
        applicant_father_name : "",
        family_member_count : "",
        family_member_list : [
            {
                member_name : "",
                member_relation : "",
                member_dob : "",
                member_gender : "",
                member_aadhaar : "",
                member_other_id_type : "",
                member_other_id_no : "",
                member_document_file_name : ""
            }
        ],
        special_case_is_disability : false,
        special_case_disability_card_no : "",
        payment_terms_accepted : false,
        payment_status : false,
        // add any more keys if required
        // SECTION FOR UPLOADED FILES
        applicant_aadhaar_front_file_name : "",
        applicant_aadhaar_front_upload_timestamp : "",
        applicant_aadhaar_back_file_name : "",
        applicant_aadhaar_back_upload_timestamp : "",
        applicant_pan_file_name : "",
        applicant_pan_upload_timestamp : "",
        applicant_photo_file_name : "",
        applicant_photo_upload_timestamp : "",
        applicant_photo_uploaded : false,
        bpl_proof_file_name : "",
        bpl_proof_upload_timestamp : "",
        disability_proof_file_name : "",
        disability_proof_upload_timestamp : "",
        income_proof_file_name : "",
        income_proof_upload_timestamp : "",
        caste_proof_file_name : "",
        caste_proof_upload_timestamp : "",
        voter_id_file_name : "",
        voter_id_upload_timestamp : "",
        rent_agreement_file_name : "",
        rent_agreement_upload_timestamp : "",
        electricity_bill_file_name : "",
        electricity_bill_upload_timestamp : "",
        domicile_cert_file_name : "",
        domicile_cert_upload_timestamp : "",
        other_misc_documents : [
            {
                misc_doc_name: 'Income proof (FY 2022-2023)*',
                misc_doc_description: 'IT returns / income certificate/ pay slips',
                misc_doc_upload_timestamp:'',
                misc_doc_status:'Not Uploaded',
                misc_doc_note:'Not Approved. Please upload valid proof.',
                misc_doc_file_name:''
            },
            {
                misc_doc_name: 'Caste certificate',
                misc_doc_description: 'Applicable for OBC - (VJ/ NT/ SBC) ST SC',
                misc_doc_upload_timestamp:'',
                misc_doc_status:'Not Uploaded',
                misc_doc_note:'',
                misc_doc_file_name:''
            },
            // {
            //     misc_doc_name: 'Caste Validity Certificate (if Applicable)',
            //     misc_doc_description: 'only for SC & ST',
            //     misc_doc_upload_timestamp:'',
            //     misc_doc_status:'Not Uploaded',
            //     misc_doc_note:'',
            //     misc_doc_file_name:''
            // },
            {
                misc_doc_name: 'Bank Proof *',
                misc_doc_description: 'Passbook account details page or cancelled cheque',
                misc_doc_upload_timestamp:'',
                misc_doc_status:'Not Uploaded',
                misc_doc_note:'',
                misc_doc_file_name:''
            },
            {
                misc_doc_name: 'Voter id card *',
                misc_doc_description: 'Applicant’s Voter ID Card',
                misc_doc_upload_timestamp:'',
                misc_doc_status:'Not Uploaded',
                misc_doc_note:'',
                misc_doc_file_name:''
            },
            {
                misc_doc_name: 'Rent Agreement',
                misc_doc_description: 'Registered/ Notarised/ consent if living with family members',
                misc_doc_upload_timestamp:'',
                misc_doc_status:'Not Uploaded',
                misc_doc_note:'',
                misc_doc_file_name:''
            },
            {
                misc_doc_name: 'Electricity bill *',
                misc_doc_description: 'On the Current Address for this month',
                misc_doc_upload_timestamp:'',
                misc_doc_status:'Not Uploaded',
                misc_doc_note:'',
                misc_doc_file_name:''
            },
            {
                misc_doc_name: 'Domicile Certificate *',
                misc_doc_description: 'Proof of living in Maharashtra from last 15 years',
                misc_doc_upload_timestamp:'',
                misc_doc_status:'Not Uploaded',
                misc_doc_note:'',
                misc_doc_file_name:''
            },
            {
                misc_doc_name: 'BPL card (if applicable)',
                misc_doc_description: 'Please upload BPL card Documents',
                misc_doc_upload_timestamp:'',
                misc_doc_status:'Not Uploaded',
                misc_doc_note:'',
                misc_doc_file_name:''
            },
            {
                misc_doc_name: 'Disability card (if applicable)',
                misc_doc_description: 'Please upload Disability card Documents',
                misc_doc_upload_timestamp:'',
                misc_doc_status:'Not Uploaded',
                misc_doc_note:'',
                misc_doc_file_name:''
            },
            {
                misc_doc_name: 'Upload Additional Documents Here',
                misc_doc_description: "",
                misc_doc_upload_timestamp:'',
                misc_doc_status:'Not Uploaded',
                misc_doc_note:'',
                misc_doc_file_name:''
                
            }
        ]

};

export const ApplicationProcessStatus = {
    identificationFilled: false,
    addressAndHousingFilled: false,
    employmentAndFinancialFilled: false,
    BankDetailFilled: false,
    FamilyAndDependencyFilled: false,
    specialCasesFilled: false,
    onlinePaymentFilled: false,
    uploadDocumentsFilled: false,
}

