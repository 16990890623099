import React, { useEffect, useState } from "react";
import ApiDataService from "../services/api-service";
import { useHistory } from "react-router-dom";
import Spinner from "./Spinner";
import {
  fetchUserProfile,
  selectUserProfile,
} from "../redux/slices/userProfileSlice";
import { useDispatch, useSelector } from "react-redux";

const LoginForm = (props) => {
  const dispatch = useDispatch();
  let userProfile = useSelector(selectUserProfile);
  const [mobileNo, setMobileNo] = useState("");
  const [otp, setOtp] = useState("");
  const [loginResponse, setLoginResponse] = useState(null);
  const [validatedMsg, setValidatedMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(30);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  useEffect(() => {
    if (userProfile?.status === "succeeded") {
      localStorage.setItem("mobileNo", mobileNo)
      // history.push(`/dashboard/identification`);
      history.push(`/dashboard/home`);

    }
  }, [userProfile?.status]);

  const handleSubmit = (evt) => {
    const form = evt.currentTarget;
    let mobReg = /^[6-9]\d{9}$/

    if (!mobReg.test(mobileNo)) {
      setValidatedMsg("Please enter a valid mobile number");
      return false;
    }
    if (mobileNo) {
      setMinutes(1);
      setSeconds(0);
      setLoading(true);
      ApiDataService.get(`session_request?mobile_number=${mobileNo}`)
        .then((response) => {
          setLoading(false);
          if (response.status === 200) {
            setValidatedMsg("")
            setLoginResponse(response.data);
          }
        })
        .catch((e) => {
          setLoading(false);
          setValidatedMsg("Failed to send OTP");
        });
    } else {
      setValidatedMsg("Please enter a valid 10 digit mobile number");
    }
  };
  const verifyOTP = async () => {
    setLoading(true);
    if (otp) {
      dispatch(fetchUserProfile({ mobileNo, otp }));
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  return (
    <div className="row">
      <div className="col-md-9 mx-auto">
        <div className="form-container">
          <div className="form-input">
            <div className="form-group">
              <label className="input-lbl">Mobile no.</label>
              <input
                type="text"
                placeholder="Enter the mobile no. that is linked to your aadhaar"
                className="leadpage-input"
                id="mobileNo"
                name="mobileNo"
                value={mobileNo}
                maxLength={10}
                onChange={(e) => setMobileNo(e.target.value)}
                required
              />
              {validatedMsg ? <p style={{ color: "#ff6550" }}>{validatedMsg}</p> : null}
            </div>
            {loginResponse ? (
              <div className="form-group">
                <label className="input-lbl">One time OTP</label>
                <input
                  type="text"
                  placeholder="Enter OTP"
                  className="leadpage-input"
                  id="otp"
                  name="otp"
                  value={otp}
                  maxLength={10}
                  onChange={(e) => setOtp(e.target.value)}
                // disabled={loginResponse.new_user < 0}
                />

                <div className="countdown-text">
                  {seconds > 0 || minutes > 0 ? (
                    <p>
                      Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:
                      {seconds < 10 ? `0${seconds}` : seconds}
                    </p>
                  ) : (
                    <p>
                      Didn't recieve OTP?{" "}
                      <button
                        className="btn btn-submit pull-right"
                        disabled={seconds > 0 || minutes > 0}
                        style={{
                          padding: 0,
                          color:
                            seconds > 0 || minutes > 0 ? "#4640DE" : "#4640DE",
                        }}
                        onClick={handleSubmit}
                      >
                        Resend OTP
                      </button>
                    </p>
                  )}
                </div>
              </div>
            ) : null}

            <div className="submit-btn-container">
              {loading || userProfile.status === "loading" ? (
                <Spinner />
              ) : loginResponse && loginResponse.new_user > -1 ? (
                <button
                  className="btn btn-submit form-control"
                  onClick={verifyOTP}
                >
                  Verify OTP
                </button>
              ) : (
                <button
                  className="btn btn-submit form-control"
                  onClick={handleSubmit}
                >
                  Login
                </button>
              )}
            </div>
            <div className="row" style={{ marginBottom: -40 }}>
              <div className="col-sm-12 text-center">
                <img src="/assets/pmay_log.jpg" className="pradhaimg" alt="pradhan mantri awas yojna-gram Banner" />
                <img src="/assets/pcmc_logo.jpg" className="pcmcimg" alt="pcmc Banner" />
                
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row" style={{ marginTop: 30 }}>
        <div className="col-2 text-right">
          <a href="https://youtu.be/sa9gp4CN_Ho" target="_blank"><img src="/assets/youtube.svg" className="youtubeimg" alt="youtube" /></a>
        </div>
        <div className="col-10">
          <p className="ptext"><a href="https://youtu.be/sa9gp4CN_Ho" target="_blank" style={{ color: "#202430" }}> Click here for a video tutorial on How to apply. For website to work correctly, please make sure you are using an updated version of Chrome or Firefox.</a></p>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
