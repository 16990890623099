import React, { useState, useEffect, useRef } from "react";
import FamilyMeber from './FamilyMeber'
import styled from "styled-components";
import ApiDataService from "../../services/api-service";
import { updateRequestStatus } from "../../redux/slices/userRequestSlice";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { selectUserPayload2, selectUserPayload1 } from "../../redux/slices/userRequestSlice";
import { FilterPayload1Items, FilterPayload2Items } from "../../services/PayloadAPIService";
import { clearUpdateReqState, fetchUserRequest, selectUpdateUserReqStatus } from "../../redux/slices/userRequestSlice";
import { postPayload2Data, validateAadhaarNo, FilterPayload2FileUpload, } from "../../services/PayloadAPIService";

const defaultMember = {
    member_name: '',
    member_relation: '',
    member_dob: '',
    member_gender: '',
    member_aadhaar: '',
    member_other_id_type: '',
    member_other_id_no: '',
    member_document_file_name:''

}
const FamilyMemberMain = ({ userId, onNextTabHandler }) => {
    const dispatch = useDispatch();
    const selectUpdateUserReqStatus = useSelector((state) => state.userRequest?.updateStatusRequest);
    const [inputFields, setInputFields] = useState([{
        member_name: '',
        member_relation: '',
        member_dob: '',
        member_gender: '',
        member_aadhaar: '',
        member_other_id_type: '',
        member_other_id_no: '',
        member_document_file_name:''
    
    }])
    const [memberList, setMemberList] = useState([]);
    const history = useHistory();
    let [count, setNum] = useState(0);
    const selectUserPayload2Data = useSelector(selectUserPayload2);
    const selectUserPayload1Data = useSelector(selectUserPayload1);
    const [uploading, setUploading] = useState(false);
    const [aadhaarVerification, setAadhaarVerification] = useState(false);
    const [aadhaarloader, setAadhaarloader] = useState(false);

    useEffect(() => {
        
        if (selectUserPayload2Data?.family_member_list) {
            setNum(selectUserPayload2Data?.family_member_list?.length)
             if (selectUserPayload2Data?.family_member_list?.length > 0){
                let newArray = [];
                selectUserPayload2Data.family_member_list.forEach(element => {
                    newArray.push({
                        member_name: element?.member_name,
                        member_relation: element?.member_relation,
                        member_dob: element?.member_dob,
                        member_gender: element?.member_gender,
                        member_aadhaar: element?.member_aadhaar,
                        member_other_id_type: element?.member_other_id_type,
                        member_other_id_no: element?.member_other_id_no,
                        member_document_file_name: element?.member_document_file_name
                    })
                });
                setInputFields(newArray)
             }
                
        } else {
            incNum()
        }
    }, [selectUserPayload2Data?.family_member_list]);
    

    const addForm = () => {
        let newfield = { ...defaultMember }
        setInputFields([...inputFields, newfield])
    }

    const formElements = (count) => {
        if (
            count > 0
        ) {
            const list = [];
            for (let i = 0; i < count; i++) {
                list.push({ ...defaultMember });
            }
            setMemberList(list)
        }
    }
    let incNum = () => {
        if (count < 10) {
            addForm();
            // formElements(Number(count) + 1)
            setNum(Number(count) + 1);
        }
    };
    let decNum = () => {
        if (count > 0) {
            let data = [...inputFields];
            data.splice(inputFields.length - 1, 1)
            setInputFields(data)
            setNum(count - 1);
        }
    }
    let handleChange = (e) => {
        setNum(e.target.value);
        formElements(e.target.value)
    }

    const handleFormChange = (index, e, fieldName) => {
        const data = [...inputFields];
        const { name, value } = e.target;
        data[index][name] = value;
        setInputFields(data);
    }

    const removeMemberHandler = (index) => {
        let data = [...inputFields];
        setNum(inputFields?.length-1)
        data.splice(index, 1)
        setInputFields(data);
    }


    const aadharVerificationHandler = (index, event, data, fieldName) => {
        if (data.member_aadhaar) {
            setAadhaarloader(true);
            validateAadhaarNo(data.member_aadhaar)
                .then((response) => {
                    if (response?.status === 200) {
                        setAadhaarVerification(true);
                        setAadhaarloader(false);

                        let data = [...inputFields];
                        data[index][fieldName] = true;
                        setInputFields(data);
                    }
                })
                .catch((err) => {
                    setAadhaarloader(false);
                    setAadhaarVerification(false);
                    let data = [...inputFields];
                    data[index][fieldName] = false;
                    setInputFields(data);
                });
        }

    }


    const manageFamilyDetailHandler = async () => {
        console.log("inputFields",inputFields)
        if (inputFields && inputFields.length > 0) {
            setUploading(true);

            let input = {
                family_member_count: inputFields?.length || 0,
                family_member_list: inputFields
            };
            let payload = {
                user_id: userId,
                payload1: FilterPayload1Items('FamilyAndDependencyFilled', 'lastTab', selectUserPayload1Data),
                payload2: FilterPayload2Items(input, selectUserPayload2Data)
            };

            let response = await postPayload2Data(payload);
            if (response.status == 200) {
                dispatch(fetchUserRequest({ userId }));
                history.push(`/dashboard/special-case`);
            }
            setUploading(false);


        }
    };

    return (
        <>
            {/* {errorMsg ? <div className="row">
                <Modal show="true" size="md" onHide={handleClose}>
                    <Modal.Header>
                        <Modal.Title >
                            Alert
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {errorMsg}
                    </Modal.Body>
                </Modal>
            </div> : null} */}
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-3 ">
                        <h5 className="headingTitle">Family Information</h5>
                        <p className="contentdata">Please enter the details of all family members in this section</p>
                    </div>
                    <div className="col-sm-6 offset-sm-1">
                        <label for="headname">How many members in the family apart from the head of family?</label>
                        <div className="row">
                            <div className="col-sm-5">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <button className="btn btn-outline-primary" type="button" onClick={decNum}>-</button>
                                    </div>
                                    <input type="text" className="form-control text-center" disabled value={count} onChange={handleChange} />
                                    <div className="input-group-prepend">
                                        <button className="btn btn-outline-primary" type="button" onClick={incNum}>+</button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div >
                {
                    inputFields.length > 0 && inputFields.map((item, index) => {
                        return (<FamilyMeber key={index} item={item} counter={index}
                            handleFormChange={handleFormChange}
                            removeMemberHandler={removeMemberHandler}
                            aadharVerificationHandler={aadharVerificationHandler}
                            aadhaarVerification={aadhaarVerification}
                            aadhaarloader={aadhaarloader}
                        />)
                    })
                }
            </div>
            <div className="row">
                <div className="col">
                    <button className="btn btn-primary mt-5" type="button" onClick={() => manageFamilyDetailHandler()}>
                        Save & Proceed
                    </button>
                </div>
            </div>
        </>
    )
}

export default FamilyMemberMain

