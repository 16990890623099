import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";

import ApiDataService from "../../services/api-service";
import { useDispatch, useSelector } from "react-redux";
import { FilterPayload1Items, FilterPayload2Items, postPayload2Data } from "../../services/PayloadAPIService";
import { useHistory } from "react-router-dom";
import { clearUpdateReqState, updateRequestStatus, selectUpdateUserReqStatus, selectUserPayload2, fetchUserRequest, selectUserRequestData } from "../../redux/slices/userRequestSlice";
import Spinner from "../Spinner";
import { selectUserDropdownData } from "../../redux/slices/MasterDropdownSlice";
import { Utility } from "../../utils/utility";

const HeadOfFamily = ({ userId, onNextTabHandler }) => {
    const [familyHeadName, setFamilyHeadName] = useState('');
    const [gender, setGender] = useState('');
    const [selectedgender, setSelectedGender] = useState('');
    const [birthDate, setBirthDate] = useState('');
    const [maritalStatus, setMaritalStatus] = useState('');
    const [selectedmaritalStatus, setSelectedMaritalStatus] = useState('');
    const [religion, setReligion] = useState('');
    const [selectedreligion, setSelectedReligion] = useState('');
    const [caste, setCaste] = useState('');
    const [selectedcaste, setSelectedCaste] = useState('');
    const [fatherName, setFatherName] = useState('');
    const [validated, setValidated] = useState(false);

    const history = useHistory();
    const [uploading, setUploading] = useState(false);
    const selectUserPayload2Data = useSelector(selectUserPayload2);
    const dispatch = useDispatch();
    const selectUpdateUserReqStatus = useSelector((state) => state.userRequest?.updateStatusRequest);
    const masterDropdownList = useSelector(selectUserDropdownData);

    useEffect(() => {
        if (masterDropdownList?.config_id) {
            setGender(masterDropdownList?.config_id?.gender);
            setMaritalStatus(masterDropdownList?.config_id?.marital_status);
            setReligion(masterDropdownList?.config_id?.religion);
            setCaste(masterDropdownList?.config_id?.caste);
        }
    }, [masterDropdownList?.config_id]);

    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
        } else {
            manageFamilyDetailHandler();
            event.preventDefault();
            event.stopPropagation();
        }
    };

    useEffect(() => {
        if (selectUserPayload2Data) {
            setFamilyHeadName(selectUserPayload2Data?.applicant_name);
            setSelectedGender(selectUserPayload2Data?.applicant_gender)
            setBirthDate(selectUserPayload2Data?.applicant_dob)
            setSelectedMaritalStatus(selectUserPayload2Data?.applicant_marital_status)
            setSelectedReligion(selectUserPayload2Data?.applicant_religion)
            setSelectedCaste(selectUserPayload2Data?.applicant_caste)
            setFatherName(selectUserPayload2Data?.applicant_father_name)
        }
    }, [selectUserPayload2Data]);

    const manageFamilyDetailHandler = async () => {
        setUploading(true);
        let input = {
            applicant_name: familyHeadName,
            applicant_gender: selectedgender,
            applicant_dob: birthDate,
            applicant_marital_status: selectedmaritalStatus,
            applicant_religion: selectedreligion,
            applicant_caste: selectedcaste,
            applicant_father_name: fatherName
        };

        let payload = {
            user_id: userId,
            payload1: FilterPayload1Items('FamilyAndDependencyFilled', 'tab1'),
            payload2: FilterPayload2Items(input, selectUserPayload2Data)
        };

        let response = await postPayload2Data(payload);
        if (response.status == 200) {
            dispatch(fetchUserRequest({ userId }));
            onNextTabHandler("uploadPhoto");
        }
        setUploading(false);
    };

    return (
        <div className="container-fluid">
            {selectUpdateUserReqStatus?.state === 'loading' ? <Spinner /> :
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-sm-3 ">
                            <h5 className="headingTitle">Applicant</h5>
                            <p className="contentdata">Please enter the details of the applicant.
                            </p>
                        </div>
                        <div className="col-sm-6 offset-sm-1">
                            <Form.Group className="form-group" controlId="familyHeadName" >
                                <Form.Label>Name of the Applicant</Form.Label>
                                <Form.Control
                                    className="form-control"
                                    type="text"
                                    placeholder="Enter Name of the Applicant"
                                    value={familyHeadName}
                                    onChange={(e) => setFamilyHeadName(e.target.value)}
                                    required
                                ></Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    Please provide Name of the Applicant.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="form-group" controlId="selectedgender">
                                <Form.Label>Gender</Form.Label>
                                <Form.Control as='select' className="form-control" value={selectedgender}
                                    onChange={(e) => setSelectedGender(e.target.value)} required>
                                    <option value=''>Select a Gender:</option>
                                    {
                                        gender && gender.map((data, key) => (
                                            <option key={key} value={data}>{data}  </option>
                                        ))}
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    Please Select Gender.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="form-group" controlId="birthDate" >
                                <Form.Label>Date of Birth</Form.Label>
                                <Form.Control
                                    className="form-control"
                                    type="date"
                                    placeholder="Enter Date of Birth"
                                    value={birthDate}
                                    onChange={(e) => setBirthDate(e.target.value)}
                                    max={new Date().toISOString().slice(0, 10)}
                                    required
                                ></Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    Please provide Date of Birth.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="form-group" controlId="selectedmaritalStatus">
                                <Form.Label>Marital Status</Form.Label>
                                <Form.Control as='select' className="form-control" value={selectedmaritalStatus}
                                    onChange={(e) => setSelectedMaritalStatus(e.target.value)} required>
                                    <option value=''>Select a Marital Status:</option>
                                    {
                                        maritalStatus && maritalStatus.map((data, key) => (
                                            <option key={key} value={data}>{data}  </option>
                                        ))}
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    Please Select Marital Status.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <div className="row">
                                <div className="col">
                                    <Form.Group className="form-group" controlId="selectedreligion">
                                        <Form.Label>Religion</Form.Label>
                                        <Form.Control as='select' className="form-control" value={selectedreligion}
                                            onChange={(e) => setSelectedReligion(e.target.value)} required>
                                            <option value=''>Select a Religion:</option>
                                            {
                                                religion && religion.map((data, key) => (
                                                    <option key={key} value={data}>{data}  </option>
                                                ))}
                                        </Form.Control>
                                        <Form.Control.Feedback type="invalid">
                                            Please Select Religion.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <div className="col">
                                    <Form.Group className="form-group" controlId="selectedcaste">
                                        <Form.Label>Caste</Form.Label>
                                        <Form.Control as='select' className="form-control" value={selectedcaste}
                                            onChange={(e) => setSelectedCaste(e.target.value)} required>
                                            <option value=''>Select a Caste:</option>
                                            {
                                                caste && caste.map((data, key) => (
                                                    <option key={key} value={data}>{data}  </option>
                                                ))}
                                        </Form.Control>
                                        <Form.Control.Feedback type="invalid">
                                            Please Select Caste.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                            </div>
                            <Form.Group className="form-group" controlId="fatherName" >
                                <Form.Label>Father’s Name</Form.Label>
                                <Form.Control
                                    className="form-control"
                                    type="text"
                                    placeholder="Enter Father Name"
                                    value={fatherName}
                                    maxLength={30}
                                    isInvalid={
                                        fatherName && !Utility.validateName(fatherName)
                                    }
                                    onChange={(e) => setFatherName(e.target.value)}
                                    required
                                ></Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    {(fatherName && !Utility.validateName(fatherName)) ? 'Please provide Father’s Name in Letter.' : ' Please provide Father’s Name.'}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </div>
                    </div>
                    <hr className="mt-5" />
                    <div className="row">
                        <div className="col-sm-4 offset-sm-4">
                            <button className="btn btn-primary"  disabled={!Utility.validateName(fatherName)} type="submit" >Save & Proceed</button>
                        </div>
                    </div>
                </Form>}
        </div>
    )
}

export default HeadOfFamily