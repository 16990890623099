import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Button } from "react-bootstrap";

import {
  FilterPayload1Items,
  FilterPayload2Items,
  FilterPayload2FileUpload,
} from "../../services/PayloadAPIService";
import { useHistory } from "react-router-dom";
import {
  updateRequestStatus,
  selectUpdateUserReqStatus,
  selectUserPayload1,
  selectUserPayload2,
  fetchUserRequest,
  selectUserRequestData,
} from "../../redux/slices/userRequestSlice";
import Spinner from "../Spinner";
import styled from "styled-components";
import { selectUserProfile } from "../../redux/slices/userProfileSlice.js";
import { UserModalData } from "../../services/PayloadModalData.js";
import Document from "./Document.js";
import { UPLOAD_DOCUMENT_STATUS } from "../../constants.js";
import { postPayload2Data } from "../../services/PayloadAPIService";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";

import { useReactToPrint } from "react-to-print";

import PropTypes from "prop-types";
import PrintUserDetails from "./PrintUserDetails.js";
import moment from "moment";

const UploadDocuments = ({ getApplicationIdHandler }) => {
  const [handleDataPrint, setHandleDataPrint] = useState(false);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const dispatch = useDispatch();
  const userProfile = useSelector(selectUserProfile);
  const selectUserRequest = useSelector(selectUserRequestData);
  const selectUserPayload1Data = useSelector(selectUserPayload1);
  const selectUserPayload2Data = useSelector(selectUserPayload2);
  const [userId, setUserId] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [cardCount, setCardCount] = useState({});
  const [docList, setDocList] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [show, setShow] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const handleClose = () => {
    setShow(false);
    setErrorMsg("");
  };
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (localStorage.getItem("userId")) {
      setUserId(localStorage.getItem("userId"));
      setMobileNumber(localStorage.getItem("mobileNo"));
    }
  }, []);

  useEffect(() => {
    if (errorMsg) {
      setTimeout(() => {
        setErrorMsg("");
      }, 3000);
    }
  }, [errorMsg]);

  useEffect(() => {
    getApplicationIdHandler(selectUserRequest?.data?.application_id);
  }, [selectUserRequest?.data?.application_id]);

  useEffect(() => {
    if (userId) dispatch(fetchUserRequest({ userId }));
  }, [userId]);

  useEffect(() => {
    if (selectUserPayload2Data?.other_misc_documents?.length > 0) {
      let newArray = [];
      selectUserPayload2Data?.other_misc_documents.forEach((element) => {
        newArray.push({
          misc_doc_status: element?.misc_doc_status,
          misc_doc_description: element?.misc_doc_description,
          misc_doc_name: element?.misc_doc_name,
          misc_doc_file_name: element?.misc_doc_file_name,
          misc_doc_upload_timestamp: element?.misc_doc_upload_timestamp,
          misc_doc_note: element?.misc_doc_note,
        });
      });
      setDocList(newArray);
      let countObj = {
        approved: 0,
        rejected: 0,
        notUploaded: 0,
        uploaded: 0,
      };
      selectUserPayload2Data?.other_misc_documents.forEach((obj) => {
        if (obj.status === UPLOAD_DOCUMENT_STATUS.approved) {
          countObj.approved = countObj.approved + 1;
        } else if (obj.status === UPLOAD_DOCUMENT_STATUS.rejected) {
          countObj.rejected = countObj.rejected + 1;
        } else if (obj.status === UPLOAD_DOCUMENT_STATUS.notUploaded) {
          countObj.notUploaded = countObj.notUploaded + 1;
        }
      });
      countObj.uploaded =
        Number(selectUserPayload2Data?.other_misc_documents.length) -
        Number(countObj.notUploaded);
      setCardCount(countObj);
    } else {
      setDocList(UserModalData.other_misc_documents);
    }
  }, [selectUserPayload2Data?.other_misc_documents]);

  const handleFileUploadChange = (response, index, otherDocument) => {
    manageUserHandler(response, index, otherDocument);
  };

  const errorMsgHandler = (errorMsg) => {
    setErrorMsg(errorMsg);
  };

  const manageUserHandler = async (res, index, otherDocument) => {
    setUploading(true);
    setErrorMsg("");
    // let finalDocList = selectUserPayload2Data?.uploadDocuments?.uploadDocumentsList;
    let finalDocList = docList;
    if (otherDocument && finalDocList && finalDocList.length > 0) {
      let lastDoc = finalDocList[index];
      // finalDocList.pop();
      // finalDocList.push({
      //     misc_doc_status:'Not Uploaded',
      //     misc_doc_description : "Additional Document", //misisng
      //     misc_doc_name: otherDocument,
      //     misc_doc_file_name: "",
      //     misc_doc_upload_timestamp: "",
      //     misc_doc_note:''
      // })
    }

    let input = FilterPayload2FileUpload(
      res.filename,
      index,
      finalDocList,
      otherDocument
    );

    if (otherDocument) {
      input.push({
        misc_doc_name: "Upload Additional Documents Here",
        misc_doc_description: "",
        misc_doc_upload_timestamp: "",
        misc_doc_status: "Not Uploaded",
        misc_doc_note: "",
        misc_doc_file_name: "",
      });
    }
    let newpayload = {
      other_misc_documents: input,
    };
    let payload = {
      user_id: userId,
      // payload2: FilterPayload2Items(input,"uploadDocuments", "uploadDocumentsList", selectUserPayload2Data)
      payload2: FilterPayload2Items(newpayload, selectUserPayload2Data),
    };

    let response = await postPayload2Data(payload);
    if (response.status === 200) {
      dispatch(fetchUserRequest({ userId }));
      //openSnackbar("File uploaded successfully.");
      setErrorMsg("File uploaded successfully.");
    }

    setUploading(false);
  };

  const managePrintHandler = () => {
    setHandleDataPrint(true);
    handlePrint();
    handleClose(false);
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col ">
            <h5
              className="mainTitle"
              style={{ marginTop: 20, marginBottom: 30 }}
            >
              Upload Documents
            </h5>
          </div>
        </div>
        {errorMsg ? (
          <div className="row">
            <Modal show={true} size="md" onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Alert</Modal.Title>
              </Modal.Header>
              <Modal.Body>{errorMsg}</Modal.Body>
            </Modal>
          </div>
        ) : null}
        {/* <div className="row">
                    <div className="col-md-4">
                        <div
                            className="card"
                            style={{ backgroundColor: "#4640de", color: "#fff" }}
                        >
                            <div className="card-body" style={{ padding: 10 }}>
                                <div className="count-card" style={{ display: "flex" }}>
                                    <div style={{ fontSize: 30, marginRight: 20 }}>
                                        {cardCount.uploaded ? cardCount.uploaded : 0}/
                                        {docList && docList?.length}
                                    </div>
                                    <div
                                        style={{
                                            fontWeight: 400,
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        Uploaded
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div
                            className="card"
                            style={{ backgroundColor: "#56cdad", color: "#fff" }}
                        >
                            <div className="card-body" style={{ padding: 10 }}>
                                <div className="count-card" style={{ display: "flex" }}>
                                    <div style={{ fontSize: 30, marginRight: 20 }}>
                                        {cardCount.rejected ? cardCount.rejected : 0}
                                    </div>
                                    <div
                                        style={{
                                            fontWeight: 400,
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        Rejected
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div
                            className="card"
                            style={{ backgroundColor: "#26a4ff", color: "#fff" }}
                        >
                            <div className="card-body" style={{ padding: 10 }}>
                                <div className="count-card" style={{ display: "flex" }}>
                                    <div style={{ fontSize: 30, marginRight: 20 }}>
                                        {cardCount.approved ? cardCount.approved : 0 }
                                    </div>
                                    <div
                                        style={{
                                            fontWeight: 400,
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        Approved
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
        <div className="row">
          <div className="col">
            <p style={{ color: "#4640DE", marginBottom: 0, marginTop: 10 }}>
              * Only these type of files are allowed to be uploaded png, jpg,
              jpeg, gif, pdf, tiff, bmp, tif, raw, webp
            </p>
            <p style={{ color: "#4640DE", marginBottom: 20, marginTop: 10 }}>
              * Maximum size allowed for each document is 3 MB
            </p>
          </div>
        </div>
        <table
          border="1"
          cellpadding="5"
          cellspacing="0"
          style={{ marginTop: 20 }}
        >
          <thead>
            <tr>
              <th>अ.क्र.</th>
              <th>काम</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>१</td>
              <td>
                उत्पन्न दाखला, तहसिलदार पिंपरी चिंचवड / हवेली / मुळशी यांचे
                स्वाक्षरीने (सन २०२३-२४ आर्थिक वर्ष) किंवा १ वर्षाचा इनकम टॅक्स
                रिटर्न (ITR) किंवा फॉर्म १६/१६अ (सन २०२३-२४ आर्थिक वर्ष)
              </td>
            </tr>
            <tr>
              <td>२</td>
              <td>
                जात प्रमाणपत्र (अनुसूचित जाती, जमाती, वि.जा.भ.ज., इतर
                मागासवर्गीय) (फक्त अर्जदाराचे, कुटुंबातील इतर सदस्यांचे
                प्रमाणपत्र ग्राह्य धरले जाणार नाही) - फक्त महाराष्ट्रातील
                रहिवासी
              </td>
            </tr>
            <tr>
              <td>३</td>
              <td>जात वैधता प्रमाणपत्र - फक्त अर्जदाराचे (उपलब्ध असल्यास)</td>
            </tr>
            <tr>
              <td>४</td>
              <td>
                आधार कार्ड (अर्जदार व कुटुंबातील सर्व सदस्यांचे) पिंपरी चिंचवड
                शहरातील
              </td>
            </tr>
            <tr>
              <td>५</td>
              <td>पॅनकार्ड (अर्जदार व सह अर्जदार)</td>
            </tr>
            <tr>
              <td>६</td>
              <td>
                बँक पासबुक (अर्जदार) पासबुक खाते तपशिल पृष्ठ व रद्द केलेला चेक
              </td>
            </tr>
            <tr>
              <td>७</td>
              <td>मतदान ओळखपत्र (अर्जदार) पिंपरी चिंचवड शहरातील</td>
            </tr>
            <tr>
              <td>८</td>
              <td>
                भाडे करार (नोंदणीकृत / नोटरी – किमान र.रु.५००/- च्या स्टॅम्प
                पेपरवर) संमतीपत्र (नातेवाईकांकडे राहत असल्यास त्यांचे किमान
                र.रु. १०० च्या स्टॅम्प पेपरवरील संमतीपत्र) पिंपरी चिंचवड शहरातील
              </td>
            </tr>
            <tr>
              <td>९</td>
              <td>
                विज बिल (चालु महिन्याचे राहत्या पत्त्यावरील) पिंपरी चिंचवड
                शहरातील
              </td>
            </tr>
            <tr>
              <td>१०</td>
              <td>
                अधिवास प्रमाणपत्र (डोमिसाईल दाखला) – फक्त महाराष्ट्रातील रहिवासी
              </td>
            </tr>
            <tr>
              <td>११</td>
              <td>दिव्यांग प्रमाणपत्र (आवश्यक असल्यास)</td>
            </tr>
          </tbody>
        </table>
        <div className="row">
          <div className="col">
            <TableCard className="card">
              <div className="card-header">Document List</div>
              <ul className="list-group list-group-flush">
                {docList &&
                  docList.length > 0 &&
                  docList.map((item, index) => {
                    return (
                      <Document
                        key={index}
                        item={item}
                        index={index}
                        handleFileUploadChange={handleFileUploadChange}
                        errorMsgHandler={errorMsgHandler}
                      />
                    );
                  })}
              </ul>
            </TableCard>
            <div className=" text-right">
              <button
                className="btn btn-primary mt-4 mb-5"
                onClick={handleShow}
                type="button"
              >
                Submit Application
              </button>
            </div>
            <div id="t_c">
              <p
                style={{
                  color: "#4640DE",
                  marginBottom: 0,
                  marginTop: 10,
                  fontSize: 20,
                }}
              >
                सदर प्रकल्पांकरिता सर्वसाधारण अटी व शर्ती खालीलप्रमाणे असणार आहे
                :
              </p>
              <p style={{ marginTop: 20 }}>
                १. सदर प्रकल्पांकरिता नागरिकांकडून ऑनलाईन अर्ज मागविण्यात येत
                आहेत.
              </p>
              <p style={{ marginLeft: 30 }}>
                अ. सदर प्रकल्पांसाठी पिंपरी चिंचवड महानगरपालिका हद्दीतील रहिवाशी
                नागरिक अर्ज करु शकतात.<br></br>
                ब. आर्थिकदृष्ट्या दुर्बल घटकाअंतर्गत येणाऱ्या अर्जदारांचा
                कुंटुंबाचे वार्षिक उत्पन्न र.रु.३,००,०००/- (अक्षरी – तीन लाख
                रुपये) पर्यंत असावे.<br></br>
                क. अर्जदार किंवा त्याच्या कुटुंबातील कोणत्याही व्यक्तींचा नावे
                भारतात कुठेही घर अथवा मिळकत नसावी. <br></br>
                ड. पिंपरी चिंचवड महानगरपालिकेमार्फत यापुर्वी चऱ्होली, रावेत व
                बोऱ्हाडेवाडी येथील प्रकल्पांसाठी अर्ज केलेल्या परंतु, सदनिका
                प्राप्त न झालेल्या व प्रतिक्षा यादीमधील नागरिक नव्याने अर्ज करु
                शकतात.
              </p>
              <p style={{ marginTop: 20 }}>
                २. सदर प्रकल्पांतील सदनिकांचे आरक्षणनिहाय माहिती खालीलप्रमाणे
                आहे.
              </p>
              <table border="1" cellpadding="5" cellspacing="0">
                <thead>
                  <tr>
                    <th>प्रकल्पाचे नाव</th>
                    <th>सर्वसाधारण (Open) ५०%</th>
                    <th>अनुसूचित जाती (SC) १३%</th>
                    <th>अनुसूचित जमाती (ST) ७%</th>
                    <th>इतर मागास प्रवर्ग (OBC) ३०%</th>
                    <th>एकूण</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>डुडुळगांव</td>
                    <td>५६५</td>
                    <td>१४७</td>
                    <td>७९</td>
                    <td>३३९</td>
                    <td>११३०</td>
                  </tr>
                  <tr>
                    <td>दिव्यांग प्रवर्ग (PH)*</td>
                    <td>३०</td>
                    <td>८</td>
                    <td>४</td>
                    <td>१८</td>
                    <td>६०</td>
                  </tr>
                  <tr>
                    <td>एकूण</td>
                    <td>५९५</td>
                    <td>१५५</td>
                    <td>८३</td>
                    <td>३५७</td>
                    <td>११९०</td>
                  </tr>
                </tbody>
              </table>
              <p>* ५% दिव्यांग समांतर आरक्षण</p>
              <p>
                ३. सदर प्रकल्पांसाठी ऑनलाईन अर्ज करणेस व अनामत रक्कम भरणेकामी
                नागरिकांना दि. ३०/०८/२०२४ ते दि.३०/०९/२०२४ पर्यंत (१ महिना)
                कालावधी देण्यात येईल. सदर कालावधीतच अर्ज केलेल्या नागरिकांचे
                पात्रतेविषयक कार्यवाही पुर्ण करण्यात येईल.
              </p>
              <p>
                ४. अर्जदारांनी अर्जकरण्यासाठी{" "}
                <a href="http://pcmc.pmay.org">http://pcmc.pmay.org</a> या
                सांकेतीक स्थळावर जाऊन अर्ज भरण्याची प्रक्रिया करावी.{" "}
              </p>
              <p>
                ५. सदर नागरिकांकडून अर्जासोबत र.रु.१०,०००/- इतकी अनामत रक्कम
                घेण्यात येणार आहे. तसेच नोंदणी शुल्क र.रु.५००/- असे एकुण
                र.रु.१०,५००/- ऑनसाईन जमा करायचे आहे.{" "}
              </p>
              <p>
                ६. नागरीकांकडुन सर्वसाधारण कागदपत्रे उदा. आधारकार्ड, पॅनकार्ड व
                इतर (सर्व कुंटुंबाचे) Upload झाल्यानंतर त्यांना अनामत व नोंदणी
                शुल्क असे एकुण र.रु.१०,५००/- ही ऑनलाईन पध्दतीने भरावयाची आहे.
                सदर रक्कम यशस्वीरित्या भरल्यानंतर पुढील उर्वरीत कागदपत्रे Upload
                करावयाची आहे.{" "}
              </p>
              <p>
                ७. सोडतीमध्ये ११९० सदनिकांसाठी विजेता यादी असेल व प्रतीक्षा यादी
                १ असणार आहे.
              </p>
              <p>
                ८. सदर सोडतीमध्ये विजेता न झालेल्या व प्रतिक्षा यादीमध्ये नाव न
                आलेल्या नागरिकांची अनामत रक्कम अर्ज करताना नमुद केलेल्या
                खात्यावर ऑनलाईन पध्दतीने र.रु.१०,०००/- परत (Refund) करण्यात
                येणार आहे.{" "}
              </p>
              <p>
                ९. डुडुळगांव येथील प्रकल्पांकरिता अर्ज करणेकामी व सोडतीनंतर निवड
                झालेल्या नागरिकांना खालील तक्त्यामधील नमुद कागदपत्रे सादर करावे
                लागणार आहेत. सोडतीनंतर आवश्यक कागदपत्रे सादर न केल्यास त्यांचे
                नाव निवड यादीमधून वगळण्यात येईल.{" "}
              </p>
              <p style={{ marginTop: 10 }}>
                १०. प्रधानमंत्री आवास योजनेअंतर्गत विजेता यादीमध्ये नाव आलेल्या
                लाभार्थ्यांच्या नावे किंवा कुटुंबातील सदस्यांच्या नावे संपुर्ण
                भारतात पक्के घर नसावे. तसे आढळल्यास सदर लाभार्थ्याचे नाव विजेता
                यादीमधून वगळण्यात येईल.
              </p>
              <p>
                ११. सदर प्रकल्पांकरिता अर्ज करणारा नागरीक हा पिंपरी चिंचवड
                महानगरपालिकेच्या हद्दीतील रहिवासी असणे आवश्यक आहे.{" "}
              </p>
              <p>
                १२. प्रधानमंत्री आवास योजनेअंतर्गत अर्ज करतांना अर्जदार जर महिला
                असेल तर जात प्रमाणपत्र सुध्दा त्यांचेच सादर करावा लागणार आहे.
                कुटुंबातील इतर सदस्यांचे जात प्रमाणपत्र ग्राह्य धरले जाणार नाही.{" "}
              </p>
              <p>
                १३. प्रधानमंत्री आवास योजनेअंतर्गत अर्ज करताना अर्जदार महिलेनेजर
                लग्नाच्या अगोदर अर्ज केला असेल आणि दरम्यानच्या काळात त्यांचे
                लग्न झाले असेल तसेच अर्जदार महिलेने लग्नाच्या अगोदर अर्ज करताना
                जे नाव नमुद केले असेल तर नाव बदल झालेबाबत मुळ गेऐट व मुळ मॅरेज
                सर्टिफीकेट सादर करावे लागणार आहे.
              </p>
              <p>
                १४. प्रधानमंत्री आवास योजनेअंतर्गत अर्ज करताना ज्या नागरिकांनी
                SBC (विशेष मागास प्रवर्ग) प्रवर्गातून अर्ज केला असेल आणि त्यांचे
                नाव विजेता यादीमध्ये OBC (इतर मागास प्रवर्ग) प्रवर्गामध्ये निवड
                झाली असेल आणि जर सदर अर्जदार SBC चे जात प्रमाणपत्र सादर करत असेल
                तर तो अर्जदार ग्राह्य धरला जाणार आहे.{" "}
              </p>
              <p>
                १५. सदर प्रकल्पाकरिता ऑनलाईन अर्जामध्ये माहिती नमुद करताना
                नागरिकांकडून कोणतीही चूक (उदा. नाव, जात आरक्षण माहिती, उत्पन्न,
                दिव्यांग बाबत माहिती इ.) झाल्यास त्यास सर्वस्वी जबाबदार अर्जदार
                राहणार आहे.{" "}
              </p>
              <p>
                १६. लाभार्थी स्वहिश्श्याच्या १०% रक्कम सोडतीनंतर १५ दिवसांच्या
                आत भरल्यानंतरच सदनिका वाटपपत्र (Allotment Letter) देण्यात येईल.{" "}
              </p>
              <p>
                १७. तदनंतर उर्वरित ९०% रक्कम १ महिन्याच्या आत भरावयाचे आहे. तसेच
                सोडतीनंतर ४५ दिवसाच्या आतमध्ये संपूर्ण रक्कम भरणा करणे बंधनकारक
                राहील.
              </p>
              <p>
                १८. उर्वरित ९०% रक्कम भरणेकामी बँक लोन करुन घेणे ही सर्वस्वी
                लाभार्थ्याची जबाबदारी असून यासाठी पिंपरी चिंचवड महानगरपालिका
                कुठलीही हमी घेणार नाही.{" "}
              </p>
              <p>
                १९. लाभार्थ्यांना लोन करिता आवश्यक असलेले No Objection
                Certificate (NOC) देणेकामी बँकेमार्फत Loan Sanction Letter
                महानगरपालिकेस प्राप्त झाल्यानंतर लाभार्थ्यांस महानगरपालिकेमार्फत
                No Objection Certificate (NOC) देण्यात येईल.
              </p>
              <p>
                २०. जर लाभार्थ्यांचे लोन होणेकामी काही अडचणी असल्यास व लोन करिता
                आवश्यक असलेल्या Cibil Score च्या काही अडचण असल्यास त्यांनी
                परस्पर बँकेशी संपर्क साधावा. सदर कारणांमुळे लाभार्थ्यांचा लोन
                होण्यास काही अडचण आल्यास त्यास महानगरपालिका जबाबदार राहणार नाही.
                तसेच होम लोन किंवा स्वहिस्सा रक्कम मुदतीत न भरल्यास
                लाभार्थ्यांचा लाभ रद्द करण्यात येणार आहे.{" "}
              </p>
              <p>
                २१. सोडत दिनांकापासून १५ दिवसाच्या आत लाभधारकाने स्वहिश्श्यामधील
                १०% रक्कम न भरल्यास किंवा लाभधारकाने सदनिका Surrender केल्यास
                त्याचा लाभ रद्द करुन तो प्रतीक्षा यादी १ मधील प्राधान्य
                क्रमानुसार लाभधारकास देण्यात येईल.
              </p>
              <p>
                २२. तसेच आवश्यक कागदपत्रे (उदा. जातीचा दाखला), योजनेचे पात्रतेचे
                निकषाची पुर्तता न केल्यास तथा सदनिकेची रक्कम निर्धारित
                टप्प्यानुसार न भरल्यास लाभार्थ्यांचा लाभ रद्द झाल्यास अशा
                लाभार्थीच्या सदनिका प्रतीक्षा यादी क्र.१ मधील प्राधान्य
                क्रमानुसार आरक्षणानुसार वितरित करणेत येईल.
              </p>
              <p>
                २३. विजेता यादी व प्रतिक्षा यादी क्र.१ मधील लाभार्थ्यांनी
                स्वहिस्सा रक्कम न भरलेस सदर लाभार्थ्यांचा लाभ रद्द करुन प्रथम
                येणाऱ्यास प्रथम प्राधान्य या तत्वानुसार प्रधानमंत्री आवास
                योजनेअंतर्गतच्या मार्गदर्शक सूचनानुसार पात्र होणाऱ्या
                लाभार्थ्यांना सदनिका वितरित करणेत येतील.{" "}
              </p>
              <p>
                २४. जर लाभार्थ्यांने सदनिकेचा ताबा घेणेपर्यंत स्वहिस्सा रक्कम
                भरलेनंतर सदनिका घेणेस इच्छुक नसेल तर Cancellation Charges म्हणून
                स्वहिस्सा रकमेमधील १०% रक्कम वजावट करणेत येईल व उर्वरित रक्कम
                लाभार्थ्यास देणेत येईल.
              </p>
              <p>
                २५. प्रधानमंत्री आवास योजनेच्या वेळोवेळी जाहिर केलेल्या
                तरतुदीनुसार असल्याने १० वर्षांपर्यंत सदनिकेची विक्री करता येणार
                नाही. अगर भाडे पट्ट्याने देता येणार नाही. सदनिकेची अनधिकृत
                विक्री, हस्तांतरण झाल्याचे आढळून आल्यास लाभार्थ्यांविरुध्द
                कायदेशीर कारवाई केली जाईल व सदनिकेचा लाभ रद्द करण्यात येईल. तसेच
                १० वर्षांनंतर सदनिका विक्री करावायाचे झाल्यास त्यावेळच्या
                बाजारभावानुसार सदर सदनिकेच्या जमिनीच्या ५०% रक्कम पिंपरी चिंचवड
                महानगरपालिकेस देणे क्रमप्राप्त आहे.
              </p>
              <p>
                २६. या सोडतीसाठी सदनिकेचे आरक्षण तक्त्यामध्ये नमुद प्रमाणे
                सामाजिक आरक्षण राहिल व ५% दिव्यांग समांतर आरक्षण असेल.
              </p>
              <p>
                २७. सदर सदनिका वाटप नियमावली मध्ये बदल किंवा वाढ करण्याचे अधिकार
                हे मा.महापालिका आयुक्त यांना राहतील.{" "}
              </p>
            </div>

            <div
              style={{
                display: handleDataPrint ? "block" : "none",
                marginTop: 30,
                visibility: "hidden",
              }}
            >
              <div style={{ marginTop: 100 }} ref={componentRef}>
                <div className="row">
                  <div className="col-sm-11 mx-auto">
                    <div className="row">
                      <div className="col-sm-3 ">
                        <img
                          src="/assets/pcmc_logo.jpg"
                          className="pcmcimg"
                          alt="pcmc Banner"
                        />
                      </div>
                      <div className="col-sm-9 " style={{ marginTop: 70 }}>
                        <h3>Pimpri Chinchwad Municipal Corporation - 411018</h3>
                        <h3 style={{ fontWeight: 400 }}>
                          Pradhan Mantri Awas Yojana-Housing for All (Urban)
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-11 mx-auto">
                    <Table responsive="sm" striped="columns" bordered>
                      <tbody>
                        <tr>
                          <th>Online Application Form number</th>
                          <td>{selectUserRequest?.data?.application_id}</td>
                          <th>Aadhaar Number</th>
                          <td>
                            {selectUserPayload2Data?.identification_aadhaar_no}
                          </td>
                        </tr>
                        <tr>
                          <th>PAN Number</th>
                          <td>
                            {selectUserPayload2Data?.identification_pan_no}
                          </td>
                          <th>Applicant Name</th>
                          <td>
                            {
                              selectUserPayload2Data?.identification_name_as_per_aadhaar
                            }
                          </td>
                        </tr>
                        <tr>
                          <th>Mobile Number</th>
                          <td>{mobileNumber}</td>
                          <th>Current Address</th>
                          <td>
                            {selectUserPayload2Data?.current_address_house_no}
                            {", "}
                            {selectUserPayload2Data?.current_address_area}
                            {", "}
                            {selectUserPayload2Data?.current_address_district}
                          </td>
                        </tr>
                        <tr>
                          <th>Bank Account Number</th>
                          <td>{selectUserPayload2Data?.bank_account_no}</td>
                          <th>IFSC</th>
                          <td>{selectUserPayload2Data?.bank_ifsc_code}</td>
                        </tr>
                        <tr>
                          <th>Marital Status</th>
                          <td>
                            {selectUserPayload2Data?.applicant_marital_status}
                          </td>
                          <th>Gender</th>
                          <td>{selectUserPayload2Data?.applicant_gender}</td>
                        </tr>
                        <tr>
                          <th>Religion</th>
                          <td>{selectUserPayload2Data?.applicant_religion}</td>
                          <th>Caste</th>
                          <td>{selectUserPayload2Data?.applicant_caste}</td>
                        </tr>
                        <tr>
                          <th>Disability</th>
                          <td>
                            {selectUserPayload2Data?.special_case_is_disability ===
                            "true"
                              ? "Yes"
                              : "No"}
                          </td>
                          <th>Date & Time</th>
                          <td>
                            {moment(new Date()).format("DD-MM-YYYY, hh:mm A")}
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <strong>Declaration</strong>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="4">
                            <p>
                              I Mr / Mrs.{" "}
                              {
                                selectUserPayload2Data?.identification_name_as_per_aadhaar
                              }{" "}
                              , declare that there is no permanent house
                              anywhere in India in the name of me and my family
                              members and we have not benefited from any
                              government housing schemes till date. Also, my
                              monthly family Income is Rs.{" "}
                              {
                                selectUserPayload2Data?.employment_avg_monthly_income
                              }
                              /- i.e. (
                              {Number(
                                selectUserPayload2Data?.employment_avg_monthly_income
                              ) * 12}
                              /- per annum) which is not more than Rs.
                              3,00,000/- (Rupees Three Lakhs only). I hereby
                              declare that the information provided above is
                              true and correct to the best of my knowledge and
                              belief. If the information is found to be false, I
                              shall be liable for punishment as per the decision
                              taken by Pimpri Chinchwad Municipal Corporation
                              (Or any other legal action).
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* print page */}
        </div>
      </div>
      <Modal show={show} size="lg" onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "Green", fontSize: 20 }}>
            Thank You. The information you have submitted has been saved. It is
            safe to log out.
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Disclaimer - I hereby declare that neither I nor any of my family
            members possess a permanent house anywhere in India. Furthermore, we
            have not received any benefits from any government housing schemes
            up to the present date. Additionally, our family's annual income
            does not exceed Rs.3,00,000 (Rupees Three Lakhs only). I affirm that
            the aforementioned information provided by me is true and accurate
            to the best of my knowledge and belief. If any of the information is
            found to be false, I understand that I may be subject to punishment
            or the PMAY house benefit may be revoked, as per the decision made
            by the Pimpri Chinchwad Municipal Corporation or any other legal
            action deemed appropriate.
          </p>
          <p>
            अस्वीकरण - मी याद्वारे घोषित करते / करतो की माझ्याकडे किंवा माझ्या
            कुटुंबातील कोणत्याही सदस्याच्या नावे भारतात कुठेही कायमस्वरूपी घर
            नाही. शिवाय, आतापर्यंत आम्हाला कोणत्याही सरकारी गृहनिर्माण
            योजनांमधून कोणतेही लाभ मिळालेले नाहीत. याव्यतिरिक्त, आमच्या
            कुटुंबाचे वार्षिक उत्पन्न रु.3,00,000 (रुपये तीन लाख) पेक्षा जास्त
            नाही. मी प्रतिज्ञा करते / करतो की माझ्याद्वारे दिलेली माहिती सत्य
            आणि अचूक आहे. कोणतीही माहिती खोटी असल्याचे आढळल्यास, पिंपरी चिंचवड
            महानगरपालिकेमार्फत होणाऱ्या कोणत्याही कायदेशीर कारवाईस मी पात्र असेल
            किंवा PMAY हाऊस बेनिफिट रद्द केला जाऊ शकतो याची मला जाणीव आहे.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={managePrintHandler}>
            Print
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UploadDocuments;

const TableCard = styled.div`
            margin-top:20px;
            .card-body{
                padding: 0;
   }
            .card-title {
                padding: 15px;
            margin-bottom: 0;
   }
            .list-group {
                padding:15px;
   }
            .list-group .list-group-item{
                border - radius: 8px;
            border-bottom: 0;
            padding: 20px;
            border-bottom: 1px solid #ccc;
   }
   .list-group .list-group-item:last-child{
border-bottom: 0;
}
            .list-group .list-group-item:nth-child(odd) {
                background - color: #f8f8fd;
    }
            small{
                color: #7c8493;
   }
            .badge {
                border - radius: 80px;
            padding: 5px 10px;
    }
            .badge-success {
                background - color: #56cdad;
    }
            .badge-danger{
                background - color: #ff6550;
    }
            .badge-warning {
                background - color: #ffb836;
            color:#fff;
    }

            `;
