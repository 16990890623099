import { api } from "./http-common";
class ApiDataService {

  get(url) {
    return api.get(url);
  }

  post(url, data) {
    return api.post(url,data);
  }

  update(url, data) {
    return api.put(url, data);
  }

  delete(url) {
    return api.delete(url);
  }

  getS3(url,body) {
    return api.get(url,{
      params: body
    });
  }

}
export default new ApiDataService();