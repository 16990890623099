import { combineReducers } from 'redux';
import userProfileSlice from './slices/userProfileSlice';
import userRequestSlice from './slices/userRequestSlice';
import userWisePaymentSlice from './slices/userWisePaymentSlice';
import MasterDropdownDataSlice from './slices/MasterDropdownSlice'
const rootReducer = combineReducers({
  userProfile: userProfileSlice,
  userRequest: userRequestSlice,
  userWisePaymentSlice: userWisePaymentSlice,
  masterDropdownData: MasterDropdownDataSlice
});

export default rootReducer;
