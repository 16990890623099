import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import styled from "styled-components";
// import { useHistory } from "react-router-dom";
import { fetchUserRequest } from "../../redux/slices/userRequestSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  FilterPayload2Items,
} from "../../services/PayloadAPIService";
import {
  selectUserPayload1,
  selectUserPayload2,
} from "../../redux/slices/userRequestSlice";
import Spinner from "../Spinner";
import { postPayload2Data } from "../../services/PayloadAPIService";

const PaymentTerm = ({ userId, onNextTabHandler }) => {
  const dispatch = useDispatch();
  // const history = useHistory();
  const selectUpdateUserReqStatus = useSelector(
    (state) => state.userRequest?.updateStatusRequest
  );
  const [acceptTerms, setAcceptTerms] = useState(false);
  const selectUserPayload2Data = useSelector(selectUserPayload2);
  // const selectUserPayload1Data = useSelector(selectUserPayload1);
  const [agree, setAgree] = useState(false);
  const [validated, setValidated] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [languageChnage, setLanguageChnage] = useState(false);

  useEffect(() => {
    if (selectUserPayload2Data) {
      setAcceptTerms(selectUserPayload2Data?.payment_terms_accepted);
    }
  }, [selectUserPayload2Data]);

  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    } else {
      manageUserHandler();
      event.preventDefault();
      event.stopPropagation();
    }
  };

  const manageUserHandler = async () => {
    setUploading(true);
    let input = {
      // flag: true,
      // isSaved: true,
      // term: true,

      payment_terms_accepted: true
    };

    let payload = {
      user_id: userId,
      payload2: FilterPayload2Items(input, selectUserPayload2Data)
    };

    let response = await postPayload2Data(payload);
    if (response.status == 200) {
      dispatch(fetchUserRequest({ userId }));
      onNextTabHandler('projectWisePayment');
    }
    setUploading(false);
  };

  return (
    <div className="container-fluid">
      {selectUpdateUserReqStatus?.state === "loading" ? (
        <Spinner />
      ) : (
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <div className="row">
            <General>
              <div className="col-sm-10">
                <h3 className="text-left heading-font">
                  Payment Terms & Conditions{" "}
                  {languageChnage ? (
                    <a
                      style={{
                        color: "#4640de",
                        fontSize: 16,
                        fontWeight: "normal",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: 1.63,
                        letterSpacing: "normal",
                      }}
                      onClick={() => setLanguageChnage(false)}
                    >
                      {" "}
                      (For Payment Terms in English Click here)
                    </a>
                  ) : (
                    <a
                      style={{
                        color: "#4640de",
                        fontSize: 16,
                        fontWeight: "normal",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: 1.63,
                        letterSpacing: "normal",
                      }}
                      onClick={() => setLanguageChnage(true)}
                    >
                      {" "}
                      ( मराठीत पेमेंट अटींसाठी येथे क्लिक करा )
                    </a>
                  )}
                </h3>

                {languageChnage ? (
                  <div>
                    {/* <p className="contentdata text-left">
                      अर्जदार हा एकाच अर्ज क्रमांकाद्वारे PCMCPMAY2023XXXXXX
                      दोन्ही प्रकल्पांसाठी अर्ज करु शकतो. परंतु, सदर अर्जांसाठी
                      स्वतंत्र अनामत रक्कम भरावा लागेल. अनामत रक्कम ही तुमच्या
                      प्रकल्पांच्या निवडीच्या आधारे मोजली जाईल.
                    </p>
                    <p className="contentdata text-left">
                      (जर तुम्ही दोन्ही प्रकल्पांसाठी अर्ज करुन संपुर्ण अनामत
                      रक्कम भरले असेल तर तुमचा अर्ज दोन्ही प्रकल्पांच्या
                      सोडतीकरिता विचारात घेतले जाईल.)
                      <br />
                      जर अर्जदारास आकुर्डी व पिंपरी या दोन्ही प्रकल्पांसाठी अर्ज
                      सादर करावयाचा असेल, तर त्यांनी एका वेळी एक प्रकल्प निवडून
                      स्वतंत्ररित्या अनामत रक्कम भरावयाची आहे.
                    </p> */}
                    <p className="contentdata text-left">
                      सदर एकुण अनामत र.रु.१०५००/- पैकी र.रु.५००/- ही अर्जाची
                      प्रक्रिया शुल्क असून सदर रकमेचा आपणास परतावा केला जाणार
                      नाही. सोडतीमध्ये किंवा प्रतिक्षा यादीमध्ये नाव निश्चित
                      झालेस र.रु.१००००/- इतकी रक्कम आपल्या लाभार्थी हिश्श्याच्या
                      रकमेमधून वजावट करणेत येईल. तसेच ज्या अर्जदारांचे नाव
                      सोडतीमध्ये येणार नाही त्यांना र.रु.१००००/- त्यांनी ज्या
                      माध्यमातून अनामत रक्कम भरली असेल त्याच माध्यमाद्वारे परत
                      करणेत येईल.
                    </p>
                  </div>
                ) : (
                  <div>
                    {/* <p className="contentdata text-left">
                      The application is a registration for the lottery, an
                      applicant can select their choice for a single or both
                      projects with the same application number:
                      PCMCPMAY2023XXXXXX. The security deposit is calculated
                      based on your selection.
                    </p>
                    <p className="contentdata text-left">
                      (In the case of selecting both projects, your application
                      will be considered for both lotteries.) If an applicant
                      wants to submit an application for both projects, they
                      will need to pay the security deposit individually by
                      choosing one project at a time.
                    </p> */}

                    <p className="contentdata text-left">
                      Out of the total security deposit of Rs.10,500/-, (Rs.
                      500/- processing fee will not be refunded) If the
                      applicant is selected in the lottery (winner or waiting
                      list), an amount of Rs.10,000/- will be deducted from
                      their beneficiary share.{" "}
                    </p>

                    <p className="contentdata text-left">
                      Also, for those applicants whose names are not selected in
                      the lottery, the amount of Rs.10,000/- will be refunded
                      through the same medium through which they have paid the
                      security deposit amount.{" "}
                    </p>
                  </div>
                )}
                <div>
                  <Form.Group className="mb-3" style={{ textAlign: "left" }}>
                    <Form.Check
                      required
                      className="contentdata text-left"
                      label={
                        languageChnage
                          ? "वरीलप्रमाणे अटी व शर्ती मला मान्य आहेत|"
                          : "by clicking here, I state that i have read understood these terms and condtions"
                      }
                      feedback="You must agree before submitting."
                      feedbackType="invalid"
                      value={agree}
                      disabled={acceptTerms}
                      checked={agree || acceptTerms}
                      onChange={(e) => setAgree(e.target.value)}
                    />
                  </Form.Group>
                </div>
              </div>
            </General>
            <div className="col-sm-12 text-right">
              {acceptTerms ? <button className="btn btn-primary pl-5 pr-5 mt-3 pull-right" type="button" onClick={() => onNextTabHandler('projectWisePayment')}>
                Next
              </button> : <button className="btn btn-primary pl-5 pr-5 mt-3 pull-right" type="submit">
                Save
              </button>}

            </div>

          </div>
        </Form>
      )}
    </div>
  );
};

export default PaymentTerm;

const General = styled.div`
  .alignatag {
    display: block;
    text-align: left;
    color: #848493;
    text-decoration: underline;
    margin-bottom: 10px;
    cursor: pointer;
  }
`;
