import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
// import ApiDataService from "../../services/api-service";
import { useDispatch, useSelector } from "react-redux";
import { FilterPayload1Items, FilterPayload2Items } from "../../services/PayloadAPIService";
// import { useHistory } from "react-router-dom";
import { clearUpdateReqState, selectUserPayload2, fetchUserRequest} from "../../redux/slices/userRequestSlice";
import Spinner from "../Spinner";
import { postPayload2Data, getPincodeVerificationData } from "../../services/PayloadAPIService";

const CurrentAddress = ({ userId, onNextTabHandler }) => {
    const selectDataUserReqStatus = useSelector((state) => state.userRequest?.userRequestListState);
    const [pinCode, setPinCode] = useState('');
    const [cAddState, setCAddState] = useState([]);
    const [selectedcAddState, setSelectedCAddState] = useState([]);
    const [cAddDistrict, setCAddDistrict] = useState([]);
    const [selectedcAddDistrict, setSelectedCAddDistrict] = useState([]);
    const [cAddCity, setCAddCity] = useState([]);
    const [selectCity, setCitySelected] = useState('');
    const [cAddAreaName, setCAddAreaName] = useState('');
    const [cAddDoorNo, setCAddDoorNo] = useState('');
    const [validated, setValidated] = useState(false);
    // const history = useHistory();
    const [uploading, setUploading] = useState(false);
    const selectUserPayload2Data = useSelector(selectUserPayload2);

    const dispatch = useDispatch();
    const selectUpdateUserReqStatus = useSelector((state) => state.userRequest?.updateStatusRequest);

    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
        } else {
            manageCurrentAddressHandler();
            event.preventDefault();
            event.stopPropagation();
        }
    };

    useEffect(() => {
        if (selectUserPayload2Data?.current_address_pin) {
            getPincodeData(selectUserPayload2Data?.current_address_pin);
        }
    }, [selectUpdateUserReqStatus?.state]);

    useEffect(() => {
        if (selectUpdateUserReqStatus?.state === 'succeeded') {
            dispatch(clearUpdateReqState());
            dispatch(fetchUserRequest({ userId }));
        }
    }, [selectUpdateUserReqStatus?.state]);

    useEffect(() => {
        if (selectUserPayload2Data) {
            setPinCode(selectUserPayload2Data?.current_address_pin);
            setSelectedCAddState(selectUserPayload2Data?.current_address_state);
            setSelectedCAddDistrict(selectUserPayload2Data?.current_address_district);
            setCitySelected(selectUserPayload2Data?.current_address_area);
            setCAddAreaName(selectUserPayload2Data?.current_address_street);
            setCAddDoorNo(selectUserPayload2Data?.current_address_house_no);
        }
    }, [selectUserPayload2Data]);

    const manageCurrentAddressHandler = async () => {
        setUploading(true);
        let input = {
            // flag: true,
            // isSaved: true,
            // pinCode: pinCode, 
            // state: selectedcAddState,
            // district: selectedcAddDistrict,
            // city: selectCity,
            // areaName: cAddAreaName,
            // doorNo: cAddDoorNo
            current_address_pin: pinCode,
            current_address_state: selectedcAddState,
            current_address_district: selectedcAddDistrict,
            current_address_area: selectCity,
            current_address_street: cAddAreaName,
            current_address_house_no: cAddDoorNo,
        };

        let payload = {
            user_id: userId,
            payload1: FilterPayload1Items('addressAndHousingFilled', 'tab2'),
            payload2: FilterPayload2Items(input, selectUserPayload2Data)
        };

        let response = await postPayload2Data(payload);
        if (response.status == 200) {
            dispatch(fetchUserRequest({ userId }));
            onNextTabHandler('permanentAddress');
        }
        setUploading(false);
    };

    const handleKeyPress = (event) => {
        getPincodeData(event);
    }

    const clearDropdown = () => {
        setSelectedCAddState([]);
        setSelectedCAddDistrict([]);
        setCitySelected([]);
    }

    function getPincodeData(pincode) {
        if (pincode.length === 6) {
            setUploading(true);
            getPincodeVerificationData(pincode)
                .then(response => {
                    if (response.status === 200 && response.data[0].Status === "Success") {
                        let resData = response.data[0].PostOffice;
                        const uniqueStateName = resData.map(item => item.State)
                            .filter((value, index, self) => self.indexOf(value) === index);
                        const uniqueDistrictName = resData.map(item => item.District)
                            .filter((value, index, self) => self.indexOf(value) === index)
                        setCAddState(uniqueStateName);
                        setCAddDistrict(uniqueDistrictName);
                        setCAddCity(resData);
                    } else {
                        clearDropdown();
                    }
                    setUploading(false);
                })
                .catch(err => {
                    console.log('An error occurred:', err.res);
                    clearDropdown();
                    setUploading(false);
                });
        }

    }

    return (
        <div className="container-fluid">
            {selectDataUserReqStatus?.state === 'loading' ? <Spinner /> :
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-sm-3 ">
                            <h5 className="headingTitle">Current Address</h5>
                            <p className="contentdata">Please enter your current address details here. </p>
                        </div>
                        <div className="col-sm-6 offset-sm-1">
                            <div className="row">
                                <div className="col">
                                    <Form.Group className="form-group" controlId="pinCode" >
                                        <Form.Label>Pin Code</Form.Label>
                                        <Form.Control
                                            className="form-control"
                                            type="text"
                                            placeholder="6 digit Pin Code"
                                            value={pinCode}
                                            maxLength={6}
                                            onChange={(e) => setPinCode(e.target.value)}
                                            onKeyUp={(e) => handleKeyPress(e.target.value)}
                                            onMouseLeave={(e) => handleKeyPress(e.target.value)}
                                            required
                                        ></Form.Control>
                                        <Form.Control.Feedback type="invalid">
                                            Please provide 6 digit Pin Code.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <div className="col">
                                    {uploading ? null : <Form.Group className="form-group" controlId="selectedcAddState">
                                        <Form.Label>State</Form.Label>
                                        <Form.Control as='select' multiple={false} className="form-control" value={selectedcAddState}
                                            onChange={(e) => setSelectedCAddState(e.target.value)} required>
                                            <option value="">Select the State</option>
                                            {
                                                cAddState && cAddState.map((data, key) => (
                                                    <option key={key} value={data}>{data}  </option>
                                                ))}
                                        </Form.Control>
                                        <Form.Control.Feedback type="invalid">
                                            Please Select State
                                        </Form.Control.Feedback>
                                    </Form.Group>}
                                </div>
                            </div>
                            {uploading ? <div className="col text-center"><Spinner /></div> :
                                <div className="row">
                                    <div className="col">
                                        <Form.Group className="form-group" controlId="selectedcAddDistrict">
                                            <Form.Label>District</Form.Label>
                                            <Form.Control as='select' multiple={false} className="form-control" value={selectedcAddDistrict}
                                                onChange={(e) => setSelectedCAddDistrict(e.target.value)} required>
                                                <option value="">Select the District</option>
                                                {
                                                    cAddDistrict && cAddDistrict.map((data, key) => (
                                                        <option key={key} value={data}>
                                                            {data}
                                                        </option>
                                                    ))}
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                Please Select District
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                    <div className="col">
                                        <Form.Group className="form-group" controlId="selectCity">
                                            <Form.Label>Area</Form.Label>
                                            <Form.Control as='select' multiple={false} className="form-control" value={selectCity}
                                                onChange={(e) => setCitySelected(e.target.value)} required>
                                                <option value="">Select the Area</option>
                                                {
                                                    cAddCity.map((data, key) => (
                                                        <option key={key} value={data.Name}>
                                                            {data.Name}
                                                        </option>
                                                    ))}
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                Please Select Area
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                </div>}
                            <Form.Group className="form-group" controlId="cAddAreaName" >
                                <Form.Label>Area Name / Street Name</Form.Label>
                                <Form.Control
                                    className="form-control"
                                    type="text"
                                    placeholder="Area Name"
                                    value={cAddAreaName}
                                    onChange={(e) => setCAddAreaName(e.target.value)}
                                    required
                                ></Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    Please provide Area Name / Street Name.
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group className="form-group" controlId="cAddDoorNo" >
                                <Form.Label>House / Flat / Door No.</Form.Label>
                                <Form.Control
                                    className="form-control"
                                    type="text"
                                    placeholder="House / Flat / Door No"
                                    value={cAddDoorNo}
                                    onChange={(e) => setCAddDoorNo(e.target.value)}
                                    required
                                ></Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    Please provide House / Flat / Door No.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <button className="btn btn-primary mt-5" type="submit" >Save & Proceed</button>
                        </div>
                    </div>
                </Form>}
        </div>
    )
}

export default CurrentAddress