import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ApiDataService from "../../services/api-service"
import { selectUserProfile } from "./userProfileSlice";
const apiState = {
  data: null,
  state: "idle",
  error: "",
};

export const fetchUserRequest = createAsyncThunk(
  "userRequest/fetchUserRequest",
  async ({ userId }) => {
    try {
      const url = `get_user_data?user_id=${userId}`;
      const result = await ApiDataService.get(url);
      return {
        data: result.data
      };
    } catch (error) {
      console.log("update error:", error);
    }
  }
);

export const updateRequestStatus = createAsyncThunk(
  "userRequest/updateRequestStatus",
  async ({ rowData }) => {
    try {
      const url = `submit_user_data`;
      const result = await ApiDataService.post(url, rowData);
      return {
        data: result,
      };
    } catch (error) {
      console.log("update error:", error);
    }
  }
);

const userRequestSlice = createSlice({
  name: "userRequest",
  initialState: {
    userRequestListState: {
      ...apiState,
      filteredData: null,
    },
    updateStatusRequest: {
      ...apiState,
    },
  },
  reducers: {
    clearUpdateReqState(state, action) {
      state.updateStatusRequest.state = "idle";
    },
    filterUserData(state, action) {
      let filteredData = action.payload;
      state.userRequestListState.filteredData = filteredData;
    },
  },
  extraReducers: {
    [fetchUserRequest.pending]: (state, action) => {
      state.userRequestListState.state = "loading";
      state.userRequestListState.error = "";
    },
    [fetchUserRequest.fulfilled]: (state, action) => {
      state.userRequestListState.state = "succeeded";
      state.userRequestListState.data = action.payload?.data || [];
    },
    [fetchUserRequest.rejected]: (state, action) => {
      state.userRequestListState.state = "failed";
      state.userRequestListState.error = action.error.message;
    },

    [updateRequestStatus.pending]: (state, action) => {
      state.updateStatusRequest.state = "loading";
      state.updateStatusRequest.error = "";
    },
    [updateRequestStatus.fulfilled]: (state, action) => {
      state.updateStatusRequest.state = "succeeded";
      state.updateStatusRequest.data = action.payload?.data || [];
    },
    [updateRequestStatus.rejected]: (state, action) => {
      state.updateStatusRequest.state = "failed";
      state.updateStatusRequest.error = action.error.message;
    },
  },
});

export const selectUserRequestData = (state) =>
  state.userRequest.userRequestListState;
export const selectUserPayload1 = (state) => {
  if (state.userRequest.userRequestListState?.data?.payload1)
  // remove json.parse
    return (state.userRequest.userRequestListState?.data?.payload1)
  else
    return {}
};
export const selectUserPayload2 = (state) => {
  if (state.userRequest.userRequestListState?.data?.payload2)
    return (state.userRequest.userRequestListState?.data?.payload2)
  else
    return {}
};


export const selectUpdateUserReqStatus = (state) =>
  state.userRequest.updateStatusRequest;
export const { clearUpdateReqState, filterUserData } =
  userRequestSlice.actions;

export default userRequestSlice.reducer;
