import {
  UserModalData,
  ApplicationProcessStatus
} from "./PayloadModalData";

import ApiDataService from "./api-service";

const FilterPayload2Items = (inputs, dataItems) => {
  let newPayload;
  if (dataItems && Object.keys(dataItems).length === 0) {
    let newUserModalData = {
      ...UserModalData,
      ...inputs
    };
    newPayload = newUserModalData;
  } else {
    dataItems = { ...dataItems, ...inputs};
    newPayload = dataItems;
  }

  return newPayload;
};

const FilterPayload1Items = (key, tab, dataItems) => {
  let newPayload;
  if (key && dataItems && Object.keys(dataItems).length === 0) {
    if (tab === 'lastTab') {
      ApplicationProcessStatus[key] = true;
    } else {
      ApplicationProcessStatus[key] = false;
    }
    newPayload = ApplicationProcessStatus;
  } else if (dataItems && Object.keys(dataItems).length > 0) {
    //dataItems[key] = true;
    newPayload = dataItems;
  }
  return newPayload;
};

const FilterPayload2FileUpload = (fileName, index, dataItems, otherDocument) => {

  let newPayload;
  let newData= dataItems[index]
  if (newData && newData.length > 0) {
    newData.misc_doc_status = "Pending Verification";
    newData.misc_doc_file_name = fileName;
    newData.misc_doc_upload_timestamp = JSON.stringify(new Date());
    dataItems[index] = newData;
    newPayload = dataItems;

  } else {
    let finalDocList = dataItems[index];
    
    finalDocList.misc_doc_status = "Pending Verification";
    finalDocList.misc_doc_file_name = fileName;
    finalDocList.misc_doc_upload_timestamp = JSON.stringify(new Date());
    dataItems[index] = finalDocList;
    if (otherDocument) {
      finalDocList.misc_doc_name =  otherDocument;
      finalDocList.misc_doc_description =  "Additional Document";
    }
    newPayload = dataItems;
  }

  return newPayload;
};

const panVerification = async (panNo) => {
  return await ApiDataService.get(`validate_pan?pan=${panNo}`)
}

const postPayload2Data = async (payload) => {
  return await ApiDataService.post(`submit_user_data`, payload)
}

const logoutFromAPI = async () => {
  return await ApiDataService.get(`logout`)
}

const aadhaarSendOTP = async (aadhaarNo) => {
  return await ApiDataService.get(`validate_aadhaar_with_otp?aadhaar=${aadhaarNo}`)
}

const getPincodeVerificationData = async (pincode) => {
  return await ApiDataService.get(`pincode?pincode=${pincode}`)
}

const getIFSCCodeVerificationData = async (ifscVal) => {
  return await ApiDataService.get(`ifsc?ifsc=${ifscVal}`)
}

const validateAadhaarNo = async (aadhaarNo) => {
  return await ApiDataService.get(`validate_aadhaar?aadhaar=${aadhaarNo}`)
}

const aadhaarSubmitOTPVerification = async (otp, cId) => {
  let request = {
    client_id: cId,
    otp: otp
  };
  return await ApiDataService.post(`submit_aadhaar_otp`, request)
}
const fileUpload = async (formData) => {
  return await ApiDataService.post(`upload_file`, formData)
}

const onlinePayment = async (projectName, userId, mobileNo,applicationId) => {
  return await ApiDataService.get(`initiate_transaction?project_name=${projectName}&user_id=${userId}&mobile_number=${mobileNo}&application_id=${applicationId}`)
}

const billDeskStatus = async (userId) => {
  return await ApiDataService.get(`https://devserver.pmay.org/get_userwise_payment_info?user_id=${userId}`);
}

const getApplicationReview = async (application_id) => {
  return await ApiDataService.get(`get_application_id_wise_issues?application_id=${application_id}`);
}

const submitApplicationAfterEdit = async (application_id) => {
  let request = {
    application_id: application_id
  };
  return await ApiDataService.post(`submit_after_issue_correction`, request)
}

export {
  FilterPayload1Items, FilterPayload2Items, FilterPayload2FileUpload,
  postPayload2Data, panVerification, aadhaarSendOTP, aadhaarSubmitOTPVerification, logoutFromAPI,
  fileUpload, validateAadhaarNo, getPincodeVerificationData, 
  getIFSCCodeVerificationData, onlinePayment, billDeskStatus,getApplicationReview, submitApplicationAfterEdit
};
