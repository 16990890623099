import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import { useReactToPrint } from "react-to-print";
import {
  selectUserPayload1,
  selectUserPayload2,
  selectUserRequestData,
} from "../../redux/slices/userRequestSlice";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserRequest } from "../../redux/slices/userRequestSlice";
import { Utility } from "../../utils/utility";
import {
  FilterPayload1Items,
  FilterPayload2Items,
} from "../../services/PayloadAPIService";
import { postPayload2Data } from "../../services/PayloadAPIService";
import { useHistory } from "react-router-dom";

const PaymentStatusSuccess = ({ userId, selectedArea, selectUserPaymentDetails }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const selectUserRequest = useSelector(selectUserRequestData);
  const selectUserPayload1Data = useSelector(selectUserPayload1);
  const selectUserPayload2Data = useSelector(selectUserPayload2);
  const [uploading, setUploading] = useState(false);
  const [aadhaarBasicVerified, setAadhaarBasicVerified] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    if (selectUserPayload2Data) {
      setAadhaarBasicVerified(selectUserPayload2Data?.identification_is_aadhaar_verified);
    }
  }, [selectUserPayload2Data]);

  const managePrintHandler = () => {
    handlePrint();
  };

  useEffect(() => {
    if (localStorage.getItem("mobileNo")) {
      setMobileNumber(localStorage.getItem("mobileNo"));
    }
  }, []);

  const manageUserHandler = async () => {
    if (aadhaarBasicVerified) {
      history.push(`/dashboard/addresshousing`);
    } else {
      setUploading(true);

      let input = {
        payment_terms_accepted: true,
        payment_status: true
      };

      let payload = {
        user_id: userId,
        payload1: FilterPayload1Items('onlinePaymentFilled', 'lastTab'),
        payload2: FilterPayload2Items(input, selectUserPayload2Data)
      };

      let response = await postPayload2Data(payload);
      if (response.status == 200) {
        dispatch(fetchUserRequest({ userId }));
        history.push(`/dashboard/identification?tab=3`);
      }
      setUploading(false);
    }
  };

  return (
    <>
      <div className="col-sm-5 payment-success-content">
        <img
          src="/assets/icons/payment-success.svg"
          style={{ marginBottom: 30, marginTop: 30 }}
          alt="payment status"
        />
        <p className="contentdata">
          Your Payment has been successfully verified.
        </p>
        <div style={{ marginTop: 20 }}>
          <h4 className="payment-amount">₹ {selectUserPaymentDetails?.txn_amount ? selectUserPaymentDetails?.txn_amount : 0} </h4>
        </div>
        <hr />
        <div style={{ marginTop: 10 }}>
          <table className="table" cellspacing="0" cellpadding="0">
            <tr>
              <td className="first">Payment Method</td>
              <td className="second">{selectUserPaymentDetails?.payment_method}</td>
            </tr>
            <tr>
              <td className="first">Status</td>
              <td className="second">
                <span className="success-color">Success</span>
              </td>
            </tr>
            <tr>
              <td className="first">Transaction ID</td>
              <td className="second">{selectUserPaymentDetails?.transaction_id}</td>
            </tr>

            <tr>
              <td className="first">Transaction Ref</td>
              <td className="second">{selectUserPaymentDetails?.txn_reference_no}</td>
            </tr>
          </table>
        </div>
        <div className="row">
          <button className="btn btn-light form-control" onClick={managePrintHandler}>
            <img src="/assets/icons/print-receipt.svg" /> Print Receipt
          </button>
          <button
            className="btn btn-primary pl-5 pr-5 mt-4 form-control"
            type="button"
            onClick={manageUserHandler}
          >
            Proceed
          </button>
        </div>
        {/* <div>
          {aadhaarBasicVerified ?
            <button
              className="btn btn-primary pl-5 pr-5 mt-4 form-control"
              type="button"
              onClick={manageUserHandler}
            >
              Proceed
            </button> :
            <button
              className="btn btn-primary pl-5 pr-5 mt-4 form-control"
              type="button"
              onClick={manageUserHandler}
            >
              Proceed
            </button>
          }
        </div> */}
      </div>

      {/* reciept */}
      <div className={`col-md-5 offset-md-1 payment-reciept-content`} >
        <div className="text-center">
          <img
            src="/assets/official-logo-of-pcmc.webp"
            style={{ marginBottom: 30, marginTop: 30 }}
            alt="payment status"
          />
          <h5>Pimpri Chinchwad Municipal Corporation</h5>
          <p>
            Mumbai-Pune Road, Pimpri, Pune-411018, Maharashtra, INDIA. Phone:
            91-020-2742-5511/12/13/14
          </p>
        </div>
        <hr />
        <div style={{ marginTop: 10 }}>
          <table className="table" cellspacing="0" cellpadding="0">
            <tr>
              <td className="first">Transaction ID</td>
              <td className="second">{selectUserPaymentDetails?.transaction_id}</td>
            </tr>

            <tr>
              <td className="first">Transaction Ref</td>
              <td className="second">{selectUserPaymentDetails?.txn_reference_no}</td>
            </tr>
            <tr>
              <td className="first">Date</td>
              <td className="second">{moment(new Date(selectUserPaymentDetails?.response_time)).format("DD MMM YYYY - hh:mm A")}</td>
            </tr>
          </table>
        </div>
        <hr />
        <div style={{ marginTop: 10 }}>
          <table className="table" cellspacing="0" cellpadding="0">
            <tr>
              <td className="first">Applicant Name</td>
              <td className="second">{selectUserPayload2Data?.identification?.information?.nameAsPerAadhaar}</td>
            </tr>
            <tr>
              <td className="first">Application ID</td>
              <td className="second">{selectUserRequest?.data?.application_id}</td>
            </tr>
            <tr>
              <td className="first">Mobile Number</td>
              <td className="second">{mobileNumber}</td>
            </tr>
            <tr>
              <td className="first">Project</td>
              <td className="second">{selectedArea}</td>
            </tr>
          </table>
        </div>
        <hr />
        <div style={{ marginTop: 10 }}>
          <table className="table" cellspacing="0" cellpadding="0">
            <tr>
              <td className="first">Total Payment Received</td>
              <td className="second">₹ {selectUserPaymentDetails?.txn_amount ? selectUserPaymentDetails?.txn_amount : 0}</td>
            </tr>
          </table>
        </div>
        <hr />
        <div style={{ marginTop: 10 }}>
          <table className="table" cellspacing="0" cellpadding="0">
            <tr>
              <td className="first">
                Amount Paid (in words): {selectUserPaymentDetails?.txn_amount ? `Rupees ${Utility.convertNumberToWords(selectUserPaymentDetails?.txn_amount)} Only` : null}
              </td>
            </tr>
          </table>
        </div>
        <hr />
        <div style={{ marginTop: 10 }}>
          <table className="table" cellspacing="0" cellpadding="0">
            <tr>
              <td className="first text-center">
                This is a computer generated receipt. Signature is not required
              </td>
            </tr>
          </table>
        </div>

      </div>

      {/* for print only */}
      <div style={{ visibility: "hidden" }}>
        <div className={`col-md-6 offset-md-3 payment-reciept-content `} ref={componentRef}>
          <div className="text-center">
            <img
              src="/assets/official-logo-of-pcmc.webp"
              style={{ marginBottom: 30, marginTop: 80 }}
              alt="payment status"
            />
            <h5>Pimpri Chinchwad Municipal Corporation</h5>
            <p>
              Mumbai-Pune Road, Pimpri, Pune-411018, Maharashtra, INDIA. Phone:
              91-020-2742-5511/12/13/14
            </p>
          </div>
          <div style={{ marginTop: 10 }}>
            <table className="table" cellspacing="0" cellpadding="0">
              <tr>
                <td className="first">Transaction ID</td>
                <td className="second">{selectUserPaymentDetails?.transaction_id}</td>
              </tr>

              <tr>
                <td className="first">Transaction Ref</td>
                <td className="second">{selectUserPaymentDetails?.txn_reference_no}</td>
              </tr>
              <tr>
                <td className="first">Date</td>
                <td className="second">{moment(new Date(selectUserPaymentDetails?.response_time)).format("DD MMM YYYY - hh:mm A")}</td>
              </tr>
            </table>
          </div>
          <div style={{ marginTop: 10 }}>
            <table className="table" cellspacing="0" cellpadding="0">
              <tr>
                <td className="first">Applicant Name</td>
                <td className="second">{selectUserPayload2Data?.identification?.information?.nameAsPerAadhaar}</td>
              </tr>

              <tr>
                <td className="first">Application ID</td>
                <td className="second">{selectUserRequest?.data?.application_id}</td>
              </tr>
              <tr>
                <td className="first">Mobile Number</td>
                <td className="second">{mobileNumber}</td>
              </tr>
              <tr>
                <td className="first">Project</td>
                <td className="second">{selectedArea}</td>
              </tr>
            </table>
          </div>
          <div style={{ marginTop: 10 }}>
            <table className="table" cellspacing="0" cellpadding="0">
              <tr>
                <td className="first">Total Payment Received</td>
                <td className="second">₹ {selectUserPaymentDetails?.txn_amount ? selectUserPaymentDetails?.txn_amount : 0}</td>
              </tr>
            </table>
          </div>
          <div style={{ marginTop: 10 }}>
            <table className="table" cellspacing="0" cellpadding="0">
              <tr>
                <td className="first">
                  Amount Paid (in words): {selectUserPaymentDetails?.txn_amount ? `Rupees ${Utility.convertNumberToWords(selectUserPaymentDetails?.txn_amount)} Only` : null}
                </td>
              </tr>
            </table>
          </div>
          <div style={{ marginTop: 10 }}>
            <table className="table" cellspacing="0" cellpadding="0">
              <tr>
                <td className="first text-center">
                  This is a computer generated receipt. Signature is not required
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentStatusSuccess;
