import React, { useState, useEffect } from "react";
const PaymentStatusPending = ({ selectUserPaymentDetails }) => {
  return (
    <>
      <div className="col-sm-5 offset-md-3 text-center payment-pending-content">
        <img
          src="/assets/icons/payment-pending.svg"
          style={{ marginBottom: 30, marginTop: 30 }}
          alt="payment status"
        />
        <p className="contentdata">
          Your transaction is pending verification from the bank. Please check
          back in 24 hours for the status of the transaction.
        </p>
        <div style={{ marginTop: 20 }}>
          <h4 className="payment-amount">₹ {selectUserPaymentDetails?.txn_amount ? selectUserPaymentDetails?.txn_amount : 0} </h4>
        </div>
        <hr />
        <div style={{ marginTop: 30 }}>
          <table className="table" cellspacing="0" cellpadding="0">
            {/* <tr>
              <td className="first">Payment Method</td>
              <td className="second">UPI</td>
            </tr> */}
            <tr>
              <td className="first">Status</td>
              <td className="second">
                <span className="pending-color">Pending Verification</span>
              </td>
            </tr>
            <tr>
              <td className="first">Transaction ID</td>
              <td className="second">{selectUserPaymentDetails?.transaction_id}</td>
            </tr>

            <tr>
              <td className="first">Transaction Ref</td>
              <td className="second">{selectUserPaymentDetails?.txn_reference_no}</td>
            </tr>
          </table>
        </div>
      </div>
    </>
  );
};

export default PaymentStatusPending;
