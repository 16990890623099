import React, { useState, useEffect } from "react";
import { Form, InputGroup, Button } from "react-bootstrap";
import { fetchUserRequest } from "../../redux/slices/userRequestSlice";
import { useDispatch, useSelector } from "react-redux";
import { FilterPayload2Items } from "../../services/PayloadAPIService.js";
import { selectUserPayload2 } from "../../redux/slices/userRequestSlice";
import Spinner from "../Spinner";
import {
  aadhaarSubmitOTPVerification,
  aadhaarSendOTP,
} from "../../services/PayloadAPIService.js";

import { postPayload2Data } from "../../services/PayloadAPIService.js";
import { updateRequestStatus } from "../../redux/slices/userRequestSlice";
import {
  panVerification,
  validateAadhaarNo,
} from "../../services/PayloadAPIService.js";
import { Utility } from "../../utils/utility";

const Verification = ({ userId, onNextTabHandler }) => {
  const dispatch = useDispatch();
  const selectUpdateUserReqStatus = useSelector(
    (state) => state.userRequest?.updateStatusRequest
  );
  const selectDataUserReqStatus = useSelector(
    (state) => state.userRequest?.userRequestListState
  );
  const [aadhaarLinked, setAadhaarLinked] = useState(false);
  const [verification, setVerification] = useState({});
  const selectUserPayload2Data = useSelector(selectUserPayload2);
  const [panNo, setPanNumber] = useState("");
  const [aadharNo, setAadharNumber] = useState("");
  const [emailId, setEmailId] = useState("");
  const [aadhaarVerificationOTP, setAadhaarVerificationOTP] = useState("");
  const [aadhaarNoValidMsg, setAadhaarNoValidMsg] = useState("");
  const [aadhaarVerification, setAadhaarVerification] = useState(false);
  const [aadhaarVerificationFlag, setAadhaarVerificationFlag] = useState(false);
  const [otpStatus, setOTPStatus] = useState(false);
  const [otpResponse, setOtpResponse] = useState({});
  const [emailVerificationOTP, setEmailVerificationOTP] = useState("");
  const [emailVerification, setEmailVerification] = useState(false);
  const [validated, setValidated] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [panloader, setPanloader] = useState(false);
  const [aadhaarloader, setAadhaarloader] = useState(false);

  const [panNoVerification, setPanNoVerification] = useState(false);
  const [aadhaarBasicVerified, setAadhaarBasicVerified] = useState(false);

  useEffect(() => {
    if (selectUserPayload2Data?.identification_pan_no) {
      setPanNumber(
        selectUserPayload2Data?.identification_pan_no
      );
      setAadharNumber(
        selectUserPayload2Data?.identification_aadhaar_no
      );
      setPanNoVerification(
        selectUserPayload2Data?.identification_is_pan_verified
      );
      setAadhaarBasicVerified(selectUserPayload2Data?.identification_is_aadhaar_verified);
    }
    if (selectUserPayload2Data) {
      setVerification(selectUserPayload2Data?.identification_is_aadhaar_verified);
      if (
        selectUserPayload2Data?.identification_is_aadhaar_verified
      ) {
        setAadhaarVerificationOTP("OTP verified successfully.");
      }
      setAadhaarVerification(
        selectUserPayload2Data?.identification_aadhaar_no
      );
      setAadhaarLinked(
        selectUserPayload2Data?.identification_is_aadhaar_linked
      );
    }
  }, [selectUserPayload2Data]);

  useEffect(() => {
    if (selectUserPayload2Data) {
      setAadharNumber(
        selectUserPayload2Data?.identification_aadhaar_no
      );
      setEmailId(selectUserPayload2Data?.identification_primary_email_id);
    }
  }, [selectUserPayload2Data]);

  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    } else {
      manageUserHandler();
      event.preventDefault();
      event.stopPropagation();
    }
  };

  const aadhaarOTPHandler = async () => {
    if (aadharNo) {
      setAadhaarNoValidMsg("");
      setAadhaarloader(true);
      aadhaarSendOTP(aadharNo)
        .then((response) => {
          if (response?.status === 200) {
            setOtpResponse(response?.data?.data);
            setOTPStatus(true);
          }
          setAadhaarloader(false);
        })
        .catch((err) => {
          console.log("response==>", err);
          setAadhaarNoValidMsg("Please provide Aadhaar Number");
          setAadhaarloader(false);
        });
    } else {
      setAadhaarNoValidMsg("Please provide Aadhaar Number");
    }
  };

  const aadhaarSubmitVerificationHandler = async () => {
    if (otpResponse && aadhaarVerificationOTP) {
      setAadhaarloader(true);
      aadhaarSubmitOTPVerification(
        aadhaarVerificationOTP,
        otpResponse.client_id
      )
        .then((response) => {
          if (response?.status === 200) {
            setAadhaarVerification(true);
            addAadhaarData();
                
   // identification_is_email_verified : false,

            let input = {
              identification_aadhaar_verification_type : 'Partial',
              identification_is_aadhaar_verified: true,
            };
            manageUserHandler(input, 'aadhaarVerified');
          }
          if(response?.status === 500){
            setAadhaarNoValidMsg('Please Enter Valid OTP')
          }
          setAadhaarloader(false);
        })
        .catch((err) => {
          setAadhaarNoValidMsg('Please Enter Valid OTP')
          setAadhaarloader(false);
        });
    }
  };

  const addAadhaarData = async () => {
    let input = {
      identification_aadhaar_no: aadharNo,
      identification_is_aadhaar_verified: true,
    };
    let payload = {
      user_id: userId,
      payload2: 
        FilterPayload2Items(
          input,
          selectUserPayload2Data
        )
    };

    let response = await postPayload2Data(payload);
    if (response.status == 200) {
      dispatch(fetchUserRequest({ userId }));
      let inputPay = {
        identification_aadhaar_no: aadharNo,
        identification_is_aadhaar_verified: true,
        identification_aadhaar_verification_type : 'Full',
        identification_is_aadhaar_linked: aadhaarLinked
      };
      manageUserHandler(inputPay, 'aadhaarVerified');
    }
    dispatch(fetchUserRequest({ userId }));
  };

  const manageUserHandler = async (input, type) => {
    setUploading(true);

    let payload = {
      user_id: userId,
      payload2: 
        FilterPayload2Items(
          input,
          selectUserPayload2Data
        )
    };

    let response = await postPayload2Data(payload);
    if (response.status == 200) {
      dispatch(fetchUserRequest({ userId }));
      if (type === "aadhaarVerified" && panNoVerification) {
        onNextTabHandler("uploadDocuments");
      }

    }
    setUploading(false);
  };

  const panVerificationHandler = async () => {
    if (panNo) {
      setPanloader(true);
      setAadhaarNoValidMsg("");
      panVerification(panNo)
        .then(async (response) => {
          if (response?.status === 200) {
            setPanNoVerification(true);

            let input = {
              identification_pan_no: panNo,
              identification_is_pan_verified: false,
            };
            let payload = {
              user_id: userId,
              payload2: 
                FilterPayload2Items(
                  input,
                  selectUserPayload2Data
                )
            };
            let response = await postPayload2Data(payload);
            if (response.status == 200) {
              dispatch(fetchUserRequest({ userId }));
              let inputPay = {
                identification_pan_no: panNo,
                identification_is_pan_verified: true
              };
              manageUserHandler(inputPay, 'panVerified')
            }
          }
          setPanloader(false);
        })
        .catch((err) => {
          console.log("response==>", err);
          setAadhaarNoValidMsg("Please Enter Valid PAN Number");
          setPanloader(false);

        });
    }else{
      setAadhaarNoValidMsg("Please Enter PAN Number");
    }
  };

  const aadharVerificationHandler = () => {
    if (aadharNo && aadhaarLinked !== undefined) {
      setAadhaarloader(true);
      validateAadhaarNo(aadharNo)
        .then((response) => {
          if (response?.status === 200) {
            setAadhaarNoValidMsg("");
            addAadhaarData();
          }
          setAadhaarloader(false);
        })
        .catch((err) => {
          setAadhaarloader(false);
          console.log("response==>", err);
          setAadhaarNoValidMsg("Please Enter Valid Aadhaar Number");
        });
    } else {
      setAadhaarNoValidMsg("Please Enter Aadhaar Number");
    }
    if (aadhaarLinked === undefined) {
      setAadhaarNoValidMsg("Please Select Is your aadhaar linked to your phone number");
    }
  };

  return (
    <div className="container-fluid">
      {selectUpdateUserReqStatus?.state === "loading" ||
        selectDataUserReqStatus?.state === "loading" ? (
        <Spinner />
      ) : (
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-sm-3">
              <h5 className="headingTitle">Basic Information</h5>
              <p className="contentdata">
                Please add the Identification information for the head of
                family. Please ensure everything matches the identification
                proofs - Aadhar and PAN card.{" "}
              </p>
              <p className="contentdata">
                You will be asked to verify and upload them in the next steps.
              </p>
            </div>
            <div className="col-sm-6 offset-sm-1">
              <Form.Group className="form-group" controlId="panNo">
                <Form.Label>PAN Number</Form.Label>
                <InputGroup className="mb-3">
                  <Form.Control
                    className="form-control"
                    type="text"
                    pattern="[a-zA-Z0-9]{0,10}"
                    placeholder="Enter 10 digit PAN number"
                    value={panNo}
                    disabled={panNoVerification}
                    onChange={(e) => setPanNumber(Utility.toUpperCase(e.target.value))}
                    required
                  ></Form.Control>
                  <Form.Control.Feedback type="invalid">
                    Please provide 10 digit PAN Number.
                  </Form.Control.Feedback>
                  <Button
                    disabled={panNoVerification}
                    className={`btn-sm ${panNoVerification ? "btn-success" : "btn-primary"
                      }`}
                    id="button-addon2"
                    onClick={panVerificationHandler}
                  >
                    {panloader ? (
                      <div className="spinner-border text-light" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : panNoVerification ? (
                      "Verified"
                    ) : (
                      "Verify"
                    )}
                  </Button>
                </InputGroup>
              </Form.Group>

              <div className="row">
                <div className="col-sm-12">
                  <div className="form-group">
                    <div>
                      <label for="lbl">
                        Is your aadhaar linked to your phone number?
                      </label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline">
                      <input
                        type="radio"
                        name="linkedAadhaar"
                        class="custom-control-input"
                        disabled={aadhaarVerification || aadhaarBasicVerified}
                        onChange={(e) => setAadhaarLinked(e.target.value)}
                        id="Yes"
                        value="true"
                        checked={aadhaarLinked === "true"}
                        required
                      />
                      <label class="custom-control-label" for="Yes">
                        Yes
                      </label>
                    </div>
                    <div class="custom-control custom-radio custom-control-inline">
                      <input
                        type="radio"
                        name="linkedAadhaar"
                        class="custom-control-input"
                        disabled={aadhaarVerification || aadhaarBasicVerified}
                        onChange={(e) => setAadhaarLinked(e.target.value)}
                        id="no"
                        value="false"
                        checked={aadhaarLinked === "false"}
                        required
                      />
                      <label class="custom-control-label" for="no">
                        No
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              {aadhaarLinked === "true" ? (
                <div>
                  <Form.Group className="form-group" controlId="aadharNo">
                    <Form.Label>Aadhaar Number</Form.Label>

                    <InputGroup className="mb-3">
                      <Form.Control
                        className="form-control"
                        type="text"
                        pattern="[0-9]{0,12}"
                        placeholder="Enter 12 digit Aadhaar Number"
                        value={aadharNo}
                        disabled={aadhaarVerification || aadhaarBasicVerified}
                        onChange={(e) => setAadharNumber(e.target.value)}
                        required
                      ></Form.Control>

                      <Button
                        disabled={aadhaarVerification || aadhaarBasicVerified}
                        className={`btn-sm ${aadhaarVerification || aadhaarBasicVerified ? "btn-success" : "btn-primary"
                          }`}
                        onClick={aadhaarOTPHandler}
                      >
                        {aadhaarloader && otpStatus === false ? (
                          <div
                            className="spinner-border text-light"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        ) : aadhaarVerification || aadhaarBasicVerified ? (
                          "Verified"
                        ) : (
                          "Send OTP"
                        )}
                      </Button>
                    </InputGroup>

                    <Form.Control.Feedback type="invalid">
                      Please provide 12 digit Aadhaar Number.
                    </Form.Control.Feedback>
                  </Form.Group>

                  {aadhaarVerification || otpStatus === false ? null : (
                    <Form.Group
                      className="form-group"
                      controlId="aadhaarVerificationOTP"
                    >
                      <Form.Label>Aadhaar Verification OTP </Form.Label>
                      <InputGroup className="mb-3">
                        <Form.Control
                          className="form-control"
                          type="text"
                          placeholder="Enter OTP for Aadhaar Verification"
                          value={aadhaarVerificationOTP}
                          onChange={(e) =>
                            setAadhaarVerificationOTP(e.target.value)
                          }
                          required
                        ></Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Please provide a Aadhaar Verification OTP.
                        </Form.Control.Feedback>
                        <Button
                          disabled={aadhaarVerification}
                          className={`btn-sm ${aadhaarVerification ? "btn-success" : "btn-primary"
                            }`}
                          id="button-addon2"
                          onClick={aadhaarSubmitVerificationHandler}
                        >
                          {aadhaarloader ? (
                            <div
                              className="spinner-border text-light"
                              role="status"
                            >
                              <span className="sr-only">Loading...</span>
                            </div>
                          ) : aadhaarVerification ? (
                            "Verified"
                          ) : (
                            "Verify OTP"
                          )}
                        </Button>
                      </InputGroup>
                    </Form.Group>
                  )}
                </div>
              ) : (
                <Form.Group className="form-group" controlId="aadharNo">
                  <Form.Label>Aadhaar Number</Form.Label>

                  <InputGroup className="mb-3">
                    <Form.Control
                      className="form-control"
                      type="text"
                      pattern="[0-9]{0,12}"
                      placeholder="Enter 12 digit Aadhaar Number"
                      value={aadharNo}
                      disabled={aadhaarBasicVerified}
                      onChange={(e) => setAadharNumber(e.target.value)}
                      required
                    ></Form.Control>

                    <Button
                      disabled={aadhaarBasicVerified}
                      className={`btn-sm btn-success`}
                      onClick={aadharVerificationHandler}
                    >
                      {aadhaarBasicVerified ? "Verified" : "Verify"}
                    </Button>
                  </InputGroup>

                  <Form.Control.Feedback type="invalid">
                    Please provide 12 digit Aadhaar Number.
                  </Form.Control.Feedback>
                </Form.Group>
              )}

              {aadhaarNoValidMsg ? (
                <div class="alert alert-danger" role="alert">
                  {aadhaarNoValidMsg}
                </div>
              ) : null}
            </div>
          </div>
          <div className="row">
            <div className="col-sm-4 offset-sm-4">
              <button
                className="btn btn-primary mt-5"
                type="button"
                disabled = {(panNoVerification ? false :true) && (aadhaarBasicVerified ? false :true ) && (aadhaarLinked === undefined ? false : true) }
                onClick={() => onNextTabHandler("uploadDocuments")}
              >
                Save & Proceed
              </button>
            </div>
          </div>

        </Form>
      )}
    </div>
  );
};

export default Verification;
