// Dashboard layout
export const APP_NAME = 'Online Application Process';

export const UPLOAD_DOCUMENT_STATUS = {
    pending:"Pending Verification",
    approved:"Approved",
    rejected:"Declined",
    notUploaded:"Not Uploaded"
};

