import React, { useState, useEffect } from "react";
import { NavLink, Link, useRouteMatch, useHistory } from "react-router-dom";
import styled from "styled-components";
import "bootstrap/dist/css/bootstrap.min.css";
import "bs-stepper/dist/css/bs-stepper.min.css";
import { useDispatch, useSelector } from "react-redux";
import { selectUserPayload1, selectUserPayload2 } from "../redux/slices/userRequestSlice";
import { selectUserWisePayment } from "../redux/slices/userWisePaymentSlice";
import { fetchUserWisePayment } from "../redux/slices/userWisePaymentSlice";
const SideVerticleStepper = ({ pageHandler }) => {
  const { path, url } = useRouteMatch();
  const dispatch = useDispatch();
  const history = useHistory();
  const [agree, setAgree] = useState(false);
  const [userId, setUserId] = useState("");
  const selectUserPayload1Data = useSelector(selectUserPayload1);
  const selectUserPayload2Data = useSelector(selectUserPayload2);
  const selectUserPaymentDetails = useSelector(selectUserWisePayment);
  const [akurdiProj, setAkurdiProj] = useState(false);
  const [pimpriProj, setPimpriProj] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  // useEffect(() => {
  //   if (selectUserPaymentDetails && selectUserPaymentDetails?.projectwise_transactions?.AKURDI == null && selectUserPaymentDetails?.projectwise_transactions?.PIMPRI == null) {
  //     setAkurdiProj(false);
  //     setPimpriProj(false);
  //   } else {
  //     if (selectUserPaymentDetails && (selectUserPaymentDetails?.projectwise_transactions?.AKURDI?.txn_status === "SUCCESS")) {
  //       setAkurdiProj(true);
  //     } else if (selectUserPaymentDetails && selectUserPaymentDetails?.projectwise_transactions?.PIMPRI?.txn_status === "SUCCESS") {
  //       setPimpriProj(true);
  //     }
  //   }
  // }, [selectUserPaymentDetails]);

  useEffect(() => {
    if (selectUserPaymentDetails && selectUserPaymentDetails?.projectwise_transactions?.DUDULGAON == null) {
      setAkurdiProj(false);
    } else {
      if (selectUserPaymentDetails && (selectUserPaymentDetails?.projectwise_transactions?.DUDULGAON?.txn_status === "SUCCESS")) {
        setAkurdiProj(true);
      }
    }
  }, [selectUserPaymentDetails]);

  useEffect(() => {
    if (selectUserPayload2Data?.identification?.term) {
      setAgree(selectUserPayload2Data?.identification?.term?.isAgreement);
    }
  }, [selectUserPayload2Data?.identification?.term]);

  useEffect(() => {
    if (localStorage.getItem("userId")) {
      setUserId(localStorage.getItem("userId"))
    }
  }, []);

  useEffect(() => {
    if (userId)
      dispatch(fetchUserWisePayment({ userId }));
  }, [userId]);

  const onClickPageClickHandler = (obj) => {
    pageHandler(obj)
    var element = document.getElementById("mySidenav")
    var shown = element.getAttribute("data-isshown");
    element.setAttribute("data-isshown", "false");
    element.style.width = "0";
  };

  let isMobile = width <= 700;

  return (
    <StepperSec>
      <div className={`bs-stepper ${isMobile ? "vertical" : "vertical"} `}>
        <div
          className="nav bs-stepper-header nav-pills"
          id="v-pills-tab"
          role="tablist"
          aria-orientation="vertical"
        >
          <button
            className="nav-link step "
            id="identification-tab"
            data-bs-toggle="pill"
            role="tab"
            aria-controls="identification-t"
            aria-selected="true"
            // disabled={false}
            onClick={() => onClickPageClickHandler({
              stage: 1,
              pageTitle: 'Identification'
            })}
          >
            <NavLink to={`${url}/identification`} className={`${selectUserPayload1Data &&
              selectUserPayload1Data.identificationFilled ? 'active active-filled' : ''}`} activeClassName="active">
              <span
                className={
                  selectUserPayload1Data &&
                    selectUserPayload1Data.identificationFilled
                    ? "bs-stepper-circle completed"
                    : "bs-stepper-circle"
                }
              >
                <img src={"/assets/icons/white/check.svg"} alt="completed" />
              </span>
              <span className="bs-stepper-label">
                <div className="name">Identification</div>
              </span>
            </NavLink>
          </button>

          <div className="line" />

          <button
            className="nav-link step"
            id="addresshousing-tab"
            role="tab"
            aria-controls="addresshousing-t"
            aria-selected="false" disabled={akurdiProj ? false : true}
            onClick={() => onClickPageClickHandler({
              stage: 2,
              pageTitle: 'Address & Housing'
            })}
          >
            <NavLink to={`${url}/addresshousing`} className={`${selectUserPayload1Data &&
              selectUserPayload1Data.addressAndHousingFilled ? 'active active-filled' : ''}`} activeClassName="active">
              <span
                className={
                  selectUserPayload1Data &&
                    selectUserPayload1Data.addressAndHousingFilled
                    ? "bs-stepper-circle completed"
                    : "bs-stepper-circle"
                }
              >
                {/* <img src={"/assets/images/Vector.svg"} alt="" /> */}
                <img src={"/assets/icons/white/check.svg"} alt="completed" />
              </span>
              <span className="bs-stepper-label">
                <div className="name">Address & Housing</div>
              </span>
            </NavLink>
          </button>

          <div className="line" />

          <button
            className="nav-link step"
            id="employment-finacials-tab"
            role="tab"
            aria-controls="employment-finacials-t"
            aria-selected="false" disabled={akurdiProj ? false : true}
            // disabled={akurdiProj || pimpriProj ? false : true}
            onClick={() => onClickPageClickHandler({
              stage: 3,
              pageTitle: 'Employment & Finacials'
            })}
          >
            <NavLink to={`${url}/employment-finacials`} className={`${selectUserPayload1Data &&
              selectUserPayload1Data.employmentAndFinancialFilled ? 'active active-filled' : ''}`} activeClassName="active">
              <span
                className={selectUserPayload1Data?.employmentAndFinancialFilled
                  ? "bs-stepper-circle completed"
                  : "bs-stepper-circle"
                }
              >
                {/* <img src={"/assets/images/Vector.svg"} alt="" /> */}
                <img src={"/assets/icons/white/check.svg"} alt="completed" />
              </span>
              <span className="bs-stepper-label">
                <div className="name">Employment and Financials</div>
              </span>
            </NavLink>

          </button>

          <div className="line" />

          <button
            className="nav-link step"
            id="bank-details-tab"
            role="tab"
            aria-controls="bank-details-t"
            aria-selected="false" disabled={akurdiProj ? false : true}
            // disabled={!agree}
            // disabled={akurdiProj || pimpriProj ? false : true}
            onClick={() => onClickPageClickHandler({
              stage: 4,
              pageTitle: 'Bank Details'
            })}
          >
            <NavLink to={`${url}/bank-details`} className={`${selectUserPayload1Data &&
              selectUserPayload1Data.BankDetailFilled ? 'active active-filled' : ''}`} activeClassName="active">
              <span
                className={
                  selectUserPayload1Data &&
                    selectUserPayload1Data.BankDetailFilled
                    ? "bs-stepper-circle completed"
                    : "bs-stepper-circle"
                }
              >
                {/* <img src={"/assets/images/Vector.svg"} alt="" /> */}
                <img src={"/assets/icons/white/check.svg"} alt="completed" />
              </span>
              <span className="bs-stepper-label">
                <div className="name">Bank Details</div>
              </span>
            </NavLink>

          </button>

          <div className="line" />

          <button
            className="nav-link step"
            id="family-dependants-tab"
            role="tab"
            aria-controls="family-dependants-t"
            aria-selected="false" disabled={akurdiProj ? false : true}
            // disabled={!agree}
            // disabled={akurdiProj || pimpriProj ? false : true}
            onClick={() => onClickPageClickHandler({
              stage: 5,
              pageTitle: 'Family & Dependants'
            })}
          >
            <NavLink to={`${url}/family-dependent`} className={`${selectUserPayload1Data &&
              selectUserPayload1Data.FamilyAndDependencyFilled ? 'active active-filled' : ''}`} activeClassName="active">
              <span
                className={
                  selectUserPayload1Data &&
                    selectUserPayload1Data.FamilyAndDependencyFilled
                    ? "bs-stepper-circle completed"
                    : "bs-stepper-circle"
                }
              >
                {/* <img src={"/assets/images/Vector.svg"} alt="" /> */}
                <img src={"/assets/icons/white/check.svg"} alt="completed" />
              </span>
              <span className="bs-stepper-label">
                <div className="name">Family & Dependants</div>
              </span>
            </NavLink>

          </button>
          <div className="line" />

          <button
            className="nav-link step"
            id="special-cases-tab"
            role="tab"
            aria-controls="special-cases-t"
            aria-selected="false" disabled={akurdiProj ? false : true}
            // disabled={!agree}
            // disabled={akurdiProj || pimpriProj ? false : true}
            // disabled={selectUserPayload1Data?.specialCasesFilled || selectUserPayload1Data?.FamilyAndDependencyFilled ? false : true}
            onClick={() => onClickPageClickHandler({
              stage: 6,
              pageTitle: 'Special Cases'
            })}
          >
            <NavLink to={`${url}/special-case`} className={`${selectUserPayload1Data &&
              selectUserPayload1Data.specialCasesFilled ? 'active active-filled' : ''}`} activeClassName="active">
              <span
                className={
                  selectUserPayload1Data &&
                    selectUserPayload1Data.specialCasesFilled
                    ? "bs-stepper-circle completed"
                    : "bs-stepper-circle"
                }
              >
                {/* <img src={"/assets/images/Vector.svg"} alt="" /> */}
                <img src={"/assets/icons/white/check.svg"} alt="completed" />
              </span>
              <span className="bs-stepper-label">
                <div className="name">Special Cases</div>
              </span>
            </NavLink>

          </button>

          <div className="line" />

          <button
            className="nav-link step"
            id="upload-documents-tab"
            role="tab"
            aria-controls="upload-documents-t"
            aria-selected="false" disabled={akurdiProj ? false : true}
            // disabled={!agree}
            // disabled={akurdiProj || pimpriProj ? false : true}
            onClick={() => onClickPageClickHandler({
              stage: 7,
              pageTitle: 'Upload Documents'
            })}
          >
            <NavLink to={`${url}/upload-documents`} className={`${selectUserPayload1Data &&
              selectUserPayload1Data.uploadDocumentsFilled ? 'active active-filled' : ''}`} activeClassName="active">
              <span
                className={
                  selectUserPayload1Data &&
                    selectUserPayload1Data.uploadDocumentsFilled
                    ? "bs-stepper-circle completed"
                    : "bs-stepper-circle"
                }
              >
                {/* <img src={"/assets/images/Vector.svg"} alt="" /> */}
                <img src={"/assets/icons/white/check.svg"} alt="completed" />
              </span>
              <span className="bs-stepper-label">
                <div className="name">Upload Documents</div>
              </span>
            </NavLink>

          </button>


          <div className="line" />
          <button
            className="nav-link step"
            id="special-cases-tab"
            role="tab"
            aria-controls="special-cases-t"
            aria-selected="false" disabled={akurdiProj ? false : true}
            // disabled={akurdiProj || pimpriProj ? false : true}
            onClick={() => onClickPageClickHandler({
              stage: 8,
              pageTitle: 'Online Payment'
            })}
          >
            <NavLink to={`${url}/online-payment`} className={`${selectUserPayload1Data &&
              selectUserPayload1Data.onlinePaymentFilled ? 'active active-filled' : ''}`} activeClassName="active">
              <span
                className={
                  selectUserPayload1Data &&
                    selectUserPayload1Data.onlinePaymentFilled
                    ? "bs-stepper-circle completed"
                    : "bs-stepper-circle"
                }
              >
                <img src={"/assets/icons/white/check.svg"} alt="completed" />
              </span>
              <span className="bs-stepper-label">
                <div className="name">Online Payment</div>
              </span>
            </NavLink>

          </button>

        </div>
      </div>
    </StepperSec>
  );
};

export default SideVerticleStepper;

const StepperSec = styled.div`
  text-align: left;
  font-weight: 600;
  font-size: 14px;
  margin-top: 30px;
  margin-bottom: 15px;
  .bs-stepper-header button.nav-link {
    text-align: left;
    padding: 0;
    height: 21px;
  }
  .bs-stepper-header button.nav-link a{
    display:block;
  }

  .bs-stepper-header button:hover {
    background: rgba(149, 159, 190, 0.12);
    border-radius: 10px;
    color: #222;
  }
  .bs-stepper-header button.active{
    background-color: transparent;
  }
  .bs-stepper-header button.active .name {
    font-weight: 700;
    color: #222;
  }

  .bs-stepper-header button[disabled] {
    opacity: 0.9;
    pointer-events: none;
  }
  .bs-stepper-header button[disabled] img{
    visibility:hidden;
  }

  .bs-stepper-circle {
    background-color: transparent;
    border: 3px solid #4640de;
    // opacity: 0.6;
  }
  .active .bs-stepper-circle {
    //background-color: #066066;
    border: 3px solid #4640de;
    opacity: 1;
    background: #4640de;
  }
  // .active .bs-stepper-circle img{
  //   visibility:hidden;
  // }
  .bs-stepper .step-trigger {
    text-align: left;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    color: #3d4144;
  }
  .bs-stepper .line,
  .bs-stepper-line {
    margin: 0 auto 0 9px;
    min-width: 3px;
    max-height: 60px;
    background-color: #4640de;
  }
  .bs-stepper .line, .bs-stepper-line {
    flex: 1 0 25px;
  }

  .bs-stepper .step-trigger {
    padding: 0px 20px;
  }
  .bs-stepper-circle {
    width: 1.5em;
    height: 1.5em;
    padding: 0px;
    margin: 0;
  }
  .bs-stepper-label {
    margin-top: -16px;
    margin-left: 10px;
    line-height: 1.5;
    position: relative;
    top: -6px;
  }

  .bs-stepper  .name {
    font-weight: normal;
    font-size: 14px;
    line-height: 14px;
    color: #3D4144;
}
  }
  .vertical .bs-stepper-pane.fade.dstepper-none,
  .vertical .content.fade.dstepper-none {
    // visibility: none;
    display: none;
  }
  .bs-stepper.vertical {
    align-items: flex-start;
}
  .bs-stepper.vertical .bs-stepper-header {
    flex-direction: column;
    width:100%;
    overflow:hidden;
    /* max-height: 610px; */
  }

  .completed {
    background: #066066;
    border: 3px solid #066066;
    opacity: 1;
  }
  .active .completed {
    padding: 4px;
  }
  .active-filled .bs-stepper-label {
    top: 0;
  }
.nav-link .bs-stepper-circle img{
    display:none;
  }
  .active .completed img{
    display:block !important;
  }
  .bs-stepper .step-trigger:hover {
    background: rgba(149, 159, 190, 0.12);
    border-radius: 10px;
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    button.nav-link {
      width: 100%;
      margin-bottom: 0px;
    }
    .bs-stepper, .bs-stepper-header {
      overflow:hidden;
      padding: 5px;
    }
    .bs-stepper .step-trigger{
      text-align: center !important;
    }
    // .bs-stepper .line {
    //   display: none;
    // }
    // p{
    //   padding-right: 0;
    //   padding-left: 0;
    // }
  }
`;
