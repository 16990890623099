import React, { useState, useEffect } from "react";
import styled from "styled-components";
import SideVerticleStepper from "./SideVerticleStepper";

const MobileLeftNav = ({ logoutHandler, userMobile, applicationId,  mobileViewContainer, setMobileViewContainer }) => {
 
  
    function toggleNav() {
        var element = document.getElementById("mySidenav")
        var shown = element.getAttribute("data-isshown");
        if (shown == "true") {
            element.setAttribute("data-isshown", "false");
            element.style.width = "0";
            setMobileViewContainer(false)

        } else {
            element.setAttribute("data-isshown", "true");
            element.style.width = "250px"
            setMobileViewContainer(true)
        }
    }

    const pageHandler = (obj) => {
        setShowGloble(obj);
        setMobileViewContainer(false)
    };

    const [showGloble, setShowGloble] = useState({
        stage: 1,
        pageTitle: "Identification",
    });

    return (
        <Header>
         
            <div id="mySidenav" className={`mobilesidenav ${mobileViewContainer ? 'mobile-view-container': ''}`}>
                <div className="user-info">
                  <p className="stage">Mobile No : {userMobile}</p>
                  <p className="stage">Application Id : {applicationId}</p>
                </div>
                <hr />
                <a href="javascript:void(0)" className="closebtn" onClick={() => toggleNav()}>×</a>
                <SideVerticleStepper pageHandler={pageHandler}></SideVerticleStepper>
                <hr />
                <p className="alignedleft" >HELP AND SUPPORT</p>
                <a className="alignedleft"  href="https://www.youtube.com/@InformativeVideosinfo" target="_blank">Youtube Videos</a>
                <a className="alignedleft" href="https://support.pmay.org/faqs.html" target="_blank">FAQs</a>
                <div className="alignedleft">Helpline: <a href="tel:+918956983351">+91 89569 83351  </a>
                <span>(10:30 AM to 6:00 PM on gov. working days)</span></div>
                <a className="alignedleft" onClick={() => logoutHandler()} > <i class="fa fa-sign-out"></i> Logout</a>
            </div>
           
        </Header>
    );
};

export default MobileLeftNav;

const Header = styled.div`
.mobilesidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    background-color: #f8f8fd;
    overflow-x: hidden;
  }
  .mobile-view-container{
    padding: 10px;
    padding-top:20px;
    .user-info .stage{
      margin-bottom: 5px;
    }
  }


  .alignedleft {
    padding: 8px 8px 12px 18px;
    text-decoration: none;
    /* font-size: 15px; */
    color: #3D4144;
    display: block;
    font-weight: normal;
    line-height: 15px;
  }

//   .logoutbtn:hover,
//   .offcanvas a:focus {
//     color: #f1f1f1;
//   }


  .closebtn {
    position: absolute;
    top: 0;
    right: 15px;
    font-size: 36px !important;
    margin-left: 50px;
  }
  @media screen and (max-height: 450px) {
    .mobilesidenav {
      padding-top: 15px;
    }
    .mobilesidenav a {
      font-size: 18px;
    }
  }
`;