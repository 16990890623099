import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ApiDataService from "../../services/api-service"
export const fetchUserWisePayment = createAsyncThunk(
  "user/UserWisePayment",
  async ({userId}) => {
    try {
      const url=`get_userwise_payment_info?user_id=${userId}`;
      const result = await ApiDataService.get(url);
      return {
        data: result.data,
      };
    } catch (error) {
      console.log("update error:", error);
    }
  }
);

const userWisePaymentSlice = createSlice({
  name: "userWisePayment",
  initialState: {
    data: null,
    status: "idle",
    error: "",
  },
  reducers: {},
  extraReducers: {
    [fetchUserWisePayment.pending]: (state, action) => {
      state.status = "loading";
      state.error = "";
    },
    [fetchUserWisePayment.fulfilled]: (state, action) => {
      state.data = action.payload?.data;
      state.status = "succeeded";
    },
    [fetchUserWisePayment.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
  },
});

export const selectUserWisePayment = (state) =>
  state.userWisePaymentSlice?.data
      ? state.userWisePaymentSlice?.data
    : null;

export default userWisePaymentSlice.reducer;
